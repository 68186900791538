import React, {Component} from "react";
import TermList from './TermList'
import {Row, Col} from 'reactstrap'
import Slider from "react-slick";
import {dateFormat} from "../../utils/dateUtil";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux"
import {selectMonth} from "../../actions/reservation";

let MonthSlider = () => {

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
    }

    let dispatch = useDispatch()

    let select = (date) => dispatch(selectMonth(date))

    let currentMonth = useSelector(state => state.reservationModal.current_month)

    let isSameDate = (date1, date2, format) => {
        if (typeof date2 === 'string') {
            date2 = moment(date2)
        }
        return date1.format(format) === date2.format(format);
    }

    let isCurrentMonth = (date) => {
        return isSameDate(date, currentMonth, 'Y-M');
    }

    let months = useSelector(state => state.reservationModal.months)

    return <>
        <h3 className='mt-4'>Miesiąc</h3>
        <Slider {...settings} className='month-slider reservation-slider'>
            {
                months.map((month) => <div
                    className={'p-3 mx-2 item ' + (isCurrentMonth(month) ? 'selected' : '')}
                    onClick={() => select(month)}
                >
                    <div className='month'>{dateFormat(month, 'MMMM')}</div>
                    <div className='year'>{month.format('Y')}</div>
                </div>)
            }
        </Slider>
    </>
}

export default MonthSlider