import React from "react";
import {Spinner} from 'reactstrap';
import Button from '@mui/material/Button';
import {useIntl} from 'react-intl'
import {trans} from '../utils/translationUtil'

export default ({
                    loading,
                    text,
                    type = 'button',
                    Icon,
                    extraClasses = '',
                    onClick = () => {
                    },
                    variant = 'primary',
                    rightIcon = false,
                    disabled = false,
                    size = ''
                }) => {

    let intl = useIntl()

    if (rightIcon) {
        extraClasses = extraClasses + ' icon-right'
    }

    let click = (e) => {
        if(loading) {
            e.preventDefault()
            return
        }
        onClick()
    }

    return <Button type={type} className={extraClasses + ' ' + (variant ?? ' ') + ' ' + size} onClick={click}
                   disabled={disabled}>
        {Icon && !rightIcon && <Icon size={24}/>}
        {text && trans(intl, text)}
        {Icon && rightIcon && <Icon size={24}/>}
        {loading && <Spinner size='sm' className='loader'/>}
    </Button>

}