import React from 'react';
import {injectIntl} from "react-intl";
import {getValidationMessage} from "../utils/validationUtil";
import TextField from '@mui/material/TextField';
import {useController} from "react-hook-form";
import {useIntl} from 'react-intl';
import {trans} from "../utils/translationUtil";
import FormWrapper from "./FormWrapper";
import InputMask from 'react-input-mask';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import InputAdornment from '@mui/material/InputAdornment';

let TextInput = ({
                     type = 'text',
                     control,
                     name,
                     namePrefix = null,
                     label = '',
                     required = false,
                     defaultValue = '',
                     information = '',
                     mask = null,
                     validation = {},
                     onChangeText = () => {
                     },
                     Icon = null,
                     loading = false,
                     multiline = false,
                     minRows = 1,
                     maxDecimals = null,
                     onClickInput = () => {},
    disabled=false
                 }) => {

    let intl = useIntl();

    if (required) {
        validation.required = {
            value: true,
            message: getValidationMessage(intl, name, 'required')
        }
    }

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name: namePrefix ? (namePrefix + '[' + name + ']') : name,
        control,
        rules: validation,
        defaultValue: defaultValue,
    });

    let errorMessage = error?.message;

    if (loading) {
        return <div className='form-skeleton'>
            <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                <Skeleton height={48}/>
            </SkeletonTheme>
        </div>;
    }

    let prepareValue = null

    if (maxDecimals) {
        prepareValue = val => {
            return val
        }
    }

    if (mask) {
        return <FormWrapper information={information} errorMessage={errorMessage}>
            <InputMask mask={mask} maskChar=" " value={value}
                       onChange={(e) => {
                           onChangeText(e.target.value);
                           onChange(e);
                       }}>
                {(inputProps) => <TextField
                    type={type}
                    {...inputProps}
                    name={name}
                    multiline={multiline}
                    label={trans(intl, label)}
                    variant="outlined"
                    error={!!errorMessage}
                    minRows={minRows}
                />}
            </InputMask>
        </FormWrapper>
    }

    let inputProps = {}
    if (Icon) {
        inputProps = {
            onClick: onClickInput,
            startAdornment: (
                <InputAdornment position="start">
                    <Icon color='red'/>
                </InputAdornment>
            )
        }
    }

    return <FormWrapper information={information} errorMessage={errorMessage}>
        <TextField
            type={type}
            autoComplete='off'
            name={name}
            label={trans(intl, label)}
            variant="outlined"
            value={value}
            multiline={multiline}
            InputProps={inputProps}
            disabled={disabled}
            onChange={(e) => {
                if (prepareValue) {
                    e.target.value = prepareValue(e.target.value)
                }
                onChangeText(e.target.value);
                onChange(e);
            }}
            error={!!errorMessage}
            minRows={minRows}
        />
    </FormWrapper>

}

export default injectIntl(TextInput);