import React, {useState} from 'react'
import Loader from "../Loader";
import {useSelector, useDispatch} from 'react-redux'
import {createAction} from '../../utils/reduxUtil'
import Button from "../../core/buttons/Button";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import {RENTAL_CALENDAR_SET_FACILITY_PART} from "../../reducers/rentalcalendar";

let FacilityPartSelect = () => {

    let dispatch = useDispatch()

    let selectedFacilityPart = useSelector(state => state.rentalcalendar.facilityPartId)

    let service = useSelector(state => state.service.byId[state.rentalcalendar.serviceId])

    let facility = useSelector(state => state.facility.byId[state.rentalcalendar.facilityId])

    if (service.type === 'entire-rental') {
        return null
    }

    return <select className='form-control' value={selectedFacilityPart} onChange={e => dispatch(createAction(RENTAL_CALENDAR_SET_FACILITY_PART, e.target.value))}>
        <option value={''}>Dowolna część</option>
        {
            facility.parts.map(part => <option value={part.id}>{part.name}</option>)
        }
    </select>
}

export default FacilityPartSelect