import {store} from '../store'
import missingImage from '../assets/img/missing-image2.svg'

export const getMainImage = (facilityId, miniature = false) => {

    const state = store.getState();

    let facility = state.facility.byId[facilityId];

    if (!facility?.images?.length) {
        return missingImage;
    }

    return getImageUrl(facility.images[0].image_id, miniature);
}

export const getBusinessMainImage = (businessId, miniature = false) => {
    const state = store.getState();

    let business = state.business.byId[businessId];

    if (!business?.images?.length) {
        return missingImage;
    }

    return getImageUrl(business.images[0].image_id, miniature);
}

export const getImageUrl = (imageId, miniature) =>
    process.env.REACT_APP_API_URL + '/image/' + imageId + (miniature ? '?miniature=true' : '')