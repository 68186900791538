import React from 'react'
import {useSelector} from 'react-redux'
import {getDistance} from "geolib";
import {getPriceText, getServicePriceText} from "../../utils/appUtil";
import ReserveButton from "../buttons/ReserveButton";
import {useIntl} from 'react-intl'
import Button from "../../core/buttons/Button";
import {useNavigate} from 'react-router-dom'
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow-right.svg'

let ServicesTable = ({facilityId, businessId, sportId}) => {

    let navigate = useNavigate()

    let intl = useIntl()

    let facility = useSelector(state => state.facility.byId[facilityId])

    let allServices = useSelector(state => state.service.byId)

    let businesses = useSelector(state => state.business.byId)

    let business = businesses[businessId]

    let services = []

    if (facilityId) {
        services = facility.services.map(serviceId => allServices[serviceId])
    } else {
        services = business.services.map(serviceId => allServices[serviceId]).filter(service => service.sport_id === sportId)
    }

    if (!services.length) {
        return <div className='d-flex flex-column w-100'>
            <p>Obiekt nie dodał jeszcze żadnych usług, które mógłbyś zarezerwować :(</p>
        </div>
    }

    let getServiceUrl = (service) => {

        let businessUrl = '/biznes/' + business.subdomain

        if (['partial-rental', 'entire-rental'].includes(service.type)) {
            return '/biznes/' + business.subdomain + '/obiekt/' + facility?.slug
        }
        if (['training'].includes(service.type)) {
            return businessUrl + '/treningi'
        }
    }

    return <table className='service-list'>
        {
            !services.length && <div style={{textAlign: 'center'}}>BRAK DOSTĘPNYCH USŁUG</div>
        }
        {
            services
                .map(service => {
                        let minTime = Math.min(...service.price.times)
                        let maxTime = Math.max(...service.price.times)
                        return <>
                            <div className='service-item'>
                                <div width='100%'>
                                    {service.name}
                                </div>
                                <div width='200px'>
                                    <div className='price'>{getServicePriceText(service.price)}</div>
                                    <div
                                        className='duration'>{minTime === maxTime ? minTime : (minTime + '-' + maxTime)} min.
                                    </div>
                                </div>
                                <div>
                                    <Button extraClasses='btn-reserve'
                                            onClick={() => navigate(getServiceUrl(service))}
                                            Icon={ArrowIcon}
                                            rightIcon={true}
                                            text='Rezerwuj'/>
                                </div>
                            </div>
                            <div className='divider'/>
                        </>
                    }
                )
        }
    </table>

    return <table className='w-100'>
        <tbody>
        {facility.services &&
            facility.services.map((serviceId, key) => <tr>
                    <td className='py-2'>
                        {services[serviceId].name}
                    </td>
                    <td className='py-2'>
                        <div className='d-flex flex-column text-end px-2'>
                            <div className='fw-bold'>
                                {getServicePriceText(services[serviceId].price, intl)}
                            </div>
                            <div>
                                {services[serviceId].duration} min.
                            </div>
                        </div>
                    </td>
                    <td className='d-flex justify-content-center'>
                        <ReserveButton facilityId={facility.id} serviceId={serviceId}/>
                    </td>
                </tr>
            )}
        </tbody>
    </table>
}

export default ServicesTable