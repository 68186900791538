import React from 'react'
import card1 from "../../assets/img/card1.svg"
import card2 from "../../assets/img/card2.svg"
import card3 from "../../assets/img/card3.svg"
import card4 from "../../assets/img/card4.svg"
import Card from './Card/Card'

let Cards = () => {
    let classes = 'col col-lg-6 col-sm-12 my-2'
    return <div className='cards'>
        <div className='row'>
            <div className={classes}>
                <Card text='app.benefits.card1' image={card1}/>
            </div>
            <div className={classes}>
                <Card text='app.benefits.card2' image={card2}/>
            </div>
            <div className={classes}>
                <Card text='app.benefits.card3' image={card3}/>
            </div>
            <div className={classes}>
                <Card text='app.benefits.card4' image={card4}/>
            </div>
        </div>
    </div>
}

export default Cards