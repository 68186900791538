import * as types from '../constants/actiontypes/login';
import api from "../core/api";
import {setLoggedUser} from "./app";
import {createAction} from "../utils/reduxUtil";
import {LOGGED_IN} from "../constants/actiontypes/login";
import {toastSuccess} from "../core/utils/appUtil";
import handleError from "../utils/errorUtil";

export function sessionExpired() {
    return function (dispatch) {
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        dispatch(createAction(LOGOUT));
        toastError('app.session_expired');
    };
}

export async function login(intl, dispatch, data) {
    localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data.access_token);
    localStorage.setItem(process.env.REACT_APP_CLIENT_ID_NAME, data.user.id);
    dispatch(setLoggedUser(data.user));
    dispatch(createAction(LOGGED_IN));
}

export function register(intl, dispatch, data) {
    localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data.access_token);
    dispatch(setLoggedUser(data.user));
    dispatch(createAction(LOGGED_IN));
}