import React from 'react';
import BusinessSlider from "../components/BusinessSlider";
import {connect} from "react-redux";
import {loadClosestFacilities, loadProposedFacilities} from "../actions/facility";
import {trans} from "../utils/translationUtil";
import {injectIntl} from "react-intl";
import Loader from "../components/Loader";
import {loadFacilityTypes} from "../actions/facilityType";
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import Button from '../core/buttons/Button'
import inviteObject from '../assets/img/invite_object.svg'
import partnerImage from '../assets/img/partner.svg'
import card1 from '../assets/img/card1.svg'
import {BiChevronRight} from 'react-icons/bi'
import Cards from '../components/home/Cards'
import MobileSearch from '../components/MobileSearch'
import {getBusinesses} from "../actions/business";
import {ReactComponent as Image404} from '../assets/img/404.svg'
import {Link} from "react-router-dom";

let Page404 = () => {

    return <div className='page404'>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
            <Image404/>
        </div>
        <p>Nie znaleziono strony.</p>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Link to='/' className='btn'>Wróć do strony głównej</Link>
        </div>
    </div>
}

export default Page404