import React from 'react';
import {BrowserRouter} from "react-router-dom";
import './styles/main.scss';
import {useSelector, useDispatch} from "react-redux";
import {initApp} from "./actions/app";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {IntlProvider} from "react-intl";
import langData from './lang/langData';
import BusinessRoutes from "./BusinessRoutes";
import AppRoutes from "./AppRoutes";
import AppLoading from "./components/AppLoading";
import LoginModal from "./components/auth/LoginModal";
import {createAction} from "./utils/reduxUtil";
import {SET_LOCATION, SET_MOBILE} from "./constants/actiontypes/app";
import Navigator from './components/Navigator'

let App = () => {

    let dispatch = useDispatch()

    let {business_id, loading} = useSelector(state => state.app)

    React.useEffect(() => {
        let isMobile = window.outerWidth < 768
        dispatch(initApp())
        window.addEventListener('resize', () => dispatch(createAction(SET_MOBILE,  isMobile)))
        dispatch(createAction(SET_MOBILE, isMobile))
    }, [])

    if (loading) {
        return <AppLoading/>
    }

    return <>
        <Navigator/>
        <IntlProvider
            key='pl'
            locale='pl'
            messages={langData['pl']}>
            <ToastContainer/>
            {!business_id && <AppRoutes/>}
            {business_id && <BusinessRoutes/>}
        </IntlProvider>
    </>
}

export default App