import React, {useState} from 'react'
import Loader from "../Loader";
import {useSelector, useDispatch} from 'react-redux'
import {createAction} from '../../utils/reduxUtil'
import Button from "../../core/buttons/Button";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import {RENTAL_CALENDAR_SET_DATE} from "../../reducers/rentalcalendar";

let Navigation = ({facilityId}) => {

    let dispatch = useDispatch()

    let {start, serviceId} = useSelector(state => state.rentalcalendar)

    let service = useSelector(state => state.service.byId[state.rentalcalendar.serviceId])

    let [isOpen, setIsOpen] = React.useState(false)

    let canBack = () => moment(start).format('Y-MM-DD') > moment().format('Y-MM-DD')

    let back = () => {
        let newDay = moment(start).subtract(7, 'days')
        if (moment(start).subtract(7, 'days').format('Y-MM-DD') < moment().format('Y-MM-DD')) {
            newDay = moment()
        }
        dispatch(createAction(RENTAL_CALENDAR_SET_DATE, {
            start: newDay.format('Y-MM-DD'),
            end: newDay.add(7, 'days').format('Y-MM-DD')
        }))
    }

    let next = () => {
        let newDay = moment(start).add(7, 'days')
        dispatch(createAction(RENTAL_CALENDAR_SET_DATE, {
            start: newDay.format('Y-MM-DD'),
            end: newDay.add(7, 'days').format('Y-MM-DD')
        }))
    }

    let today = () => {
        let newDay = moment()
        dispatch(createAction(RENTAL_CALENDAR_SET_DATE, {
            start: newDay.format('Y-MM-DD'),
            end: newDay.add(7, 'days').format('Y-MM-DD')
        }))
    }

    let facility = useSelector(state => state.facility.byId[facilityId])

    let business = useSelector(state => state.business.byId[facility?.business])

    let maxDate = moment(business?.max_reservation_time).format('Y-MM-DD')

    let shouldDisableNextMonth = (date) => {
        return false
    }

    let shouldDisableDate = (date, date2, date3) => {
        if(date.format('Y-MM-DD') === moment().format('Y-MM-DD')) {
            return false;
        }
        if(date.format('Y-MM-DD') > maxDate) {
            return true;
        }
        return facility.openingHours.filter(o => o.day == date.day()).length === 0
    }

    let canForward = () => moment(start).add(7, 'days').format('Y-MM-DD') < maxDate

    return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} className='navigation'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                autoOk
                allowSameDateSelection={true}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                label=''
                shouldDisableDate={shouldDisableDate}
                minDate={moment()}
                maxDate={moment(maxDate)}
                value={moment(start)}
                onChange={(newValue) => {
                    let newDay = moment(newValue.toISOString())
                    dispatch(createAction(RENTAL_CALENDAR_SET_DATE, {
                        start: newDay.format('Y-MM-DD'),
                        end: newDay.add(7, 'days').format('Y-MM-DD')
                    }))
                }}
                disableMaskedInput={true}
                inputFormat="yyyy-MM-dd"
                renderInput={(params) => {
                    let date = moment(params.inputProps.value).format('dddd, D MMMM Y')
                    return <TextField
                        {...params}
                        onClick={() => {
                            setIsOpen(true)
                            return false;
                        }}
                        inputProps={{
                            ...params.inputProps,
                            value: date,
                            onChange: () => {
                                console.log('change')
                            }
                        }}
                    />
                }
                }
            />
        </LocalizationProvider>
        <Button onClick={back} text={'<'} disabled={!canBack()} extraClasses={canBack() ? '' : 'vhidden'}/>
        <Button onClick={today} text='Dziś'/>
        <Button onClick={next} text={'>'} disabled={!canForward()} extraClasses={canForward() ? '' : 'vhidden'}/>
    </div>
}

export default Navigation