import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import {Row, Col} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import {initReservationPage} from "../actions/reservation";
import ReservationTile from "../components/ReservationTile";
import AccountPage from './AccountPage'
import {composeUtils} from "../core/utils/appUtil";

let ReservationsPage = ({dispatch}) => {

    let {loadingUpcoming, loadingFinished} = useSelector(state => state.reservation)

    let {upcoming, finished} = useSelector(state => state.reservation)

    React.useEffect(() => {
        dispatch(initReservationPage())
    }, [])

    return <AccountPage>
        <Row>
            <Col>
                <div>
                    <h3>Nadchodzące rezerwacje</h3>
                    {loadingUpcoming && <Loader/>}
                    {!loadingUpcoming && upcoming.length === 0 && <p>Brak nadchodzących rezerwacji</p>}
                    {!loadingUpcoming && <Row>
                        {upcoming.map((reservation) => <ReservationTile
                            key={reservation}
                            reservationId={reservation}/>)}
                    </Row>}
                </div>
                <div>
                    <h3>Zakończone rezerwacje</h3>
                    {loadingFinished && <Loader/>}
                    {!loadingFinished && finished.length === 0 && <p>Brak zakończonych rezerwacji</p>}
                    {!loadingFinished && <Row>
                        {finished.map((reservation) => <ReservationTile
                            key={reservation}
                            reservationId={reservation}
                        finished={true}
                        />)}
                    </Row>}
                </div>
            </Col>
        </Row>
    </AccountPage>
}

export default composeUtils(ReservationsPage)