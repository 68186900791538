import {
    CLOSE_LOGIN_MODAL,
    LOGGED_IN,
    OPEN_LOGIN_MODAL,
    OPEN_REGISTER_MODAL, OPEN_RESET_PASSWORD_FORM, OPEN_RESET_PASSWORD_SUBMIT_FORM, SET_STEP
} from "../constants/actiontypes/login";
import {LOGIN, REGISTER, RESET_PASSWORD, RESET_PASSWORD_SUBMIT} from "../constants/loginModalPages";

var initialState = {
    show_login_modal: false,
    current_step: null,
    loggedUser: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LOGIN_MODAL:
            return {
                ...state,
                show_login_modal: true,
                current_step: LOGIN
            };
        case CLOSE_LOGIN_MODAL:
            return {
                show_login_modal: false
            }
        case OPEN_REGISTER_MODAL:
            return {
                ...state,
                show_login_modal: true,
                current_step: REGISTER
            }
        case LOGGED_IN:
            return {
                ...state,
                show_login_modal: false,
                loggedUser: action.payload
            }
        case OPEN_RESET_PASSWORD_FORM:
            return {
                ...state,
                current_step: RESET_PASSWORD
            }
        case OPEN_RESET_PASSWORD_SUBMIT_FORM:
            return {
                ...state,
                show_login_modal: true,
                current_step: RESET_PASSWORD_SUBMIT
            }
        case SET_STEP: {
            return {
                ...state,
                current_step: action.payload
            }
        }
        default:
            return state;
    }
};