import {toast} from "react-toastify";
import {translationExists, trans} from '../core/utils/translationUtil'

export function toastSuccess(text, intl) {
    if (intl && translationExists(intl, text)) {
        text = trans(intl, text)
    }
    toast.success(text, {hideProgressBar: true});
}

export function toastError(text) {
    toast.error(text, {hideProgressBar: true});
}