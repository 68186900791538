import React from 'react';
import MyNavbar from "../../components/header/MyNavbar";
import MobileHeader from "../../components/header/MobileHeader";
import Footer from "../../components/Footer";
import MobileNavigation from "../../components/MobileNavigation";
import {useSelector} from "react-redux";
import {injectIntl} from "react-intl";
import {withRouter} from 'react-router-dom';
import {useMatch, useLocation, useNavigate} from "react-router-dom";
import LoginModal from "../auth/LoginModal";
import TestInfo from './TestInfo/TestInfo'

let BasicLayout = ({children, requireLogin = true}) => {

    let loggedUser = useSelector(state => state.app.logged_user)

    let {mobile: isMobile, business_id: businessId} = useSelector(state => state.app)

    let {show_login_modal: showLoginModal} = useSelector(state => state.auth)

    const location = useLocation()

    let navigate = useNavigate()

    if (requireLogin === true && !loggedUser) {
        navigate('/')
    }

    let styles = {}

    if (isMobile) {
        styles = {
            backgroundImage: `url(${process.env.PUBLIC_URL + '/img/headerbackground.svg'})`
        }
    }

    if (window.self !== window.top) {
        return <div className='container'>
            {children}
        </div>
    }

    return <div className={'wrapper ' + (isMobile ? 'mobile' : '')} style={styles}>
        {!isMobile && <MyNavbar withSearchBar={!businessId && location.pathname === '/'} businessContext={!!businessId}/>}
        {isMobile && <MobileHeader/>}
        <div className='container'>
            {children}
        </div>
        {(!isMobile && !businessId) && <Footer/>}
        {isMobile && <MobileNavigation/>}
        {showLoginModal && <LoginModal/>}
    </div>
}

export default BasicLayout