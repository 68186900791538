import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import {store} from './store';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_SENTRY_URL) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
    <App/>
</Provider>);
// ReactDOM.createRoot(
//         <Provider store={store}>
//             <App/>
//         </Provider>,
//     document.getElementById('root')
// );
