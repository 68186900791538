export let importModule = (subKey = '', result, data) => {
    Object.keys(data).forEach(key => {
        if(typeof data[key] === 'object') {
            result = importModule(subKey + '.' + key, result, data[key])
        } else if (key === '_') {
            result[subKey] = data[key]
        } else {
            result[subKey + '.' + key] = data[key]
        }
    })
    return result
}