import React from 'react'
import {useSelector} from "react-redux";
import {ReactComponent as ArrowIcon} from '../../assets/icons/location-arrow-outline.svg'
import missingImage from '../../assets/img/missing-image.svg'
import {getMainImage} from "../../utils/facilityUtil";

let FacilityImage = ({facilityId, width}) => {

    return <img src={getMainImage(facilityId)} width={width}/>

}

export default FacilityImage