import React from 'react';
import card1 from "../../../assets/img/card1.svg";
import {useIntl} from 'react-intl'
import {trans} from '../../../core/utils/translationUtil'
import Text from '../../../core/components/Text'
import style from './Card.module.scss'

export default ({text, image}) => <div className={'d-flex flex-column p-5 m-3 card h-100 ' + style.card}>
    <img src={image} className='py-3'/>
    <div className='d-flex justify-content-center align-items-center h-100'>
        <Text text={text}/>
    </div>
</div>
