import {toastError, toastSuccess} from "../utils/toastUtil";
import {createAction} from "../utils/reduxUtil";
import {
    getAllFacilities, getBusinessesBySubdomain,
    getFacilitiesByIds,
    getFacilityById,
    getFacilityBySlug,
    updateFacility
} from "../api/app";
import * as api from '../api/app'
import {normalize} from 'normalizr';
import * as QueryString from "query-string"

import {
    ADD_FAVOURITE,
    CHANGE_SEARCH_PAGE, LOAD_BUSINESS_SUCCESS,
    LOAD_CLOSEST_FACILITIES_REQUEST,
    LOAD_CLOSEST_FACILITIES_SUCCESS,
    LOAD_FACILITIES_REQUEST,
    LOAD_FACILITIES_SUCCESS,
    LOAD_FACILITY_REQUEST,
    LOAD_FACILITY_SUCCESS,
    LOAD_FAVOURITE_FACILITIES_REQUEST,
    LOAD_FAVOURITE_FACILITIES_SUCCESS,
    LOAD_MORE_REULTS,
    LOAD_MORE_REULTS_REQUEST,
    LOAD_OTHER_FACILITIES_REQUEST,
    LOAD_OTHER_FACILITIES_SUCCESS,
    REMOVE_FAVOURITE,
    RESIZE_SEARCH_PAGINATION,
    SEARCH_REQUEST,
    SEARCH_SUCCESS
} from "../constants/actiontypes/facility";
import schemas from "../reducers/schemas";
import {appError} from "./app";
import {
    SEARCH_REMOVE_FILTER_FACILITY_TYPE,
    SEARCH_SET_FILTER_FACILITY_TYPE
} from "../constants/actiontypes/facilityType";
import handleError from "../utils/errorUtil";

export function getFacilityDetails(subdomain, slug) {
    return function (dispatch) {
        dispatch(createAction(LOAD_FACILITY_REQUEST));
        getBusinessesBySubdomain(subdomain).then(response => {
            let business = response.data[0]
            let data = normalize(response.data, [schemas.businessSchema])
            if(!slug) {
                slug = business.facilities[0].slug
            }
            getFacilityBySlug(slug, business.id).then(response => {
                let normalizedData = normalize(response.data, [schemas.facilitySchema]);
                let facilityId = normalizedData.result[0];
                dispatch(createAction(LOAD_FACILITY_SUCCESS, {
                    facility_id: facilityId,
                    ...normalizedData
                }));
            }).catch(err => handleError(err, dispatch));
        })
    }
}