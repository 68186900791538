import React from 'react'
import {injectIntl} from "react-intl"
import {Link, useLocation, useNavigate} from "react-router-dom"
import moment from 'moment'
import {trans} from '../utils/translationUtil'
import calendarImage from "../assets/img/mobilenav/calendar.svg";
import homeImage from "../assets/img/mobilenav/home.svg";
import profileImage from "../assets/img/mobilenav/profile.svg";
import {createAction} from "../utils/reduxUtil";
import {OPEN_LOGIN_MODAL} from "../constants/actiontypes/login";
import {composeUtils} from "../core/utils/appUtil";
import {useSelector} from 'react-redux'

let MobileNavigation = ({dispatch}) => {

    let navigate = useNavigate()

    let openLoginModal = () => dispatch(createAction(OPEN_LOGIN_MODAL))

    let loggedUser = useSelector(state => state.app.logged_user);

    const location = useLocation();

    let isActive = (path) => {
        if(location.pathname === '/rezerwacje' || location.pathname === '/konto') {
            return path.startsWith(location.pathname)
        }
        return !['/rezerwacje','/konto'].includes(path);
    }

    let openReservations = () => {
        if (!loggedUser) {
            openLoginModal()
        }
        navigate('/rezerwacje')
    }

    let openHomepage = () => {
        navigate('/')
    }

    let openProfile = () => {
        if (!loggedUser) {
            openLoginModal()
        }
        navigate('/account')
    }

    return <div className='d-flex flex-row mobile-navigation justify-content-around align-items-center'>
        <div
            className={'item d-flex flex-column justify-content-center ' + (isActive('/rezerwacje') ? 'active' : '')}>
            <div className='d-flex flex-column align-items-center' onClick={openReservations}>
                <div className='imgcontainer'>
                    <img src={calendarImage}/>
                </div>
            </div>
            <div className='text-center'>
                <p>Rezerwacje</p>
            </div>
        </div>
        <div className={'item d-flex flex-column justify-content-center ' + (isActive('/') ? 'active' : '')}>
            <div className='d-flex flex-column align-items-center justify-content-center' onClick={openHomepage}>
                <div className='imgcontainer'>
                    <img src={homeImage}/>
                </div>
            </div>
            <div className='text-center'>
                <p>Główna</p>
            </div>
        </div>
        <div className={'item d-flex flex-column justify-content-center ' + (isActive('/account') ? 'active' : '')}>
            <div className='d-flex flex-column align-items-center' onClick={openProfile}>
                <div className='imgcontainer'>
                    <img src={calendarImage}/>
                </div>
            </div>
            <div className='text-center'>
                <p>Profil</p>
            </div>
        </div>
    </div>
}

export default composeUtils(MobileNavigation)
