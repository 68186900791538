import React, {Component} from "react";
import calendarImage from "../../assets/img/calendar.svg";
import {addLeadingZeros} from "../../utils/stringUtil";
import {createAction} from "../../utils/reduxUtil";
import {SELECT_TERM} from "../../constants/actiontypes/reservation";
import {useDispatch, useSelector} from "react-redux";
import TermListPart from './TermListPart'

let TermList = ({minHour, maxHour, title}) => {

    let dispatch = useDispatch()

    let terms = useSelector(state => state.reservationModal.terms.filter(
        term => term.from.hour >= minHour && term.from.hour < maxHour
    ))

    let columns = 2
    let perColumn = Math.ceil(terms.length / columns)
    return <>
        <p className='header-text'>{title}</p>
        {!terms?.length && <div className='d-flex justify-content-center flex-column'>
            <span style={{fontSize: '12px'}} className='mb-2'>Brak wolnych terminów</span>
            <img src={calendarImage}/>
        </div>}
        <div className='d-flex flex-row justify-content-around w-100'>
            {
                Array.from(Array(columns).keys()).map((val, key) => {
                        let startIndex = perColumn * key
                        let endIndex = perColumn * (key + 1)
                        if (columns === (key - 1)) {
                            endIndex = terms.length
                        }
                        return <div className='d-flex flex-column'>
                            <TermListPart terms={terms.slice(perColumn * key, endIndex)}/>
                        </div>
                    }
                )
            }
        </div>
    </>
}

export default TermList