import React, {useState} from 'react';
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from 'react-router-dom'
import {withRouter} from 'react-router-dom';
import {loadFacilityTypes} from "../../actions/facilityType";
import icon from '../../assets/icons/map/tennis.svg'
import {MapContainer, TileLayer, useMap} from "react-leaflet";
import FacilityMarker from '../map/markers/FacilityMarker'
import {ReactComponent as FullscreenIcon} from '../../assets/icons/fullscreen.svg'

function ChangeView({center, zoom}) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

function CenterMap({fullscreen, center, zoom}) {
    let map = useMap()
    React.useEffect(() => {
        map.invalidateSize()
        map.setView(center, zoom)
    }, [fullscreen, center])
}

let FacilityMap = ({type}) => {

    let zoom = 11

    let allObjects = useSelector(state => state[type].byId)

    let result = useSelector(state => state.search.result).map(r => allObjects[r]).filter(r => !!r)

    let currentObject = useSelector(state => state[type].byId[state.search.selected_object])

    let [position, setPosition] = useState(currentObject ? [currentObject.address.lat, currentObject.address.lon] : [0,0])

    let loading = useSelector(state => state.search.loading)

    let [fullscreen, setFullscreen] = useState(false)

    let centerMap = () => {
        let maxLat = Math.max.apply(Math, result.map(r => r.address.lat))
        let minLat = Math.min.apply(Math, result.map(r => r.address.lat))
        let maxLon = Math.max.apply(Math, result.map(r => r.address.lon))
        let minLon = Math.min.apply(Math, result.map(r => r.address.lon))

        setPosition(state => [
            (maxLat + minLat) / 2,
            (maxLon + minLon) / 2
        ])
    }

    React.useEffect(() => {
        if(!loading && !currentObject && result.length > 0) {
            centerMap()
        }
    }, [loading])

    React.useEffect(() => {
        if(currentObject) {
            setPosition([currentObject.address.lat, currentObject.address.lon])
        }
    }, [currentObject])

    return <div className={'map ' + (fullscreen ? 'fullscreen' : '')}>
        <div className='fullscreen-icon' onClick={() => {setFullscreen(state => !state);centerMap()}}>
            <FullscreenIcon/>
        </div>
        <MapContainer center={position} zoom={zoom}>
            <ChangeView center={position}/>
            <CenterMap fullscreen={fullscreen} center={position}/>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                type === 'facility' && result.map(facility => <FacilityMarker facilityId={facility.id} key={facility.id}/>)
            }
        </MapContainer>
    </div>

}

export default FacilityMap
