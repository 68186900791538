import {
    CONFIRM_RESERVATION_SUCCESS, LOAD_FINISHED_RESERVATIONS_REQUEST,
    LOAD_FINISHED_RESERVATIONS_SUCCESS,
    LOAD_UPCOMING_RESERVATIONS_REQUEST,
    LOAD_UPCOMING_RESERVATIONS_SUCCESS, RESERVATION_CANCEL_SUCCESS
} from "../constants/actiontypes/reservation";
import {CONFIRMED} from "../constants/reservationStatuses";
import _ from "lodash";

var initialState = {
    byId: [],
    upcoming: [],
    finished: [],
    loadingUpcoming: false,
    loadingFinished: false
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.reservation) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.reservation
            }
        }
    }
    switch (action.type) {
        case RESERVATION_CANCEL_SUCCESS: {
            return {
                ...state,
                upcoming: state.upcoming.filter(id => id !== action.payload),
                finished: state.finished.filter(id => id !== action.payload),
                byId: _.omit(state.byId, [action.payload])
            }
        }
        case LOAD_UPCOMING_RESERVATIONS_REQUEST:
            return {
                ...state,
                loadingUpcoming: true
            }
        case LOAD_UPCOMING_RESERVATIONS_SUCCESS:
            return {
                ...state,
                loadingUpcoming: false,
                byId: {
                    ...state.byId,
                    ...action.payload.entities.reservation
                },
                upcoming: action.payload.result
            }
        case LOAD_FINISHED_RESERVATIONS_REQUEST:
            return {
                ...state,
                loadingFinished: true
            }
        case LOAD_FINISHED_RESERVATIONS_SUCCESS:
            return {
                ...state,
                loadingFinished: false,
                byId: {
                    ...state.byId,
                    ...action.payload.entities.reservation
                },
                finished: action.payload.result
            }
        case CONFIRM_RESERVATION_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload]: {
                        ...state.byId[action.payload],
                        status: CONFIRMED
                    }
                }
            }
        }
        default:
            return state;
    }
};