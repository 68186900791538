import React from 'react';
import MyNavbar from "../../components/header/MyNavbar";
import MobileHeader from "../../components/header/MobileHeader";
import Footer from "../../components/Footer";
import MobileNavigation from "../../components/MobileNavigation";
import {useDispatch, useSelector} from "react-redux";
import {injectIntl} from "react-intl";
import {withRouter} from 'react-router-dom';
import {useMatch, useLocation, useNavigate, useParams} from "react-router-dom";
import LoginModal from "../auth/LoginModal";
import TestInfo from './TestInfo/TestInfo'
import BasicLayout from './BasicLayout'
import {Link} from "react-router-dom";
import {getBusinessesBySubdomain} from "../../api/app";
import {normalize} from "normalizr";
import schemas from "../../reducers/schemas";
import {createAction} from "../../utils/reduxUtil";
import {APP_LOADING} from "../../constants/actiontypes/app";
import {BUSINESS_FETCH_REQUEST, BUSINESS_FETCH_SUCCESS} from "../../reducers/business";
import Loader from "../Loader";
import {getBusinessBySubdomain} from "../../selectors";
import Page404 from '../../pages/Page404'
import * as QueryString from "query-string"

let BusinessLayout = ({children, requireLogin = true, businessContext = false}) => {

    let searchParams = useSelector(state => state.search.searchParams)
    let params = useParams()
    let location = useLocation()
    let currentPath = location.pathname
    let dispatch = useDispatch()
    let subdomain = params.business
    let {business_id: businessContextId} = useSelector(state => state.app)
    let business = null

    if (!businessContextId) {
        business = useSelector(state => getBusinessBySubdomain(params.business, state))
    } else {
        business = useSelector(state => state.business.byId[businessContextId])
    }

    React.useEffect(() => {
        if (business?.subdomain || params.business) {
            dispatch(createAction(BUSINESS_FETCH_REQUEST))
            getBusinessesBySubdomain(business?.subdomain ? business?.subdomain : params.business).then(response => {
                let data = {}
                if (response.data.length) {
                    data = normalize(response.data[0], schemas.businessSchema)
                }
                dispatch(createAction(BUSINESS_FETCH_SUCCESS, data))
            })
        }
    }, [])

    let {loading} = useSelector(state => state.business)

    if (loading && !business?.id) {
        return <div className='business-page'>
            <BasicLayout requireLogin={false} businessContext={businessContext}>
                <div className='' style={{marginTop: '200px'}}>
                    <Loader/>
                </div>
            </BasicLayout>
        </div>
    }

    if (!loading && !business?.id) {
        return <BasicLayout requireLogin={false} businessContext={businessContext}>
            <Page404/>
        </BasicLayout>
    }

    let getClass = path => {
        return path.replaceAll('/','') === currentPath.replaceAll('/','') ? 'menu-item active' : 'menu-item'
    }

    let getPath = (path = '') => {
        if(businessContextId) {
            return '/' + path
        }
        return '/biznes/' + subdomain + '/' + path
    }

    return <div className='business-page'>
        <BasicLayout requireLogin={false} businessContext={businessContext}>
            <div className='header'>
                {searchParams?.type &&
                    <Link to={'/szukaj?' + QueryString.stringify(searchParams, {arrayFormat: 'bracket'})}
                          className='btn'>{'<'} Powrót do wyników wyszukiwania</Link>}
                <div>
                    {(!businessContextId && business.logo) && <img src={process.env.REACT_APP_API_URL + '/image/' + business.logo.id}
                                           style={{marginRight: '30px', maxHeight: '60px'}}
                    />}
                    {business.name}
                </div>
            </div>
            <div className='menu'>
                <div className={getClass(getPath())}>
                    <Link to={getPath()}>Informacje</Link>
                </div>
                <div className='divider'></div>
                <div className={getClass(getPath('treningi'))}>
                    <Link to={getPath('treningi')}>
                        Treningi i wizyty
                    </Link>
                </div>
                <div className='divider'></div>
                <div className={getClass(getPath('wynajem'))}>
                    <Link to={getPath('wynajem')}>
                        Wynajem obiektów
                    </Link>
                </div>
                <div className='divider'></div>
                <div className='menu-item disabled'><span href='#'>Harmonogram zajęć</span></div>
                <div className='divider'></div>
                <div className='menu-item disabled'><span href='#'>Kursy i warsztaty</span></div>
                <div className='divider'></div>
                <div className='menu-item disabled'><span href='#'>Produkty</span></div>
                <div className='divider'></div>
                <div className='menu-item disabled'><span href='#'>Wydarzenia</span></div>
            </div>
            <div className='page'>
                {children}
            </div>
        </BasicLayout>
    </div>
}

export default BusinessLayout