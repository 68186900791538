import React from 'react';
import {Button, Form, Modal} from 'reactstrap';
import {connect} from "react-redux";
import * as steps from "../../constants/loginModalPages";
import RegisterForm from "./RegisterForm";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordSubmitForm from "./ResetPasswordSubmitForm";
import LoginForm from "./LoginForm";
import {CLOSE_LOGIN_MODAL, SET_STEP} from "../../constants/actiontypes/login";
import {createAction} from "../../utils/reduxUtil";
import SubmitFormButton from '../../core/buttons/SubmitFormButton'
import AppModal from '../../core/modal/AppModal'
import ModalForm from '../../core/modal/ModalForm'
import {useSelector, useDispatch} from 'react-redux'
import {useForm} from "react-hook-form";
import TextInput from "../../core/form/TextInput";
import {useIntl} from 'react-intl'
import {trans} from '../../core/utils/translationUtil'
import {setErrors} from "../../core/utils/formUtil";
import api from '../../core/api'
import {login, register} from '../../actions/auth'
import {resetPassword, resetPasswordLink} from "../../core/api/auth";
import {useLocation} from "react-router-dom";
import * as QueryString from "query-string";
import {composeUtils} from "../../core/utils/appUtil";
import {RESET_PASSWORD_CONFIRMATION, RESET_PASSWORD_SUBMIT_CONFIRMATION} from "../../constants/loginModalPages";
import resetPasswordImg1 from '../../assets/img/reset_password_1.svg'
import resetPasswordImg3 from '../../assets/img/reset_password_3.svg'

let LoginModal = ({dispatch, match, intl}) => {

    let businessId = useSelector(state => state.app.business_id)

    let {
        show_login_modal: modalOpened,
        current_step: currentStep
    } = useSelector(state => state.auth)

    let closeModal = () => dispatch(createAction(CLOSE_LOGIN_MODAL))

    let isMobile = useSelector(state => state.app.mobile)

    let form = useForm({defaultValues: {}})

    let renderForm = (form) => {
        switch (currentStep) {
            case steps.LOGIN:
                return <LoginForm form={form}/>
            case steps.REGISTER:
                return <RegisterForm form={form}/>
            case steps.RESET_PASSWORD:
                return <ResetPasswordForm form={form}/>
            case steps.RESET_PASSWORD_SUBMIT:
                return <ResetPasswordSubmitForm form={form}/>
            case steps.RESET_PASSWORD_CONFIRMATION:
                return <div className='d-flex justify-content-center flex-column mt-5'>
                    <p>Jeśli w systemie istnieje podany adres e-mail to wiadomość z linkiem pozwalającym zresetować hasło została wysłana.</p>
                    <img src={resetPasswordImg1} className='my-5'/>
                </div>
            case steps.RESET_PASSWORD_SUBMIT_CONFIRMATION:
                return <div className='d-flex justify-content-center flex-column mt-5 align-items-center'>
                    <p>Twoje hasło zostało pomyślnie zmienione!</p>
                    <img src={resetPasswordImg3} className='w-75'/>
                </div>
            default:
                return null
        }
    }

    let getTitle = () => {
        switch (currentStep) {
            case steps.LOGIN:
                return 'app.loging'
            case steps.REGISTER:
                return 'app.registering'
            case steps.RESET_PASSWORD:
            case steps.RESET_PASSWORD_SUBMIT:
            case steps.RESET_PASSWORD_CONFIRMATION:
                return 'Resetowanie hasła'
            case steps.RESET_PASSWORD_SUBMIT_CONFIRMATION:
                return 'Gratulacje'
            default:
                return ''
        }
    }

    let getSubmitText = () => {
        switch (currentStep) {
            case steps.LOGIN:
                return 'app.login'
            case steps.REGISTER:
                return 'app.register'
            case steps.RESET_PASSWORD:
            case steps.RESET_PASSWORD_SUBMIT:
                return 'Zresetuj'
            default:
                return 'Zamknij'
        }
    }

    let onSubmitForm = data => {
        data.business_id = businessId
        if (currentStep === steps.REGISTER) {
            return register(intl, dispatch, data)
        }
        if (currentStep === steps.RESET_PASSWORD) {
            return dispatch(createAction(SET_STEP, RESET_PASSWORD_CONFIRMATION))
        }
        if (currentStep === steps.RESET_PASSWORD_SUBMIT) {
            return dispatch(createAction(SET_STEP, RESET_PASSWORD_SUBMIT_CONFIRMATION))
        }
        return login(intl, dispatch, data)
    }

    let submitAction = (data) => {
        data.business_id = businessId
        if (currentStep === steps.REGISTER) {
            return api.auth.registerClient(data)
        }
        if (currentStep === steps.RESET_PASSWORD) {
            data.type = 'client'
            return api.auth.resetPasswordLink(data)
        }
        if (currentStep === steps.RESET_PASSWORD_SUBMIT) {
            return api.auth.resetPassword(match.params.id, data)
        }
        if (currentStep === steps.RESET_PASSWORD_CONFIRMATION) {
            closeModal()
        }
        if (currentStep === steps.RESET_PASSWORD_SUBMIT_CONFIRMATION) {
            closeModal()
        }
        return api.auth.loginClient(data.email, data.password, businessId)
    }

    let prepareData = data => {
        if (currentStep === steps.REGISTER) {
            return {
                email: data.email,
                password: data.password,
                fullname: data.fullname,
                phone: data.phone
            }
        }
        if (currentStep === steps.RESET_PASSWORD) {
            return {
                email: data.email
            }
        }
        if (currentStep === steps.RESET_PASSWORD_SUBMIT) {
            return {
                email: data.email,
                password: data.password,
                password_confirmation: data.password_confirmation
            }
        }
        return {
            email: data.email,
            password: data.password
        }
    }

    let hasCancelButton = () => {
        if(steps.RESET_PASSWORD_CONFIRMATION === currentStep) {
            return false
        }
        return !isMobile
    }

    return <ModalForm
        prepareData={prepareData}
        isOpen={modalOpened}
        closeModal={closeModal}
        title={trans(intl, getTitle())}
        getPromise={(id, data) => submitAction(data)}
        onSubmit={onSubmitForm}
        renderForm={renderForm}
        submitText={trans(intl, getSubmitText())}
        className={'login-modal'}
        cancelButton={hasCancelButton()}
        isMobile={isMobile}
    />
}

export default composeUtils(LoginModal)