import React,{ useState, useCallback } from 'react';
import {injectIntl} from "react-intl";
import Slider from "react-slick";
import BusinessSliderItem from "./BusinessSliderItem";

let BusinessSlider = (props) => {

    const [dragging, setDragging] = useState(false)

    const handleBeforeChange = useCallback(() => {
        setDragging(true)
    }, [setDragging])

    const handleAfterChange = useCallback(() => {
        setDragging(false)
    }, [setDragging])

    const settings = {
            width: '12px',
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: props.items.length > 6 ? 6 : props.items.length,
            slidesToScroll: 3,
            arrows: true,
            beforeChange: handleBeforeChange,
            afterChange: handleAfterChange,
            responsive: [
                {
                    breakpoint: 3000,
                    settings: {
                        slidesToShow: props.items.length > 5 ? 5 : props.items.length,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: props.items.length > 4 ? 4 : props.items.length,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: props.items.length > 3 ? 3 : props.items.length,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: props.items.length > 2 ? 2 : props.items.length,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: props.items.length > 2 ? 2 : props.items.length,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 470,
                    settings: {
                        slidesToShow: props.items.length > 1 ? 1 : props.items.length,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                },
            ]
        };

        return <Slider {...settings} className={'facility-slider ' + (props.loading ? 'loading' : '')}>
                {
                    props.loading && [0, 1].map((item) => <BusinessSliderItem loading={true} key={item} dragging={dragging}/>)
                }
                {
                    !props.loading && props.items.map((item)=><BusinessSliderItem businessId={item} key={item} dragging={dragging}/>)
                }
            </Slider>
}

export default injectIntl(BusinessSlider);
