import React from 'react';
import {loadClosestFacilities, loadProposedFacilities} from "../actions/facility";
import {trans} from "../utils/translationUtil";
import {injectIntl} from "react-intl";
import Loader from "../components/Loader";
import {loadFacilityTypes} from "../actions/facilityType";
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import Button from '../core/buttons/Button'
import inviteObject from '../assets/img/invite_object.svg'
import partnerImage from '../assets/img/partner.svg'
import card1 from '../assets/img/card1.svg'
import {BiChevronRight} from 'react-icons/bi'
import Cards from '../components/home/Cards'
import ObjectPage from '../core/components/objectpage/ObjectPage'
import AccountPage from './AccountPage'
import AccountForm from '../components/form/AccountForm'
import {Row, Col} from 'reactstrap'

let AccountSettingsPage = ({children}) => {

    return <AccountPage>
        <Row>
            <Col lg={{size: 6, offset: 3}}>
                <AccountForm/>
            </Col>
        </Row>
    </AccountPage>

}

export default AccountSettingsPage