import React from 'react';
import {withRouter, useMatch, useParams, Link} from "react-router-dom";
import * as QueryString from "query-string"
import {useSelector} from "react-redux";
import {getFacilityDetails} from "../actions/facility";
import {Row, Col, Button} from 'reactstrap';
import FacilityImageSlider from "../components/facility/FacilityImageSlider";
import {injectIntl} from "react-intl";
import moment from 'moment';
import {initReservationModal} from "../actions/reservation";
import {DAYS} from "../constants/days";
import {trans} from "../utils/translationUtil";
import {addLeadingZeros} from "../utils/stringUtil";
import {CgWebsite} from 'react-icons/cg';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {ReactComponent as WebIcon} from '../assets/icons/web.svg'
import {BiPhone} from 'react-icons/bi';
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconMe from '../assets/img/marker-me.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import Loader from "../components/Loader";
import ReservationModal from "../components/reservation/ReservationModal";
import {getDistance} from 'geolib';
import {getPriceText} from "../utils/appUtil";
import ReserveButton from "../components/buttons/ReserveButton";
import MapMarker from '../components/map/markers/MapMarker'
import {useIntl} from 'react-intl'
import MarkerMe from '../components/map/markers/MarkerMe'
import Distance from './../components/facility/Distance'
import Map from '../components/facility/Map'
import ServicesTable from '../components/facility/ServiceTable'
import OpeningHours from '../components/facility/OpeningHours'
import {composeUtils} from "../core/utils/appUtil";
import Socials from '../components/facility/Socials'
import Address from '../components/facility/Address'
import OtherFacilities from '../components/facility/OtherFacilities'
import {getBusinessBySubdomain} from "../selectors"
import {ReactComponent as ShowerIcon} from '../assets/icons/amenities/shower.svg'
import {ReactComponent as CreditCardIcon} from '../assets/icons/amenities/🦆 icon _credit card_.svg'
import {ReactComponent as ParkIcon} from '../assets/icons/amenities/🦆 icon _park thin_.svg'
import {ReactComponent as RoomIcon} from '../assets/icons/amenities/🦆 icon _room divider_.svg'
import {ReactComponent as ShopIcon} from '../assets/icons/amenities/🦆 icon _shop cart bold_.svg'
import {ReactComponent as CallIcon} from '../assets/icons/call.svg'
import {ReactComponent as BasketballIcon} from '../assets/icons/sports/basketball.svg'
import {ReactComponent as BowlingIcon} from '../assets/icons/sports/bowling.svg'
import {ReactComponent as FootballIcon} from '../assets/icons/sports/football.svg'
import {ReactComponent as FutsalIcon} from '../assets/icons/sports/futsal.svg'
import {ReactComponent as HandballIcon} from '../assets/icons/sports/handball.svg'
import {ReactComponent as HockeyIcon} from '../assets/icons/sports/hockey.svg'
import {ReactComponent as RugbyIcon} from '../assets/icons/sports/rugby.svg'
import {ReactComponent as TennisIcon} from '../assets/icons/sports/tennis.svg'
import {ReactComponent as VolleyballIcon} from '../assets/icons/sports/volleyball.svg'
import {ReactComponent as FbIcon} from '../assets/icons/fb.svg'
import {ReactComponent as InstagramIcon} from '../assets/icons/ig.svg'
import {ReactComponent as IconUp} from '../assets/icons/direct-up.svg'

let markerIconMe = L.icon({
    iconUrl: iconMe,
    // shadowUrl: iconShadow,
    iconSize: new L.Point(30, 30),
})


function ChangeView({center, zoom}) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

function CenterMap({fullscreen, center, zoom}) {
    let map = useMap()
    React.useEffect(() => {
        map.invalidateSize()
        map.setView(center, zoom)
    }, [fullscreen, center])
}

let BusinessDetailsPage = ({intl, dispatch}) => {

    let {business_id: businessId} = useSelector(state => state.app)
    let business = null
    let params = useParams();

    if (!businessId) {
        business = useSelector(state => getBusinessBySubdomain(params.business, state))
        businessId = business?.id
    } else {
        business = useSelector(state => state.business.byId[businessId])
    }

    let sports = useSelector(state => state.sport.byId)

    let amenities = useSelector(state => state.amenity.byId)

    let today = moment().day()

    let amenityIcons = {}
    amenityIcons['Prysznice'] = <ShowerIcon/>
    amenityIcons['Parking'] = <ParkIcon/>
    amenityIcons['Szatnie'] = <RoomIcon/>
    amenityIcons['Trybuny'] = <ShopIcon/>
    amenityIcons['Oświetlenie'] = <ShopIcon/>
    amenityIcons['Zamykane szafki'] = <RoomIcon/>
    amenityIcons['Dostępność dla osób niepełnosprawnych'] = <ParkIcon/>
    amenityIcons['Ubezpieczenie obiektu'] = <ShowerIcon/>

    let sportIcons = {}
    sportIcons['Futsal'] = <FutsalIcon/>
    sportIcons['Piłka nożna'] = <FootballIcon/>
    sportIcons['Rugby'] = <RugbyIcon/>
    sportIcons['Siatkówka'] = <VolleyballIcon/>
    sportIcons['Koszykówka'] = <BasketballIcon/>
    sportIcons['Piłka ręczna'] = <HandballIcon/>
    sportIcons['Hokej'] = <HockeyIcon/>
    sportIcons['Kręgle'] = <BowlingIcon/>
    sportIcons['Tenis'] = <TennisIcon/>

    let position = [business.address?.lat ?? 0, business.address?.lon ?? 0]

    return <div className='details-page'>
        <div className='row'>
            <div className='col col-lg-6'>
                <FacilityImageSlider images={business?.images ?? []}/>
                <div className='description' dangerouslySetInnerHTML={{__html: business.description}}/>
                <div className='sports'>
                    <div className='heading'>
                        Udogodnienia
                    </div>
                    {
                        business.amenities?.map(amenityId => amenities[amenityId]).map((amenity, idx) =>
                            <div className='sport'>
                                {amenityIcons[amenity.name]}
                                {amenity.name}
                            </div>)
                    }
                    {
                        business.amenities?.length === 0 && <span>BRAK DANYCH</span>
                    }
                </div>
                <div className='sports'>
                    <div className='heading'>
                        Sporty
                    </div>
                    {
                        business.sports?.map(sportId => sports[sportId]).map((sport, idx) =>
                            <div className='sport'>
                                {sportIcons[sport.name]}
                                {sport.name}
                            </div>)
                    }
                    {
                        business.sports?.length === 0 && <span>BRAK DANYCH</span>
                    }
                </div>
            </div>
            <div className='col col-lg-6' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className='map-container' style={{width: '100%', height: '400px'}}>
                    <div className='map' style={{height: '100%'}}>
                        <MapContainer center={position} zoom={13}>
                            <ChangeView center={position}/>
                            <CenterMap fullscreen={false} center={position}/>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MapMarker lat={position[0]}
                                       lon={position[1]} icon={markerIconMe}>
                            </MapMarker>
                        </MapContainer>
                    </div>
                </div>
                <div className='address'>
                    <div className='address-text'>
                        <div>Adres</div>
                        <div>{business.address.address}</div>
                        <div>{business.address.city} {business.address.postcode}</div>
                    </div>
                    <div className='divider'/>
                    <div>
                        <a href={'http://www.google.com/maps/place/' + business.address.lat + ',' + business.address.lon}
                           className='pointer'>
                            <IconUp/>
                        </a>
                    </div>
                </div>
                <div className='opening-hours'>
                    <div className='heading'>
                        Godziny otwarcia
                    </div>
                    <div className='days'>
                        {
                            DAYS.map((day) => {
                                return <div className={day === today ? 'active' : ''}>
                                    <div>{moment().day(day).format('dddd')}</div>
                                    <div>
                                        {
                                            business.openingHours
                                                ?.filter(openingHour => openingHour.day === day).length === 0 &&
                                            <span className='inavailable'>nieczynne</span>}
                                        {
                                            business.openingHours?.length > 0 &&
                                            business.openingHours?.filter((openingHour) => {
                                                return openingHour.day === day;
                                            })
                                                .map((openingHour, key) =>
                                                    <><br/><span
                                                        key={key}>{addLeadingZeros(openingHour.time_from.hour, 2)}:{addLeadingZeros(openingHour.time_from.minute, 2)}-
                                                        {addLeadingZeros(openingHour.time_to.hour, 2)}:{addLeadingZeros(openingHour.time_to.minute, 2)}</span></>
                                                )
                                        }
                                    </div>
                                </div>
                            })}
                    </div>
                </div>
                <div className='contacts'>
                    <div className='phone'>
                        <div>Numer tel:</div>
                        {
                            business.phone && <a className='number' href={'tel:' + business.phone}>
                                <div>{business.phone}</div>
                                <div className='icon'>
                                    <CallIcon/>
                                </div>
                            </a>
                        }
                        {
                            !business.phone && <div>BRAK</div>
                        }
                    </div>
                    <div className='separator'></div>
                    {
                        (business.facebook || business.instagram || business.website) && <div className='socials'>
                            {business.facebook &&
                                <div>
                                    <a href={business.facebook} target='_blank'>
                                        <FbIcon/>
                                        <span>Facebook</span>
                                    </a>
                                </div>
                            }
                            {business.instagram &&
                                <div>
                                    <a href={business.instagram} target='_blank'>
                                        <InstagramIcon/>
                                        <span>Instagram</span>
                                    </a>
                                </div>
                            }
                            {business.website &&
                                <div>
                                    <a href={business.website} target='_blank'>
                                        <WebIcon/>
                                        <span>Strona internetowa</span>
                                    </a>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}

export default composeUtils(BusinessDetailsPage)