import React, {Component} from "react";
import TermList from './TermList'
import {Row, Col} from 'reactstrap'
import Slider from "react-slick";
import {dateFormat} from "../../utils/dateUtil";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux"
import {selectMonth} from "../../actions/reservation";
import {createAction} from "../../utils/reduxUtil";
import {SELECT_DAY} from "../../constants/actiontypes/reservation";

let DaySlider = () => {

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
    }

    let {
        current_service: serviceId,
        current_facility: facilityId
    } = useSelector(state => state.reservationModal)

    let dispatch = useDispatch()

    let currentMonth = useSelector(state => state.reservationModal.current_month)

    let currentDay = useSelector(state => state.reservationModal.current_day)

    let isSameDate = (date1, date2, format) => {
        if (typeof date2 === 'string') {
            date2 = moment(date2)
        }
        return date1.format(format) === date2.format(format);
    }

    let isCurrentMonth = (date) => {
        return isSameDate(date, currentMonth, 'Y-M');
    }

    let days = useSelector(state => state.reservationModal.days)

    let isCurrentDay = (date) => {
        return isSameDate(date, currentDay, 'YYY-MM-dd');
    }

    let select = (date) => dispatch(createAction(SELECT_DAY, {date: date.format('YYYY-MM-DD')}))

    return <>
        <h3 className='mt-4'>Dzień</h3>
        <Slider {...settings} className='month-slider reservation-slider'>
            {
                days.map((day) => <div
                    className={'item day-item ' + (isCurrentDay(day) ? 'selected' : '')}
                    onClick={() => select(day)}
                >
                    <div className='day-shortcut'>{dateFormat(day, 'dd')}</div>
                    <div className='day-number'>{dateFormat(day, 'D')}</div>
                </div>)
            }
        </Slider>
    </>
}

export default DaySlider