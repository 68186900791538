var initialState = {
    byId: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.service) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.service
            }
        }
    }
    switch (action.type) {
        default:
            return state;
    }
};