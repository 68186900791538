import {
    SET_LOGGED_USER
} from "../constants/actiontypes/app";
import moment from 'moment';
import {createAction} from "../utils/reduxUtil";
import {normalize} from 'normalizr';
import {
    CLOSE_RESERVATION_MODAL,
    CONFIRM_RESERVATION_SUCCESS,
    LOAD_FINISHED_RESERVATIONS_REQUEST,
    LOAD_FINISHED_RESERVATIONS_SUCCESS,
    LOAD_UPCOMING_RESERVATIONS_REQUEST,
    LOAD_UPCOMING_RESERVATIONS_SUCCESS,
    OPEN_RESERVATION_CONFIRMATION,
    OPEN_RESERVATION_MODAL,
    RESERVATION_CANCEL_SUCCESS,
    RESERVATION_MODAL_FETCH_TERMS_REQUEST,
    RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    RESERVATION_UPDATE_SUCCESS,
    SELECT_DAY,
    SELECT_MONTH
} from "../constants/actiontypes/reservation";
import {CANCELED, CONFIRMED} from "../constants/reservationStatuses";
import {createReservation, getReservations, getTerms} from "../api/app";
import * as api from "../core/api";
import {store} from '../store';
import {OPEN_LOGIN_MODAL} from "../constants/actiontypes/login";
import {toastError, toastSuccess} from "../utils/toastUtil";
import {addLeadingZeros} from "../utils/stringUtil";
import schemas from "../reducers/schemas";
import {appError} from "./app";
import handleError from "../utils/errorUtil";

export function setLoggedUser(loggedUser) {
    return {type: SET_LOGGED_USER, payload: loggedUser}
}

export function initReservationModal(serviceId, minReservationTime, maxReservationTime) {
    var currentDate = moment();
    var endDate = moment.unix(maxReservationTime);
    var days = [];
    for (let i = currentDate.format('D'); i <= currentDate.daysInMonth(); i++) {
        days.push(moment(currentDate.set('date', i)));
    }
    let months = [];
    do {
        months.push(moment(currentDate));
        currentDate.add(1, 'M');
    } while (currentDate.unix() < endDate.unix());
    months.push(currentDate);
    return function (dispatch) {
        dispatch(createAction(OPEN_RESERVATION_MODAL, {serviceId, months, days}));
        // dispatch(createAction(RESERVATION_MODAL_FETCH_TERMS_REQUEST));
        // getTerms(serviceId, moment().format('Y-MM-DD'), priceListId).then((response) => {
        //     let terms = response.data;
        //     dispatch(createAction(RESERVATION_MODAL_FETCH_TERMS_SUCCESS, {terms}));
        // })
    }
}

export function selectMonth(month) {
    let now = moment()
    return function (dispatch) {
        var days = [];
        var date = moment(month);
        let firstDay = 1
        if(month.month() === now.month()) {
            firstDay = now.date()
        }
        for (let i = date.set('date', firstDay).format('D'); i <= date.daysInMonth(); i++) {
            days.push(moment(date.set('date', i)));
        }
        dispatch(createAction(SELECT_MONTH, {month: month, days}));
    }
}

export function makeReservation() {
    return function (dispatch) {
        let state = store.getState();
        if (!state.app.logged_user) {
            dispatch(createAction(OPEN_LOGIN_MODAL));
            return;
        }
        let data = {};
        data.service_id = state.reservationModal.current_service;
        data.facility_id = state.reservationModal.current_facility;
        data.resources = state.reservationModal.current_resources;
        data.price_list_id = state.reservationModal.priceListId;
        data.price = state.reservationModal.current_term.price;
        let term = state.reservationModal.current_term;
        data.date = state.reservationModal.current_day
        data.starttime = data.date + ' ' + addLeadingZeros(term.from.hour, 2) + ':' + addLeadingZeros(term.from.minute, 2) + ':00';
        data.endtime = data.date + ' ' + addLeadingZeros(term.to.hour, 2) + ':' + addLeadingZeros(term.to.minute, 2) + ':00';
        createReservation(data).then((response) => {
            dispatch(createAction(OPEN_RESERVATION_CONFIRMATION));
            toastSuccess('Dodano rezerwację');
        }).catch(err => handleError(err, dispatch))
    }
}

export function loadUpcomingReservations() {
    return function (dispatch) {
        dispatch(createAction(LOAD_UPCOMING_RESERVATIONS_REQUEST));
        getReservations({'filter[upcoming]': true}, 'facility,service,price').then((response) => {
            let normalizedData = normalize(response.data.data, [schemas.reservationSchema]);
            dispatch(createAction(LOAD_UPCOMING_RESERVATIONS_SUCCESS, normalizedData));
        })
    }
}


export function initReservationPage() {
    return function (dispatch) {
        dispatch(createAction(LOAD_UPCOMING_RESERVATIONS_REQUEST));
        dispatch(createAction(LOAD_FINISHED_RESERVATIONS_REQUEST));
        let promises = [
            getReservations({'filter[upcoming]': true,sort: '-starttime'}, 'facility,service'),
            getReservations({'filter[finished]': true,sort: 'starttime'}, 'facility,service')
        ];
        Promise.all(promises)
            .then((r) => initReservationPageSuccess(dispatch, r))
            .catch((err) => appError(dispatch, err));
    }
}

function initReservationPageSuccess(dispatch, response) {
    let normalizedData = normalize(response[0].data.data, [schemas.reservationSchema]);
    dispatch(createAction(LOAD_UPCOMING_RESERVATIONS_SUCCESS, normalizedData));
    normalizedData = normalize(response[1].data.data, [schemas.reservationSchema]);
    dispatch(createAction(LOAD_FINISHED_RESERVATIONS_SUCCESS, normalizedData));
}

export function updateReservation(reservationId, formData, dispatch) {
    return api.updateReservation(reservationId, formData).then((response) => {
        dispatch(createAction(RESERVATION_UPDATE_SUCCESS, normalize(response.data, schemas.reservationSchema)))
        return response.data;
    }).catch(error => handleError(error, dispatch))
}