import React from 'react'
import TextInput from "../../core/form/TextInput"
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg'

let SearchTextField = ({form}) => {

    return <TextInput {...form} name='text' label='app.header.search_text_field' Icon={SearchIcon}/>

}

export default SearchTextField