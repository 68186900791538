import React from 'react';
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux"
import {withRouter} from 'react-router-dom';
import {loadFacilityTypes} from "../../actions/facilityType";
import icon from '../../assets/icons/map/tennis.svg'
import getIcon from "../../constants/facilityIcons";
import {createAction} from '../../core/utils/appUtil'
import {
    SEARCH_REMOVE_FILTER_FACILITY_TYPE,
    SEARCH_SET_FILTER_FACILITY_TYPE
} from "../../constants/actiontypes/facilityType";

let FacilityType = ({id}) => {

    let dispatch = useDispatch()

    let {
        byId: facilityTypes
    } = useSelector(state => state.facilityType)

    let facilities = useSelector(state => state.facility.byId)

    let {
        facility_type: currentFacilityType,
        result: results
    } = useSelector(state => state.search)

    let facilityType = facilityTypes[id]

    let isDisabled = currentFacilityType && currentFacilityType !== facilityType.id

    let count = 0//results.filter(id => facilities[id].type === facilityType.id).length

    let selectFacilityType = () => {
        if (currentFacilityType === facilityType.id) {
            dispatch(createAction(SEARCH_REMOVE_FILTER_FACILITY_TYPE))
        } else {
            dispatch(createAction(SEARCH_SET_FILTER_FACILITY_TYPE, facilityType.id))
        }
    }

    return <div className={'item' + (isDisabled ? ' disabled' : '')}
                key={facilityType.id}
                onClick={selectFacilityType}
    >
        <div className='image'>
            {count > 0 && <div className='count'><span>{count}</span></div>}
            <img
                src={getIcon(facilityType, false)}
            />
        </div>
        <span className='name'>{facilityType.name}</span>
    </div>
}

export default FacilityType
