export function createAction(action, payload = null) {
    return {type: action, payload: payload}
}


export function createArray(data) {
    let arr = [];
    data.forEach((elem) => {
        arr[elem.id] = elem;
    });
    return arr;
}