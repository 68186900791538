import * as types from "../constants/actiontypes/app";
import {
    APP_SET_BUSINESS,
    CHANGE_LANGUAGE_REQUEST,
    LOAD_LANGUAGES_REQUEST,
    LOAD_LANGUAGES_SUCCESS,
    SET_LOCATION, SET_MOBILE
} from "../constants/actiontypes/app";
import {LOAD_PROFILE_SUCCESS} from "../constants/actiontypes/profile";

export const APP_OPEN_SEARCH_MODAL = 'APP_OPEN_SEARCH_MODAL'

var initialState = {
    business_id: null,
    logged_user: null,
    languages: [],
    loading: true,
    language: {
        code: 'en',
        name: 'English'
    },
    currentLocation: null,
    mobile: false,
    search_modal_opened: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.APP_SET_BUSINESS: {
            return {
                ...state,
                business_id: action.payload.result[0]
            }
        }
        case types.APP_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.APP_LOADED:
            return {
                ...state,
                loading: false
            }
        case types.SET_LANGUAGE:
            return {
                ...state,
                lang: action.payload
            }
        case types.SET_LOGGED_USER:
            return {
                ...state,
                logged_user: action.payload
            }
        case LOAD_LANGUAGES_REQUEST:
            return {
                ...state,
                languages: []
            }
        case LOAD_LANGUAGES_SUCCESS:
            let lang = action.payload.filter((language) => language.code === state.language.code);
            return {
                ...state,
                languages: action.payload,
                language: lang[0]
            }
        case CHANGE_LANGUAGE_REQUEST:
            localStorage.setItem('current_language', JSON.stringify(action.payload))
            return {
                ...state,
                language: action.payload
            }
        case SET_LOCATION:
            return {
                ...state,
                currentLocation: action.payload
            }
        case SET_MOBILE: {
            return {
                ...state,
                mobile: action.payload
            }
        }
        case LOAD_PROFILE_SUCCESS: {
            return {
                ...state,
                loading: false,
                logged_user: action.payload
            }
        }
        case APP_OPEN_SEARCH_MODAL: {
            return {
                ...state,
                search_modal_opened: action.payload
            }
        }
        default:
            return state;
    }
};