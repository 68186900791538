export default {
    required: {
        _: "Pole jest wymagane"
    },
    invalid_password: 'Zły email lub hasło',
    required_min_partners: "Wymagana ilość personelu: {count}",
    same: 'Hasła nie są identyczne',
    min: {
        string: 'Wymagane conajmniej 8 znaków'
    },
    account_blocked: 'Konto jest zablokowane',
    unique: {
        _: "Wartość już istnieje",
        email: "Adres email już istnieje"
    },
    confirmed: "Hasła nie zgadzaja się",
    match: {
        password: 'Hasło nie zgadza się z aktualnym'
    },
    same_password: 'Hasło musi się różnić od aktualnego',
    missing_services_in_facility: 'Brak dostępnych usług w wybranym obiekcie',
    personnel_unavailable: 'Jeden z wybranych pracowników jest niedostępny',
    resource_unavailable: 'Jeden z wybranych zasobów jest niedostępny',
    service_unavailable: 'Wybrana usługa jest niedostępna',
    facility_unavailable: 'Wybrany obiekt jest niedostępny'
}