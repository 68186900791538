import React from 'react';
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {getFacilityDetails} from "../actions/facility";
import Skeleton from "react-loading-skeleton";
import {AiFillHeart, AiOutlineHeart} from 'react-icons/ai';
import {useNavigate} from "react-router-dom";
import {composeUtils} from "../core/utils/appUtil";
import missingImage from '../assets/img/missing-image2.svg'
import {getBusinessMainImage, getMainImage} from "../utils/facilityUtil";

let BusinessSliderItem = ({loading, businessId, dragging, dispatch}) => {

    let navigate = useNavigate()

    let business = useSelector(state => state.business.byId[businessId])

    let openBusinessPage = (e) => {
        if (!dragging) {
            navigate('/biznes/' + business.subdomain)
        } else {
            e.stopPropagation();
        }
    }

    if (loading) {
        return <div className='facility-slider-item'>
            <div className='facility-slider-item-card'>
                <Skeleton height={165}/>
                <p><Skeleton height={10}/></p>
                <p><Skeleton height={10}/></p>
            </div>
        </div>
    }

    return <div className='facility-slider-item' onClick={openBusinessPage}>
        <div className='facility-slider-item-card'>
            <div className='mb-2 image-container'>
                <img src={getBusinessMainImage(businessId)}
                     style={{maxWidth: '100%'}}
                />
            </div>
            <div className='details'>
                <p className='fw-bold name'>{business.name}</p>
                <p className='address'>{business.address_text}</p>
            </div>
        </div>
    </div>
}

export default composeUtils(BusinessSliderItem)
