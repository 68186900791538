import {trans, translationExists} from "./translationUtil";

export function validateEmail(email) {
    if (!email) {
        return 'validation.required'
    }
    var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
        return 'Pole musi być poprawnym adresem email';
    }
    return null;
}

export function validateFullname(fullname) {
    return validateEmptyOrWhitespace(fullname, 4);
}

export function validateEmptyOrWhitespace(text, length = 0) {
    if (!text || text.trim().length < length) {
        return 'Zła wartość';
    }
    return null;
}

export function validateNumber(text, length = 0, onlyPositive = true) {
    text += "";
    if (!text || text.trim().length < length) {
        return 'Zła wartość';
    }
    var regex = new RegExp('^[0-9]+$');
    if (!regex.test(text)) {
        return 'Zła wartość';
    }
    if (parseInt(text) < 0) {
        return 'Zła wartość';
    }
    return null;
}

export function validatePrice(text, length = 0) {
    if (!text || text.trim().length < length) {
        return 'Zła wartość';
    }
    var regex = new RegExp('^\\d*(\\.\\d{0,2})?$', 'g');
    if (!regex.test(text)) {
        return 'Zła wartość';
    }
    return null;
}

export function validateRequired(value) {
    if (!value) {
        return 'validation.required';
    }
}

export function validatePassword(password) {
    if (!password) {
        return 'Podaj hasło';
    }
    if (password.length < 8) {
        return 'Hasło musi mieć conajmniej 8 znaków';
    }
    return null;
}


export function getValidationMessage(intl, name, type) {
    let message = null;
    if(Array.isArray(type)) {
        type = type[0];
    }
    let params = [];
    if(typeof type === 'object') {
        params = type;
        type = type['code'];
    }
    type = type.replace('validation.','');
    if (translationExists(intl, 'validation.' + type + '.' + name)) {
        message = trans(intl, 'validation.' + type + '.' + name);
    }
    if (!message) {
        message = trans(intl, 'validation.' + type);
    }
    for(var param in params) {
        let key = '{' + param + '}';
        message = message.replace(key, params[param]);
    }
    return message;
}