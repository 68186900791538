import React from 'react';
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux"
import {useHistory, useLocation, useNavigate} from 'react-router-dom'
import {withRouter} from 'react-router-dom';
import {loadFacilityTypes} from "../../actions/facilityType";
import icon from '../../assets/icons/map/tennis.svg'
import {createAction} from "../../core/utils/appUtil";
import {SEARCH_SET_DISTANCE, SEARCH_CHANGE_SORT} from "../../constants/actiontypes/facility";
import moment from 'moment';
import {addLeadingZeros} from "../../core/utils/stringUtil";
import * as QueryString from "query-string"
import SelectInput from '../../core/form/select/SelectInput'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {trans} from "../../core/utils/translationUtil";
import FormControl from '@mui/material/FormControl';


let SearchHeader = ({}) => {

    let navigate = useNavigate()

    let location = useLocation()

    const params = QueryString.parse(location.search, {arrayFormat: 'bracket'});

    let allSports = useSelector(state => state.sport.byId)

    let sports = useSelector(state => state.sport.all).map(sportId => allSports[sportId])

    let getCurrentCustomFieldOptions = () => {
        if (Array.isArray(params.customFieldOptions)) {
            return params.customFieldOptions
        }
        if (params.customFieldOptions) {
            return [params.customFieldOptions]
        }
        return []
    }

    let day = params.day ? params.day : moment().format('Y-MM-DD');

    let hour = params.hour ? params.hour : moment().hour()

    let onlyAvailable = params.onlyAvailable ? true : false

    let sport = params.sport

    let dispatch = useDispatch()

    let allFacilityTypes = useSelector(state => state.facilityType.byId)

    let allCustomFields = useSelector(state => state.customField.byId)

    let allCustomFieldOptions = useSelector(state => state.customFieldOption.byId)

    let facilityType = allFacilityTypes[params.facilityType]

    let {
        result,
        loading,
        loading_facilities: loadingFacilities
    } = useSelector(state => state.search)

    let distances = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]

    let distance = params.distance ? params.distance : 10

    let setParam = (name, value) => {
        params[name] = value
        navigate('/szukaj?' + QueryString.stringify(params, {arrayFormat: 'bracket'}))
    }

    let setOnlyAvailable = (e) => {
        delete params.onlyAvailable
        if (e.target.value) {
            params.onlyAvailable = e.target.value;
        }
        navigate('/szukaj?' + QueryString.stringify(params, {arrayFormat: 'bracket'}))
    }

    let setCustomField = (id, value, e) => {
        let currentCustomFieldOptions = getCurrentCustomFieldOptions()
            .map(cfo => allCustomFieldOptions[cfo])
            .filter(cfo => cfo.custom_field_id != id)
            .map(cfo => cfo.id)
        if (value && value !== -1) {
            currentCustomFieldOptions.push(value)
        }
        params.customFieldOptions = currentCustomFieldOptions
        navigate('/szukaj?' + QueryString.stringify(params, {arrayFormat: 'bracket'}))
    }

    let getCustomFieldValue = (id) => getCurrentCustomFieldOptions()
        .map(cfo => allCustomFieldOptions[cfo])
        .filter(cfo => cfo.custom_field_id == id)
        .shift()?.id ?? -1

    let today = moment()

    let hours = []
    if (day === moment().format('Y-MM-DD')) {
        for (let i = today.startOf('hour').hour(); i < 24; i++) {
            hours.push(i)
        }
    } else {
        for (let i = 0; i < 24; i++) {
            hours.push(i)
        }
    }

    return <div className='d-flex flex-column search-header'>
        <br/>
        <div className='d-flex flex-row'>
            {/*<FormControl>*/}
            {/*    <InputLabel>Data</InputLabel>*/}
            {/*    <Select name='day' value={day} onChange={e => setParam('day', e.target.value)} label='Data'>*/}
            {/*        <MenuItem value={today.format('Y-MM-DD')}>Dzis</MenuItem>*/}
            {/*        <MenuItem value={today.add(1, 'days').format('Y-MM-DD')}>Jutro</MenuItem>*/}
            {/*        {[2, 3, 4, 5, 6, 7, 8].map(d =>*/}
            {/*            <MenuItem*/}
            {/*                value={today.clone().add(d, 'days').format('Y-MM-DD')}>*/}
            {/*                {today.clone().add(d, 'days').format('Y-MM-DD')}</MenuItem>*/}
            {/*        )}*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            {/*<FormControl>*/}
            {/*    <InputLabel>Godzina</InputLabel>*/}
            {/*    <Select name='hour' value={hour} onChange={e => setParam('hour', e.target.value)} label='Godzina'>*/}
            {/*        {*/}
            {/*            hours.map(hour => <MenuItem value={hour}>{addLeadingZeros(hour, 2)}:00</MenuItem>)*/}
            {/*        }*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            {
                params.place &&
                <FormControl>
                    <InputLabel>Odległość</InputLabel>
                    <Select name='distance' value={distance} onChange={e => setParam('distance', e.target.value)}
                            label='Odległość'>
                        {
                            distances.map(distance => <MenuItem value={distance}>do {distance} km</MenuItem>)
                        }
                    </Select>
                </FormControl>
            }
            {/*<FormControl>*/}
            {/*    <InputLabel>Dostępność</InputLabel>*/}
            {/*    <Select name='available' value={onlyAvailable} onChange={setOnlyAvailable} label='Dostępność'>*/}
            {/*        <MenuItem value={false}>Wszystkie</MenuItem>*/}
            {/*        <MenuItem value={true}>Tylko dostępne</MenuItem>*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            {
                params.type === 'training' &&
                <FormControl>
                    <InputLabel>Sport</InputLabel>
                    <Select name='sport' value={sport} onChange={e => setParam('sport', e.target.value)} label='Sport'>
                        {
                            sports.map(s => <MenuItem value={s.id}>{s.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            }
            {params.type === 'rental' &&
                facilityType?.custom_fields
                    ?.map(cfId => allCustomFields[cfId])
                    .map(customField =>
                        <FormControl>
                            <InputLabel>{customField.name}</InputLabel>
                            <Select name={'customField'}
                                    label={customField.name}
                                    value={getCustomFieldValue(customField.id)}
                                    onChange={(e) => setCustomField(customField.id, e.target.value, e)}>
                                <MenuItem value={-1}>Wszystkie</MenuItem>
                                {
                                    customField.options
                                        .map(option => allCustomFieldOptions[option])
                                        .map(option => <MenuItem value={option.id}>{option.value}</MenuItem>)
                                }
                            </Select>
                        </FormControl>)
            }
        </div>
    </div>

}

export default SearchHeader
