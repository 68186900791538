import React from 'react';
import {connect} from "react-redux";
import {loadClosestFacilities, loadFavouriteFacilities, loadProposedFacilities} from "../actions/facility";
import {trans} from "../utils/translationUtil";
import {injectIntl} from "react-intl";
import Loader from "../components/Loader";
import {loadFacilityTypes} from "../actions/facilityType";
import SearchResultTile from "../components/SearchResultTile";
import {Col} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux'
import AccountPage from './AccountPage'
import {composeUtils} from "../core/utils/appUtil";
let FavouritePage = ({dispatch}) => {

    React.useEffect(() => {
        // dispatch(loadFavouriteFacilities())
    }, [])

    let {loading} = useSelector(state => state.facility)

    return <AccountPage>
        <h3>Ulubione obiekty</h3>
        {loading && <Loader/>}
        {
            loading && this.props.favourite.map(facility =>
                <SearchResultTile facilityId={facility} key={facility} enable_hover={false}/>)
        }
    </AccountPage>
}

export default composeUtils(FavouritePage)