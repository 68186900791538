import React from 'react'
import {injectIntl} from "react-intl"
import {Link} from "react-router-dom"
import moment from 'moment'
import {trans} from '../utils/translationUtil'

let Footer = ({intl}) => {
    return <footer className='d-flex justify-content-between'>
        <div>
            © {moment().format('YYYY')} {trans(intl, 'app.app_name')} {trans(intl, 'app.footer.all_rights_reserved')}
        </div>
        <div className='d-flex links align-items-center'>
            <a href='/pdf/Bookendy_Regulamin_dla_Klienta.docx.pdf' className='px-5'>Regulamin</a>
            <a href='/pdf/Polityka_prywatności_Bookendy.pdf' className='px-5'>Polityka prywatności</a>
            <Link to='/' className='px-5'>Kontakt</Link>
        </div>
    </footer>
}

export default injectIntl(Footer)
