import { schema } from 'normalizr';
const facilitySchema     = new schema.Entity('facility');
const serviceSchema     = new schema.Entity('service');
const businessSchema     = new schema.Entity('business');
const reservationSchema     = new schema.Entity('reservation');
const currencySchema     = new schema.Entity('currency');
const regionSchema     = new schema.Entity('region');
const agreementSchema     = new schema.Entity('agreement');
const facilityTypeSchema     = new schema.Entity('facilityType');
const notificationSchema     = new schema.Entity('notification');
const amenitySchema     = new schema.Entity('amenity');
const sportSchema     = new schema.Entity('sport');
const resourceSchema     = new schema.Entity('resource');
const customFieldSchema     = new schema.Entity('customField');
const customFieldOptionSchema     = new schema.Entity('customFieldOption');

customFieldSchema.define({
    options: [customFieldOptionSchema]
})
facilitySchema.define({
    services: [serviceSchema],
    business: businessSchema,
    region: regionSchema,
    type: facilityTypeSchema,
    resources: [resourceSchema],
    custom_field_values: [customFieldOptionSchema]
});
facilityTypeSchema.define({
    custom_fields: [customFieldSchema]
})
reservationSchema.define({
    facility: facilitySchema,
    service: serviceSchema,
    currency: currencySchema
});
businessSchema.define({
    facilities: [facilitySchema],
    services: [serviceSchema],
    amenities: [amenitySchema],
    sports: [sportSchema]
});

export default {
    facilitySchema,
    serviceSchema,
    businessSchema,
    reservationSchema,
    currencySchema,
    regionSchema,
    agreementSchema,
    facilityTypeSchema,
    notificationSchema,
    sportSchema
};