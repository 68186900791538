import React from 'react';
import {Button, Form, Modal} from 'reactstrap';
import {connect} from "react-redux";
import * as steps from "../../constants/loginModalPages";
import {CLOSE_LOGIN_MODAL} from "../../constants/actiontypes/login";
import {createAction} from "../../utils/reduxUtil";
import SubmitFormButton from '../../core/buttons/SubmitFormButton'
import AppModal from '../../core/modal/AppModal'
import ModalForm from '../../core/modal/ModalForm'
import {useSelector, useDispatch} from 'react-redux'
import {useForm} from "react-hook-form";
import TextInput from "../../core/form/TextInput";
import {useIntl} from 'react-intl'
import {trans} from '../../core/utils/translationUtil'
import {setErrors} from "../../core/utils/formUtil";
import api from '../../core/api'
import {login, register} from '../../actions/auth'
import backImage from "../../assets/img/arrow-left.svg";
import SearchTextField from "../header/SearchTextField";
import PlaceField from "../header/PlaceField";
import {APP_OPEN_SEARCH_MODAL} from '../../reducers/app'
import {useNavigate, useLocation} from "react-router-dom";
import * as QueryString from "query-string";

let MobileSearchModal = () => {

    let {
        search_modal_opened: isOpen,
    } = useSelector(state => state.app)

    let navigate = useNavigate()

    let location = useLocation()

    let params = QueryString.parse(location.search)

    let submit = (data) => {
        const searchParams = new URLSearchParams();
        if (data.text) {
            searchParams.append("text", data.text);
        }
        if (data.place) {
            searchParams.append("place", data.place);
        }
        if (data.date) {
            searchParams.append("date", data.date);
        }
        navigate('/szukaj?' + searchParams.toString())
        dispatch(createAction(APP_OPEN_SEARCH_MODAL, false))
    }

    let intl = useIntl()

    let dispatch = useDispatch()

    let closeModal = () => dispatch(createAction(APP_OPEN_SEARCH_MODAL, false))

    let isMobile = useSelector(state => state.app.mobile)

    let form = useForm({defaultValues: params})

    let renderForm = (form) => {
        return <div className='search-form'>
            <div className='header'>
                <img src={backImage} onClick={closeModal}/>
                <span>Wyszukaj obiekt</span>
            </div>
            <SearchTextField form={form}/>
            <PlaceField form={form}/>
        </div>
    }

    return <ModalForm
        data={params}
        isOpen={isOpen}
        closeModal={closeModal}
        title='Wyszukaj'
        onSubmit={submit}
        renderForm={renderForm}
        submitText='Wyszukaj'
        className={isMobile ? 'mobile' : ''}
        cancelButton={!isMobile}
    />
}

export default MobileSearchModal