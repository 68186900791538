import {
    LOAD_FACILITY_SUCCESS,
    RESIZE_SEARCH_PAGINATION,
    SEARCH_SUCCESS
} from "../constants/actiontypes/facility";
import {
    ADD_NOTIFICATION, DELETE_NOTIFICATION_REQUEST,
    LOAD_NOTIFICATIONS_REQUEST,
    LOAD_NOTIFICATIONS_SUCCESS, RESET_UNREADED_COUNT
} from "../constants/actiontypes/notification";

var initialState = {
    byId: [],
    unreaded_count: 0,
    all: [],
    page: 1,
    has_more: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_NOTIFICATIONS_REQUEST: {
            return {
                ...state
            }
        }
        case LOAD_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                unreaded_count: action.payload[2].current_page === 1 ? action.payload[2].unreaded : 0,
                byId: {
                    ...state.byId,
                    ...action.payload[0]
                },
                all: [
                    ...state.all,
                    ...action.payload[1]
                ],
                page: action.payload[2].current_page,
                has_more: action.payload[2].last_page !== action.payload[2].current_page
            }
        }
        case ADD_NOTIFICATION: {
            return {
                ...state,
                unreaded_count: state.unreaded_count + 1,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload
                },
                all: [
                    action.payload.id,
                    ...state.all
                ]
            }
        }
        case DELETE_NOTIFICATION_REQUEST: {

            let byId = Object.assign({}, state.byId)
            delete byId[action.payload]
            return {
                ...state,
                byId: byId,
                all: state.all.filter((n) => n !== action.payload),
                unreaded_count: state.unreaded_count - 1
            }
        }
        case RESET_UNREADED_COUNT: {
            return {
                ...state,
                unreaded_count: 0
            }
        }
        default:
            return state;
    }
};