import React from "react";
import {toastSuccess} from "../../core/utils/appUtil";
import {updateReservation} from "../../actions/reservation";
import {useDispatch} from 'react-redux'
import {
    REJECTED,
    RESERVATION_STATUS_CANCELLED,
    RESERVATION_STATUS_CONFIRMED
} from "../../core/constants/reservationStatuses";
import {useSelector} from 'react-redux'
import Checkbox from "../../core/form/Checkbox";
import AppModal from '../../core/modal/AppModal'
import * as QueryString from "query-string"
import * as api from "../../core/api";
import {createAction} from "../../utils/reduxUtil";
import {RESERVATION_CANCEL_SUCCESS, RESERVATION_UPDATE_SUCCESS} from "../../constants/actiontypes/reservation";
import schemas from "../../reducers/schemas";
import handleError from "../../utils/errorUtil";

const ReservationStatusChangeModal = (props) => {

    let {
        setModalOpened,
        reservationId,
        status,
    } = props

    let dispatch = useDispatch();

    let reservation = useSelector(state => state.reservation.byId[reservationId])

    let save = async () => {
        api.deleteReservation(reservationId).then((response) => {
            dispatch(createAction(RESERVATION_CANCEL_SUCCESS, reservationId))
            toastSuccess('Anulowano rezerwację')
            setModalOpened(() => false)
            return response.data;
        }).catch(error => handleError(error, dispatch))
    }

    function getTitle() {
        switch (status) {
            case RESERVATION_STATUS_CANCELLED: {
                return 'Anulowanie rezerwacji'
            }
            case RESERVATION_STATUS_CONFIRMED: {
                return 'Potwierdzenie rezerwacji'
            }
            case REJECTED: {
                return 'Odrzucenie rezerwacji'
            }
            default:
                return null
        }
    }

    function getText() {
        switch (status) {
            case RESERVATION_STATUS_CANCELLED: {
                return 'anulować'
            }
            case RESERVATION_STATUS_CONFIRMED: {
                return 'potwierdzić'
            }
            case REJECTED: {
                return 'odrzucić'
            }
        }
    }

    return <AppModal
        {...props}
        title={getTitle()}
        cancelText='Nie'
        submitText='Tak'
        type='confirmation'
        submitAction={save}
        setModalOpened={setModalOpened}>
        <p>Czy na pewno chcesz <b>{getText()}</b> rezerwację <b>#{reservation?.number}</b>?</p>
    </AppModal>
}

export default ReservationStatusChangeModal;

