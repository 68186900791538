import React from "react";
import {Link} from "react-router-dom";
import {BiTrash} from 'react-icons/bi'
import {useLocation} from 'react-router-dom'
import Button from "../../buttons/Button";

let ObjectPage = ({
                      children,
                      pages,
                      onRemove = null,
                      name = '',
                      removeText = 'app.remove',
                      removeIcon = BiTrash
                  }) => {

    const location = useLocation();

    let getMenuItemClasses = (path) => {
        let classes = 'menu-item';
        if (location.pathname === path) {
            classes += ' active'
        }
        return classes;
    }

    return <div className={'objectpage ' + (name + '-page')}>
        <div className='menu'>
            <div className='menu-items-container'>
                <div className='menu-items'>
                    {
                        pages.filter(page => !page.disabled).map((page) => <div className={getMenuItemClasses(page.path)} key={page.path}>
                            <Link to={page.path}>{page.name}</Link>
                        </div>)
                    }
                </div>
            </div>
            {onRemove && <div className='remove-object'>
                <Button text={removeText} variant='danger' Icon={removeIcon} onClick={() => onRemove()}/>
            </div>}
        </div>
        <div>
            {children}
        </div>
    </div>

}

export default ObjectPage;

