import React, {useState} from 'react'
import TextInput from "../../core/form/TextInput"
import {ReactComponent as PinIcon} from '../../assets/icons/pin.svg'
import ReduxSelect from "../../core/form/select/ReduxSelect";
import FormWrapper from "../../core/form/FormWrapper";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import api from "../../core/api";
import {appError} from "../../actions/app";
import InputAdornment from "@mui/material/InputAdornment";
import {useController} from "react-hook-form";
import * as QueryString from "query-string";
import {composeUtils, uniqueArray} from "../../core/utils/appUtil";
import {useLocation} from 'react-router-dom'
import SelectInput from "../../core/form/select/SelectInput";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getValidationMessage} from "../../core/utils/validationUtil";
import {useDispatch, useSelector} from 'react-redux'
import {loadFacilityTypes} from "../../actions/facilityType";

let FacilityTypeField = ({form}) => {

    let dispatch = useDispatch()

    let {
        all: facilityTypeIds,
        byId: facilityTypes
    } = useSelector(state => state.facilityType)

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name: 'facilityType',
        control: form.control,
        defaultValue: facilityTypeIds.map(id => facilityTypes[id]).filter(facilityType => facilityType.name === 'Hala sportowa')[0]?.id,
        rules: {
            required: {
                value: true,
                message: 'Pole jest wymagane'
            }
        }
    })

    React.useEffect(() => {
        dispatch(loadFacilityTypes())
    }, [])

    React.useEffect(() => {
        if(!value) {
            onChange(facilityTypeIds.map(id => facilityTypes[id]).filter(facilityType => facilityType.name === 'Hala sportowa')[0]?.id)
        }
    }, [
        JSON.stringify(facilityTypeIds)
    ])

    return <FormWrapper extraClasses='place-select' errorMessage={error?.message}>
        <select className='form-control' placeholder='Wybierz' value={value} onChange={e => onChange(e.target.value)}>
            <option>Wybierz typ obiektu</option>
            {
                facilityTypeIds.map(t => facilityTypes[t])
                    .map(facilityType => <option value={facilityType.id} key={facilityType.id}>{facilityType.name}</option>)
            }
        </select>
    </FormWrapper>

}

export default composeUtils(FacilityTypeField)