import { combineReducers } from 'redux';
import auth from './reducers/auth';
import app from './reducers/app';
import facility from './reducers/facility';
import reservationModal from './reducers/reservationModal';
import service from './reducers/service';
import business from './reducers/business';
import profile from './reducers/profile';
import reservation from './reducers/reservation';
import search from './reducers/search';
import facilityType from './reducers/facilityType';
import notification from './reducers/notification';
import sport from './reducers/sport';
import amenity from './reducers/amenity';
import resource from './reducers/resource';
import customField from './reducers/customField';
import customFieldOption from './reducers/customFieldOption';
import rentalcalendar from './reducers/rentalcalendar';
import rentalModal from './reducers/rentalModal';

export default combineReducers({
    app,
    auth,
    facility,
    reservationModal,
    service,
    business,
    profile,
    reservation,
    search,
    facilityType,
    notification,
    rentalcalendar,
    rentalModal,
    sport, amenity, resource, customField, customFieldOption
});