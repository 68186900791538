import React from "react";
import {
    FINISHED,
    REJECTED,
    RESERVATION_STATUS_CANCELLED,
    RESERVATION_STATUS_CONFIRMED,
    WAITING_FOR_BUSINESS_APPROVAL
} from "../../core/constants/reservationStatuses";
import {
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";
import {useController} from "react-hook-form";
import {BsFillCalendarCheckFill} from 'react-icons/bs'
import {RiArrowDownSLine} from 'react-icons/ri'
import {useSelector} from "react-redux";
import ReservationStatusChangeModal from "../modal/ReservationStatusChangeModal";
import moment from 'moment';

const ReservationStatusField = ({reservationId}) => {
    let reservation = useSelector(state => state.reservation.byId[reservationId])
    let name = 'status'
    let [status, setStatus] = React.useState(null);
    let [modalOpened, setModalOpened] = React.useState(false);

    let changeStatus = status => {
        setStatus(status)
        setModalOpened(true)
    }

    if(moment(reservation.endtime).diff(moment()) < 0) {
        return <UncontrolledDropdown className='status mb-1'>
            <DropdownToggle nav className='finished'>
                <div className='bg-icon'>
                    <BsFillCalendarCheckFill/>
                </div>
                <div className='text'>Zakończona</div>
                <RiArrowDownSLine className='icon-collapse'/>
            </DropdownToggle>
        </UncontrolledDropdown>;
    }

    return <>
        {modalOpened && <ReservationStatusChangeModal
            status={status}
            reservationId={reservationId}
            modalOpened={modalOpened}
            setModalOpened={setModalOpened}
        />}
        <UncontrolledDropdown className='status mb-1'>
            {reservation.confirmed && <DropdownToggle nav className={'confirmed'}>
                <div className='bg-icon'>
                    <BsFillCalendarCheckFill/>
                </div>
                <div className='text'>{reservation.confirmed ? 'Potwierdzona' : 'Oczekuje na potwierdzenie'}</div>
                <RiArrowDownSLine className='icon-collapse'/>
            </DropdownToggle>}
            {!reservation.confirmed && <DropdownToggle nav className={WAITING_FOR_BUSINESS_APPROVAL.toLowerCase()}>
                <div className='bg-icon'>
                    <BsFillCalendarCheckFill/>
                </div>
                <div className='text'>Oczekuje na potwierdzenie</div>
                <RiArrowDownSLine className='icon-collapse'/>
            </DropdownToggle>}
            <DropdownMenu className="dropdown-menu-lg py-0">
            <div className='menu-item'>
                    <DropdownItem className={RESERVATION_STATUS_CANCELLED.toLowerCase()}
                                  onClick={() => changeStatus(RESERVATION_STATUS_CANCELLED)}>Anuluj</DropdownItem>
            </div>
        </DropdownMenu>
        </UncontrolledDropdown>
    </>
}

export default ReservationStatusField;

