import React, {useState} from 'react'
import TextInput from "../../core/form/TextInput"
import {ReactComponent as PinIcon} from '../../assets/icons/pin.svg'
import ReduxSelect from "../../core/form/select/ReduxSelect";
import FormWrapper from "../../core/form/FormWrapper";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import api from "../../core/api";
import {appError} from "../../actions/app";
import InputAdornment from "@mui/material/InputAdornment";
import {useController} from "react-hook-form";
import * as QueryString from "query-string";
import {composeUtils} from "../../core/utils/appUtil";
import {useLocation} from 'react-router-dom'

let PlaceField = ({form}) => {
    let location =useLocation()
    let place = QueryString.parse(location.search)?.place
    let [text, setText] = useState(place)
    let [options, setOptions] = useState([{
        text: place
    }])
    React.useEffect(() => {
        setOptions(() => [{
            text: place
        }])
    }, [
        place
    ])

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name: 'place',
        control: form.control,
        defaultValue: place
    })

    let searchLocations = (text) => {
        setText(text)
        api.place.find(text)
            .then((r) => {
                setOptions(() => r.data)
            })
            .catch((err) => appError(dispatch, err))
    }

    let inputProps = {
        startAdornment: (
            <InputAdornment position="start">
                <PinIcon/>
            </InputAdornment>
        )
    }

    return <FormWrapper extraClasses='place-select'>
        <Autocomplete
            value={{text: text ?? ''}}
            options={options}
            // disablePortal
            // filterOptions={createFilterOptions({
            //     stringify: (data) => `${data['name']}`
            // })}
            onChange={(event, newValue) => {
                onChange(newValue?.text)
            }}
            onInputChange={(event, newInputValue) => searchLocations(newInputValue)}
            renderInput={(params) => <TextField {...params} label="Wskaż lokalizację"/>}
            getOptionLabel={option => option.text}
            renderOption={(props, option) => {
                return <div {...props} className='select-option'>
                    {option.text}
                </div>
                return option.text
            }}
        />
    </FormWrapper>

}

export default composeUtils(PlaceField)