import {
    BACK_TO_RESERVATION_MODAL,
    CLOSE_RESERVATION_MODAL, OPEN_RESERVATION_CONFIRMATION, OPEN_RENTAL_RESERVATION_SUMMARY,
    OPEN_RESERVATION_MODAL,
    OPEN_RESERVATION_SUMMARY, RESERVATION_MODAL_FETCH_TERMS_REQUEST, RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    SELECT_DAY,
    SELECT_MONTH, SELECT_TERM
} from "../constants/actiontypes/reservation";

var initialState = {
    loadingTerms: false,
    confirmation: false,
    modal_opened: false,
    months: [],
    days: [],
    current_month: null,
    current_day: null,
    terms: [],
    current_facility: null,
    current_resources: [],
    current_service: null,
    current_term: null,
    summary: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_RESERVATION_MODAL: {
            return {
                ...state,
                modal_opened: true,
                months: action.payload.months,
                days: action.payload.days,
                current_month: action.payload.months[0],
                current_day: action.payload.days[0].format('Y-MM-DD'),
                terms: action.payload.terms ?? [],
                current_service: action.payload.serviceId,
                current_term: null,
                summary: false,
                loadingTerms: false,
            }
        }
        case RESERVATION_MODAL_FETCH_TERMS_SUCCESS: {
            return {
                ...state,
                loadingTerms: false,
                terms: action.payload.terms ?? []
            }
        }
        case CLOSE_RESERVATION_MODAL: {
            return {
                ...state,
                modal_opened: false,
                summary: false,
                confirmation: false
            }
        }
        case SELECT_MONTH: {
            return {
                ...state,
                current_month: action.payload.month,
                days: action.payload.days,
                current_term: null
            }
        }
        case SELECT_DAY: {
            return {
                ...state,
                current_day: action.payload.date,
                current_term: null,
                loadingTerms: false
            }
        }
        case SELECT_TERM: {
            return {
                ...state,
                current_term: action.payload
            }
        }
        case OPEN_RESERVATION_SUMMARY: {
            return {
                ...state,
                summary: true
            }
        }
        case OPEN_RENTAL_RESERVATION_SUMMARY: {
            return {
                ...state,
                summary: true,
                modal_opened: true,
                current_day: action.payload.day,
                current_month: action.payload.day,
                current_resources: action.payload.resource_id ? [action.payload.resource_id] : [],
                current_facility: action.payload.facility_id,
                current_service: action.payload.service_id,
                current_term: {
                    from: {
                        hour: action.payload.from.split(':')[0],
                        minute: action.payload.from.split(':')[1]
                    },
                    to: {
                        hour: action.payload.to.split(':')[0],
                        minute: action.payload.to.split(':')[1]
                    },
                    price: action.payload.price
                }
            }
        }
        case BACK_TO_RESERVATION_MODAL: {
            return {
                ...state,
                summary: true
            }
        }
        case OPEN_RESERVATION_CONFIRMATION: {
            return {
                ...state,
                confirmation: true,
                summary: false
            }
        }
        case RESERVATION_MODAL_FETCH_TERMS_REQUEST: {
            return {
                ...state,
                loadingTerms: true
            }
        }
        default:
            return state;
    }
};