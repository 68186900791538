import {getValidationMessage} from "./validationUtil";

export function getErrorMessage(errors, fieldName) {
    let error = null;
    if(fieldName.includes('[')) {
        let fields = fieldName.split('[').map(field => field.replace(']',''));
        if(errors[fields[0]]) {
            if(errors[fields[0]][fields[1]]){
                return errors[fields[0]][fields[1]].message;
            }
        }
    }
    if (errors[fieldName]) {
        error = errors[fieldName].message;
    }
    return error;
}

export function getDefaultValue(defaultValues, fieldName) {
    if(fieldName.includes('[')) {
        let fields = fieldName.split('[').map(field => field.replace(']',''));
        if(defaultValues[fields[0]]) {
            if(defaultValues[fields[0]][fields[1]]){
                return defaultValues[fields[0]][fields[1]];
            }
        }
    }
    if (defaultValues[fieldName]) {
        return defaultValues[fieldName];
    }
    return null;
}

export function setErrors(result, setError, intl) {
    let hasErrors = false;
    if (result && result.errors) {
        for (let property in result.errors) {
            if (result.errors.hasOwnProperty(property)) {
                hasErrors = true;
                setError(property, {message: getValidationMessage(intl, property, result.errors[property])});
            }
        }
    }
    return hasErrors;
}