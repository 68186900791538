import {
    LOAD_FACILITIES_REQUEST,
    LOAD_FACILITIES_SUCCESS,
    LOAD_FACILITY_REQUEST,
    LOAD_FACILITY_SUCCESS
} from "../constants/actiontypes/facility";

var initialState = {
    byId: [],
    loading: false
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.facility) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.facility)
        }
        console.log('state', state)
    }
    switch (action.type) {
        case LOAD_FACILITIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LOAD_FACILITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                proposed: action.payload.facilities
            }
        case LOAD_FACILITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LOAD_FACILITY_SUCCESS:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};