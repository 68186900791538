import React from 'react';
import {connect, useSelector} from "react-redux";
import * as actions from "../../actions/login";
import {ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {validateEmail, validatePassword} from "../../utils/validationUtil";
import {createAction} from "../../utils/reduxUtil";
import {CLOSE_LOGIN_MODAL, OPEN_REGISTER_MODAL, OPEN_RESET_PASSWORD_FORM} from "../../constants/actiontypes/login";
import CardForm from '../../core/form/CardForm'
import Card from "../../core/card/Card";
import CardBody from "../../core/card/CardBody";
import CardFooter from "../../core/card/CardFooter";
import SubmitFormButton from "../../core/buttons/SubmitFormButton";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import TextInput from '../../core/form/TextInput'
import {useDispatch} from 'react-redux'
import Button from '../../core/buttons/Button'
import {useIntl} from 'react-intl'
import {trans} from '../../core/utils/translationUtil'
import loginImage from '../../assets/img/login.svg'
import backImage from '../../assets/img/arrow-left.svg'

let ResetPasswordSubmitForm = ({form}) => {

    let dispatch = useDispatch()

    let intl = useIntl()

    let isMobile = useSelector(state => state.app.mobile)

    let openRegisterForm = () => dispatch(createAction(OPEN_REGISTER_MODAL))

    let openResetPasswordForm = () => dispatch(createAction(OPEN_RESET_PASSWORD_FORM))

    let closeModal = () => dispatch(createAction(CLOSE_LOGIN_MODAL))

    return <>
        {isMobile && <div className='d-flex justify-content-center'>
            <img src={loginImage} className='w-100'/>
        </div>}
        <TextInput {...form} name='email' label='app.email' required={true}/>
        <TextInput {...form} type='password' required={true} name='password' label='app.password'/>
        <TextInput {...form} type='password' required={true} name='password_confirmation' label='Powtórz hasło'/>
    </>
}

export default ResetPasswordSubmitForm