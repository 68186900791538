import React, {useState} from 'react'
import * as BaseLoader from "../Loader";
import {useSelector} from 'react-redux'

let RentalDisabledInfo = () => {

    let {facilityId, loading} = useSelector(state => state.rentalcalendar)

    let facility = useSelector(state => state.facility.byId[facilityId])

    if (loading || facility?.rental?.partial || facility?.rental?.entire || !facilityId) {
        return <></>
    }

    return <p style={{textAlign: 'center'}}>Obiekt nie jest dostępny do rezerwacji online</p>
}

export default RentalDisabledInfo