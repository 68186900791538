import {FIXED, FREE, INDIVIDUAL, RANGE, VARIABLE} from "../constants/priceTypes";
import {trans} from "./translationUtil";

export function getPriceText(service, intl) {
    let price = service?.price
    if (!price?.type) {
        return '-'
    }
    if (price.type === FIXED) {
        return price.value + " PLN"
    }
    if ([FREE, INDIVIDUAL].includes(price.type)) {
        return trans(intl, 'form.price.type_' + price.type.toLowerCase())
    }
    if (price.type === RANGE) {
        return price.from + ' - ' + price.to + " PLN"
    }
    if (price.type === VARIABLE) {
        if(service.price_value) {
            return service.price_value + " PLN"
        }
        return price.from + ' - ' + price.to + " PLN"
    }
    return '-';
}

export function getServicePriceText(price) {
    if (!price.min) {
        return 'BRAK'
    }
    if (price.min === price.max) {
        return price.min + ' PLN'
    }
    return price.min + ' - ' + price.max + ' PLN'
}