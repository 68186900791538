import React, {useState} from 'react'
import Loader from "../Loader";
import {useSelector} from 'react-redux'

let RentalLoader = ({facilityId}) => {

    let {loading} = useSelector(state => state.rentalcalendar)

    if (!loading) {
        return <></>;
    }

    return <div style={{
        position: "absolute",
        zIndex: 999,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)'
    }}>
        <div className=''>
            <Loader/>
        </div>
    </div>
}

export default RentalLoader