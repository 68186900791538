import React from 'react'
import {Route, Routes, BrowserRouter} from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import BasicLayout from "./components/layout/BasicLayout";
import BusinessLayout from './components/layout/BusinessLayout';
import SearchResultPage from "./pages/SearchResultPage";
import FacilityPage from "./pages/FacilityPage";
import Home from "./pages/Home";
import AccountSettingsPage from "./pages/AccountSettingsPage";
import FavouritePage from "./pages/FavouritePage";
import ReservationsPage from "./pages/ReservationsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import BusinessDetailsPage from './pages/BusinessDetailsPage'
import BusinessTrainingsPage from './pages/BusinessTrainingsPage'
import BusinessRentalPage from './pages/BusinessRentalPage'
import Page404 from './pages/Page404'
import BusinessContextLayout from "./components/layout/BusinessContextLayout";

let BusinessRoutes = () => <BrowserRouter>
    <Routes>
        <Route exact path='/' element={<BusinessLayout requireLogin={false} businessContext={true}><BusinessDetailsPage/></BusinessLayout>}/>
        <Route exact path='/konto' element={<BasicLayout requireLogin={true} businessContext={true}><AccountSettingsPage/></BasicLayout>}/>
        <Route exact path='/treningi' element={<BusinessLayout requireLogin={false} businessContext={true}><BusinessTrainingsPage/></BusinessLayout>}/>
        <Route exact path='/wynajem' element={<BusinessLayout requireLogin={false} businessContext={true}><BusinessRentalPage/></BusinessLayout>}/>
        <Route exact path='/obiekt/:slug' element={<BasicLayout requireLogin={false} businessContext={true}><FacilityPage/></BasicLayout>}/>
        <Route exact path='/rezerwacje' element={<BasicLayout businessContext={true}><ReservationsPage/></BasicLayout>}/>
    </Routes>
</BrowserRouter>

export default BusinessRoutes