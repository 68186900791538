import {
    makeRequest,
    createPutRequest,
    createRequest,
    createPostRequest,
    createDeleteRequest,
    getApi,
    prepareRequest
} from "../utils/apiUtil";

export const FACILITY_RESOURCE_GET_ALL = createRequest('facility/:facilityId/resource');
export const FACILITY_UPDATE_SERVICES = createPostRequest('facility/:id/service');
export const FACILITY_UPDATE_PARTNERS = createPostRequest('facility/:id/partner');
export const FACILITY_GET_PARTNERS = createRequest('facility/:id/partner');
export const FACILITY_GET_SERVICES = createRequest('facility/:id/service');
export const FACILITY_UPDATE_RESOURCES = createPostRequest('facility/:id/resource');
export const SERVICE_UPDATE_RESOURCES = createPostRequest('service/:id/resource');
export const SERVICE_UPDATE_FACILITIES = createPostRequest('service/:id/facility');
export const PARTNER_UPDATE_FACILITIES = createPostRequest('partner/:id/facility');
export const RESOURCE_UPDATE_FACILITIES = createPostRequest('resource/:id/facility');
export const SERVICE_UPDATE_PARTNERS = createPostRequest('service/:id/partner');
export const TERM_VERIFICATION_ADD = createPostRequest('termVerification');
export const FACILITIES_UPDATE = createPutRequest('facility/:param1');
export const FACILITY_OPENING_HOURS_UPDATE = createPostRequest('facility/:id/openingHour');
export const PARTNER_OPENING_HOURS_UPDATE = createPostRequest('partner/:id/openingHour');
export const RESOURCE_OPENING_HOURS_UPDATE = createPostRequest('resource/:id/openingHour');
export const FACILITIES_IMAGE_ADD = createPostRequest('facility/:facilityId/image');
export const FACILITIES_IMAGE_DELETE = createDeleteRequest('facility/:facilityId/image/:imageId');
export const FACILITIES_IMAGES_UPDATE = createPutRequest('facility/:facilityId/image');
export const OPENING_HOUR_UPDATE = createPutRequest('openingHour/:param1');
export const OPENING_HOUR_DELETE = createDeleteRequest('openingHour/:param1');
export const OPENING_HOUR_ADD = createPostRequest('openingHour');
export const PARTNERS_UPDATE = createPutRequest('partner/:param1');
export const PARTNERS_DELETE = createDeleteRequest('partner/:id');
export const PARTNERS_SHOW = createRequest('partner/:param1');
export const SERVICES_GET_ALL = createRequest('service');
export const SERVICES_ADD = createPostRequest('service');
export const SERVICES_SHOW = createRequest('service/:id');
export const SERVICES_UPDATE = createPutRequest('service/:id');
export const SERVICE_PRICE_UPDATE = createPostRequest('service/:id/price');
export const SERVICES_DELETE = createDeleteRequest('service/:id');
export const SERVICE_PARTNERS = createRequest('service/:id/partner');
export const RESOURCES_GET_ALL = createRequest('resource');
export const RESOURCES_ADD = createPostRequest('resource');
export const RESOURCES_SHOW = createRequest('resource/:id');
export const RESOURCES_UPDATE = createPutRequest('resource/:id');
export const RESOURCE_TYPES_SHOW = createRequest('resourceType/:id');
export const RESOURCE_TYPE_ADD = createPostRequest('resourceType');
export const RESOURCE_TYPE_UPDATE = createPutRequest('resourceType/:id');
export const RESOURCE_TYPES_GET_ALL = createRequest('resourceType');
export const FACILITY_TYPES_GET_ALL = createRequest('facilityType');
export const FACILITY_TYPES_SHOW = createRequest('facilityType/:id');
export const CLIENT_GET_ALL = createRequest('client');
export const CLIENT_SHOW = createRequest('client/:id');
export const CLIENT_ADD = createPostRequest('client');
export const CLIENT_UPDATE = createPutRequest('client/:id');
export const RESERVATION_SHOW = createRequest('reservation/:id');
export const RESERVATION_GET_ALL = createRequest('reservation');
export const RESERVATION_ADD = createPostRequest('reservation');
export const RESERVATION_UPDATE = createPutRequest('reservation/:param1');
export const RESERVATION_DELETE = createDeleteRequest('reservation/:param1');
export const BUSINESS_GET = createRequest('business');
export const BUSINESS_UPDATE = createPutRequest('business/:id');
export const LANGUAGE_GET_ALL = createRequest('language');
export const ADDRESSES_UPDATE = createPutRequest('address/:param1');
export const PARTNER_GET_ALL = createRequest('partner');
export const PARTNER_SHOW = createRequest('partner/:id');
export const PARTNER_ADD = createPostRequest('partner');

export function getTerms(date, facilityId, serviceId) {
    return makeRequest(createRequest('term'), {date: date, facility_id: facilityId, service_id: serviceId})
}
export function getFacilities(filters = {}, includes = [], extraParams = {}) {

    return makeRequest(createRequest('facility'), prepareRequest(filters, includes, extraParams));
}
export function getFacilityBySlug(slug) {
    return makeRequest(createRequest('facility'), {
        filter: {slug: slug},
        include: ['openingHours', 'images', 'type', 'resources', 'services', 'address', 'partners'].join(',')
    });
}
export function getFacility(id, includes = []) {
    return makeRequest(createRequest('facility/:id'), {
        include: includes.join(',')
    }, {id});
}
export function addFacility(formData) {
    formData.include = ['type', 'address', 'images']
    return makeRequest(createPostRequest('facility'), formData);
}
export function deleteFacility(id) {
    return makeRequest(createDeleteRequest('facility/:id'), {}, {id});
}
export function updateOpeningHour(formData) {
    return makeRequest(OPENING_HOUR_UPDATE, formData, {'param1': formData.id});
}
export function deleteOpeningHour(formData) {
    return makeRequest(OPENING_HOUR_DELETE, formData, {'param1': formData.id});
}
export function addOpeningHour(formData) {
    return makeRequest(OPENING_HOUR_ADD, formData);
}
export function updateFacility(formData, uuid, includes = []) {
    formData.include = includes;
    return makeRequest(FACILITIES_UPDATE, formData, {'param1': uuid});
}
export function updateFacilityServices(uuid, services) {
    return makeRequest(FACILITY_UPDATE_SERVICES, services, {'id': uuid});
}
export function updateFacilityResources(uuid, services) {
    return makeRequest(FACILITY_UPDATE_RESOURCES, services, {'id': uuid});
}
export function updateServiceResources(uuid, services) {
    return makeRequest(SERVICE_UPDATE_RESOURCES, services, {'id': uuid});
}
export function updateServiceFacilities(uuid, services) {
    return makeRequest(SERVICE_UPDATE_FACILITIES, services, {'id': uuid});
}
export function updateServicePartners(uuid, services) {
    return makeRequest(SERVICE_UPDATE_PARTNERS, services, {'id': uuid});
}
export function updatePartnerFacilities(uuid, services) {
    return makeRequest(PARTNER_UPDATE_FACILITIES, services, {'id': uuid});
}
export function updateResourceFacilities(uuid, services) {
    return makeRequest(RESOURCE_UPDATE_FACILITIES, services, {'id': uuid});
}
export function updatePartnerServices(uuid, services) {
    return makeRequest(createPostRequest('partner/:id/service'), services, {'id': uuid});
}
export function updateResourceServices(uuid, services) {
    return makeRequest(createPostRequest('resource/:id/service'), services, {'id': uuid});
}
export function getFacilityPartners(facilityId, filters = {}, includes = []) {
    if (includes.length > 0) {
        filters.includes = includes.join(',')
    }
    return makeRequest(FACILITY_GET_PARTNERS, filters, {'id': facilityId});
}
export function getFacilityServices(facilityId, includes = []) {
    return makeRequest(FACILITY_GET_SERVICES, {include: includes.join(',')}, {'id': facilityId});
}
export function updateFacilityPartners(uuid, partners) {
    return makeRequest(FACILITY_UPDATE_PARTNERS, partners, {'id': uuid});
}
export function updateFacilityOpeningHours(openingHours, uuid, includes = []) {
    return makeRequest(FACILITY_OPENING_HOURS_UPDATE, openingHours, {'id': uuid});
}
export function updatePartnerOpeningHours(openingHours, uuid, includes = []) {
    return makeRequest(PARTNER_OPENING_HOURS_UPDATE, openingHours, {'id': uuid});
}
export function updateResourceOpeningHours(openingHours, uuid, includes = []) {
    return makeRequest(RESOURCE_OPENING_HOURS_UPDATE, openingHours, {'id': uuid});
}
export function updateAddress(formData, uuid) {
    return makeRequest(ADDRESSES_UPDATE, formData, {'param1': uuid});
}
export function addFacilityImage(formData, facilityId) {
    formData.facility_id = facilityId;
    return makeRequest(FACILITIES_IMAGE_ADD, formData, {
        facilityId
    });
}
export function updateFacilityImages(facilityId, images) {
    return makeRequest(FACILITIES_IMAGES_UPDATE, images, {
        facilityId
    });
}
export function deleteFacilityImage(facilityId, imageId) {
    return makeRequest(FACILITIES_IMAGE_DELETE, {}, {facilityId, imageId});
}
export function updatePartner(formData, partnerUuid) {
    return makeRequest(PARTNERS_UPDATE, formData, {'param1': partnerUuid});
}
export function deletePartner(partnerId) {
    return makeRequest(PARTNERS_DELETE, {}, {'id': partnerId})
}
export function termVerificationCreate(formData) {
    return makeRequest(TERM_VERIFICATION_ADD, formData);
}
export function refreshToken() {
    return makeRequest(createPostRequest('refreshToken'));
}
export function getPartnerDetails(uuid) {
    return makeRequest(PARTNERS_SHOW, {include: 'avatar'}, {'param1': uuid});
}
export function getServices(includes = [], filters = {}, extraParams = {}) {
    return makeRequest(SERVICES_GET_ALL, prepareRequest(filters, includes, extraParams));
}
export function getServicePartners(serviceId, params) {
    return makeRequest(SERVICE_PARTNERS, params, {id: serviceId});
}
export function getServicesByFacilityType(facilityTypeId) {
    return makeRequest(SERVICES_GET_ALL, {
        filter: {
            facility_type_id: facilityTypeId
        }
    });
}
export function getService(id, includes = []) {
    return makeRequest(SERVICES_SHOW, {include: includes.join(',')}, {id: id});
}
export function createService(formData) {
    return makeRequest(SERVICES_ADD, formData);
}
export function deleteService(id) {
    return makeRequest(SERVICES_DELETE, null, {id: id});
}
export function updateService(id, formData) {
    return makeRequest(SERVICES_UPDATE, formData, {id: id});
}
export function updateServicePrice(serviceId, formData) {
    return makeRequest(SERVICE_PRICE_UPDATE, formData, {id: serviceId});
}
export function getAllResources(filters, includes = [], extraParams = {}) {
    return makeRequest(RESOURCES_GET_ALL, prepareRequest(filters, includes, extraParams));
}
export function getResource(id, includes = []) {
    return makeRequest(RESOURCES_SHOW, {include: includes.join(',')}, {id: id});
}
export function createResource(formData) {
    return makeRequest(RESOURCES_ADD, formData);
}
export function updateResource(id, formData) {
    return makeRequest(RESOURCES_UPDATE, formData, {id: id});
}
export function getResourceType(id) {
    return makeRequest(RESOURCE_TYPES_SHOW, {}, {id: id});
}
export function createResourceType(formData) {
    return makeRequest(RESOURCE_TYPE_ADD, formData);
}
export function updateResourceType(id, formData) {
    return makeRequest(RESOURCE_TYPE_UPDATE, formData, {id: id});
}
export function getAllResourceTypes(filters = {}, includes = [], extraParams = {}) {
    return makeRequest(RESOURCE_TYPES_GET_ALL, prepareRequest(filters, includes, extraParams));
}
export function getFacilityTypes(filters = {}, includes = [], extraParams = {}) {
    return makeRequest(FACILITY_TYPES_GET_ALL, prepareRequest(filters, includes, extraParams));
}
export function getFacilityType(id, includes = []) {
    return makeRequest(FACILITY_TYPES_SHOW, {include: includes.join(',')}, {id: id});
}
export function getAllClients(filters = {}, includes = [], extraParams = {}) {
     return makeRequest(CLIENT_GET_ALL, prepareRequest(filters, includes, extraParams));
}
export function getBusiness() {
    return makeRequest(BUSINESS_GET);
}
export function updateBusiness(formData, id) {
    return makeRequest(BUSINESS_UPDATE, formData, {id});
}
export function createClient(formData) {
    return makeRequest(CLIENT_ADD, formData);
}
export function updateClient(formData, clientId) {
    return makeRequest(CLIENT_UPDATE, formData, {id: clientId});
}
export function getAllLanguages() {
    return makeRequest(LANGUAGE_GET_ALL, {});
}
export function searchReservations(params) {
    return makeRequest(RESERVATION_GET_ALL, params);
}
export function createReservation(formData) {
    return makeRequest(RESERVATION_ADD, formData);
}
export function getReservation(reservationId, includes = []) {
    return makeRequest(RESERVATION_SHOW, {include: includes.join(',')}, {'id': reservationId});
}
export function updateReservation(reservationId, formData) {
    return makeRequest(RESERVATION_UPDATE, formData, {param1: reservationId});
}
export function deleteReservation(reservationId) {
    return makeRequest(RESERVATION_DELETE, {}, {param1: reservationId});
}
export function getClient(clientId) {
    return makeRequest(CLIENT_SHOW, {}, {'id': clientId});
}
export function getPartners(filters = {}, includes = [], extraParams = {}) {
    return makeRequest(PARTNER_GET_ALL, prepareRequest(filters, includes, extraParams));
}
export function getPartner(partnerId, includes = []) {
    return makeRequest(PARTNER_SHOW, {include: includes.join(',')}, {'id': partnerId});
}
export function createPartner(formData) {
    return makeRequest(PARTNER_ADD, formData);
}
export function getRoles() {
    return makeRequest(createRequest('role'));
}
export function getPermissions() {
    return makeRequest(createRequest('permission'));
}
export function updatePermissions(formData) {
    return makeRequest(createPostRequest('permission'), formData);
}
export function createAccountInvitation(partnerId) {
    return makeRequest(createPostRequest('accountInvitation'), {partner_id: partnerId})
}
export function updateAccount(formData) {
    return makeRequest(createPostRequest('account'), formData);
}
export function deleteInavailabilityHour(id) {
    return makeRequest(createDeleteRequest('inavailabilityHour/:id'), {}, {id});
}
export function createInavailabilityHour(formData) {
    return makeRequest(createPostRequest('inavailabilityHour'), formData);
}
export function updateInavailabilityHour(id, formData) {
    return makeRequest(createPutRequest('inavailabilityHour/:id'), formData, {id});
}
export function deletePayment(id) {
    return makeRequest(createDeleteRequest('payment/:id'), {}, {id});
}
export function createPayment(formData) {
    return makeRequest(createPostRequest('payment'), formData);
}
export function updatePayment(id, formData) {
    return makeRequest(createPutRequest('payment/:id'), formData, {id});
}
export function getFacilityResources(facilityId, serviceId, startTime, endTime) {
    return makeRequest(FACILITY_RESOURCE_GET_ALL,
        {service_id: serviceId, include: 'type', start_time: startTime, end_time: endTime},
        {facilityId: facilityId}
    );
}
export function getServiceTerms(serviceId, queryParams) {
    return makeRequest(createRequest('service/' + serviceId + '/term'), prepareRequest({}, [], queryParams));
}
export function updateProfile(submitData) {
    return getApi().post('account', submitData);
}

import * as auth from './auth'
import * as facility from './facility'
import * as client from './client'
import * as business from './business'
import * as place from './place'
import * as paymentMethod from './paymentMethod'
import * as subscription from './subscription'
import * as resourceType from './resourceType'
import * as resource from './resource'
import * as notification from './notification'
import * as reservation from './reservation'
import * as partner from './partner'
import * as pricingPlan from './pricingPlan'

export default {
    auth,
    client,
    facility,
    business,
    partner,
    place,
    paymentMethod,
    pricingPlan,
    resourceType,
    resource,
    subscription,
    notification,
    reservation
}