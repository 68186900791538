import axios from 'axios';

export function getApi() {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'secure-key' : 'QiTEqWsViaInQTvbjBYg'
        }
    });
}

export default getApi;
