import React, {Component, useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import {createAction} from "../../utils/reduxUtil";
import {
    CLOSE_RESERVATION_MODAL,
    OPEN_RESERVATION_MODAL,
    OPEN_RESERVATION_SUMMARY,
    RESERVATION_MODAL_FETCH_TERMS_REQUEST,
    RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    RESERVATION_MODAL_SELECT_PRICE_LIST,
    SELECT_DAY,
    SELECT_TERM
} from "../../constants/actiontypes/reservation";
import {makeReservation, selectMonth} from "../../actions/reservation";
import Slider from "react-slick";
import {addLeadingZeros} from "../../utils/stringUtil";
import {DAY_SHORTCUTS, MONTH_NAMES} from "../../constants/dateContants";
import moment from 'moment';
import {dateFormat} from "../../utils/dateUtil";
import {getPriceText} from "../../utils/appUtil";
import {injectIntl, useIntl} from "react-intl";
import Button from '../../core/buttons/Button'
import calendarImage from '../../assets/img/calendar.svg'
import {useNavigate} from "react-router-dom";
import ModalForm from "../../core/modal/ModalForm";
import {trans} from "../../core/utils/translationUtil";
import backImage from "../../assets/img/arrow-left.svg";
import reservationConfirmationImage from '../../assets/img/reservation_confirmation.svg'
import Loader from "../Loader";
import FacilityImage from '../facility/FacilityImage'
import SelectInput from "../../core/form/select/SelectInput";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getTerms} from "../../api/app";
import Dialog from "@mui/material/Dialog";
import {MdClose} from 'react-icons/md'
import {
    getPrzelewy24PaymentMethods
} from '../../api/app'
import {
    RENTAL_MODAL_CLOSE,
    RENTAL_MODAL_SET_CYCLE,
    RENTAL_MODAL_SET_PAYMENT_METHOD,
    RENTAL_MODAL_CASH,
    RENTAL_MODAL_PRZELEWY24, RENTAL_MODAL_SET_PRZELEWY24_PAYMENT_METHOD_ID
}
    from "../../reducers/rentalModal";

let Przelewy24 = () => {

    let dispatch = useDispatch()

    let navigate = useNavigate()

    let intl = useIntl()

    let {
        opened: isOpen,
        paymentMethod,
        przelewy24MethodId
    } = useSelector(state => state.rentalModal)

    let closeModal = () => dispatch(createAction(RENTAL_MODAL_CLOSE))

    let {price, serviceId, date, time_from: timeFrom, time_to: timeTo} = useSelector(state => state.rentalModal)

    let service = useSelector(state => state.service.byId[state.rentalModal.serviceId])

    let [loading, setLoading] = useState(true)

    let [paymentMethods, setPaymentMethods] = useState([])


    React.useEffect(() => {
        getPrzelewy24PaymentMethods(price).then(response => {
            setPaymentMethods(response.data.data)
        })
    }, [])

    let goToReservations = () => {
        dispatch(createAction(RENTAL_MODAL_CLOSE))
        navigate('/rezerwacje')
    }

    return <div className='przelewy24'>
        {
            paymentMethods.slice(0, 8).map(paymentMethod => <div className={'paymentmethod ' + (paymentMethod.id === przelewy24MethodId ? 'active' : '')}
            onClick={() => dispatch(createAction(RENTAL_MODAL_SET_PRZELEWY24_PAYMENT_METHOD_ID, paymentMethod.id))}
            >
                <span>{paymentMethod.name}</span>
                <img src={paymentMethod.imgUrl}/>
            </div>)
        }
    </div>
}

export default Przelewy24
