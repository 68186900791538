export const RENTAL_CALENDAR_RESET = 'RENTAL_CALENDAR_RESET'
export const RENTAL_CALENDAR_INIT = 'RENTAL_CALENDAR_INIT'
export const RENTAL_CALENDAR_CHANGE_SERVICE = 'RENTAL_CALENDAR_CHANGE_SERVICE'
export const RENTAL_CALENDAR_CHANGE_DURATION = 'RENTAL_CALENDAR_CHANGE_DURATION'
export const RENTAL_CALENDAR_LOAD_TERMS = 'RENTAL_CALENDAR_LOAD_TERMS'
export const RENTAL_CALENDAR_HOVER_SLOT = 'RENTAL_CALENDAR_HOVER_SLOT'
export const RENTAL_CALENDAR_RESET_HOVER = 'RENTAL_CALENDAR_RESET_HOVER'
export const RENTAL_CALENDAR_SET_DATE = 'RENTAL_CALENDAR_SET_DATE'
export const RENTAL_CALENDAR_TERMS_LOADED = 'RENTAL_CALENDAR_TERMS_LOADED'
export const RENTAL_CALENDAR_SET_FACILITY_PART = 'RENTAL_CALENDAR_SET_FACILITY_PART'

var initialState = {
    loading: false,
    businessId: null,
    facilityId: null,
    serviceId: null,
    start: null,
    end: null,
    duration: null,
    hoveredSlot: null,
    terms: [],
    facilityPartId: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RENTAL_CALENDAR_RESET: {
            return {
                ...state,
                loading: action.payload.service_id ? true : false,
                businessId: action.payload.businessId,
                facilityId: action.payload.facilityId,
                serviceId: action.payload.serviceId,
                start: action.payload.start,
                end: action.payload.end,
                duration: action.payload.duration
            }
        }
        case RENTAL_CALENDAR_CHANGE_SERVICE: {
            return {
                ...state,
                serviceId: action.payload.serviceId
            }
        }
        case RENTAL_CALENDAR_CHANGE_DURATION: {
            return {
                ...state,
                duration: action.payload.duration
            }
        }
        case RENTAL_CALENDAR_LOAD_TERMS: {
            return {
                ...state,
                loading: true
            }
        }
        case RENTAL_CALENDAR_TERMS_LOADED: {
            return {
                ...state,
                terms: action.payload.terms,
                loading: false
            }
        }
        case RENTAL_CALENDAR_HOVER_SLOT: {
            return {
                ...state,
                hoveredSlot: action.payload.slot
            }
        }
        case RENTAL_CALENDAR_RESET_HOVER: {
            return {
                ...state,
                hoveredSlot: null
            }
        }
        case RENTAL_CALENDAR_SET_DATE: {
            return {
                ...state,
                start: action.payload.start,
                end: action.payload.end
            }
        }
        case RENTAL_CALENDAR_SET_FACILITY_PART: {
            return {
                ...state,
                facilityPartId: action.payload ?? null,
            }
        }
        default:
            return state;
    }
};