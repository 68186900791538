import React, {useState} from 'react'
import Loader from "../Loader";
import {createAction} from '../../utils/reduxUtil'
import {useSelector, useDispatch} from 'react-redux'
import Button from "../../core/buttons/Button";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import {RENTAL_CALENDAR_CHANGE_SERVICE} from "../../reducers/rentalcalendar";
import FacilityPartSelect from "./FacilityPartSelect";

let FacilityPart = () => {

    let dispatch = useDispatch()

    let {facilityId, loading, serviceId} = useSelector(state => state.rentalcalendar)

    let service = useSelector(state => state.service.byId[serviceId])

    if(!service?.required_facility && !service?.required_facility_parts) {
        return null
    }

    return null

    let facility = useSelector(state => state.facility.byId[facilityId])

    if(!facility?.rental.partial || !facility?.rental.entire) {
        return <></>
    }

    return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 0}} className='navigation'>
        <div>
            <Button text='Cały obiekt' extraClasses={serviceId === facility.rental.entire_service_id ? 'secondary' : 'primary'}
                    onClick={() => dispatch(createAction(RENTAL_CALENDAR_CHANGE_SERVICE, {serviceId: facility.rental.entire_service_id}))}/>
        </div>
        <div>
            <Button text='Część'
                    extraClasses={serviceId === facility.rental.partial_service_id ? 'secondary' : 'primary'}
                    onClick={() => dispatch(createAction(RENTAL_CALENDAR_CHANGE_SERVICE, {serviceId: facility.rental.partial_service_id}))}/>
        </div>
        <div>
            <FacilityPartSelect/>
        </div>
    </div>
}

export default FacilityPart