import React, {Component} from "react";
import TermList from './TermList'
import {Row, Col} from 'reactstrap'
import {getPriceText, getServicePriceText} from "../../utils/appUtil";
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'

let ServiceInfo = () => {

    let intl = useIntl()

    let service = useSelector(state => state.service.byId[state.reservationModal.current_service])

    return <div className='service-description py-4 px-3 d-flex justify-content-between w-100 flex-row'>
        <div className='d-flex justify-content-center align-items-center'>
            <span>{service.name}</span>
        </div>
        <div className='d-flex flex-column align-items-end justify-content-end'>
            <div className='d-flex justify-content-end fw-bold'>
                <span>{getServicePriceText(service.price)}</span>
            </div>
            <div className='d-flex justify-content-end'>
                <span>{service?.duration} min</span>
            </div>
        </div>
    </div>
}

export default ServiceInfo