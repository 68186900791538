export default {
    remove: 'Usuń',
    signin: 'Zaloguj się',
    signup: 'Zarejestruj się',
    signin_from_google: 'Zaloguj się za pomocą Google',
    signin_from_fb: 'Zaloguj się za pomocą Facebook',
    add_new_facility: 'Dodaj nowy obiekt',
    facility_update: 'Zaktualizuj obiekt',
    facility_remove: 'Usuń obiekt',
    facility_remove_confirmation: 'Czy na pewno chcesz usunąć obiekt?',
    facilities: 'Obiekty'
}