import React from 'react'
import * as MuiTextField from '@mui/material/TextField'
import {trans} from "../utils/translationUtil"
import {useIntl} from 'react-intl'

let TextField = ({
                    type = 'text',
                    name,
                    value,
                    label,
                    multiline,
                    onChangeText,
                    onChange,
                    invalid,
                    minRows
                }) => {

    let intl = useIntl()

    return <MuiTextField
        type={type}
        autoComplete='off'
        name={name}
        label={trans(intl, label)}
        variant="outlined"
        value={value}
        multiline={multiline}
        onChange={(e) => {
            onChangeText(e.target.value);
            onChange(e);
        }}
        error={invalid}
        minRows={minRows}
    />
}

export default TextField