import React from 'react';
import {withRouter, useMatch, useParams, Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getFacilityDetails} from "../actions/facility";
import {Row, Col, Button} from 'reactstrap';
import FacilityImageSlider from "../components/facility/FacilityImageSlider";
import {injectIntl} from "react-intl";
import moment from 'moment';
import {initReservationModal} from "../actions/reservation";
import {DAYS} from "../constants/days";
import {trans} from "../utils/translationUtil";
import {addLeadingZeros} from "../utils/stringUtil";
import {CgWebsite} from 'react-icons/cg';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {BiPhone} from 'react-icons/bi';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconMe from '../assets/img/marker-me.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import Loader from "../components/Loader";
import ReservationModal from "../components/reservation/ReservationModal";
import {getDistance} from 'geolib';
import {getPriceText} from "../utils/appUtil";
import ReserveButton from "../components/buttons/ReserveButton";
import {useIntl} from 'react-intl'
import MarkerMe from '../components/map/markers/MarkerMe'
import Distance from './../components/facility/Distance'
import Map from '../components/facility/Map'
import ServicesTable from '../components/facility/ServiceTable'
import OpeningHours from '../components/facility/OpeningHours'
import {composeUtils} from "../core/utils/appUtil";
import Socials from '../components/facility/Socials'
import Address from '../components/facility/Address'
import OtherFacilities from '../components/facility/OtherFacilities'
import RentalForm from '../components/RentalForm'
import BasicLayout from "../components/layout/BasicLayout";
import Page404 from "./Page404";
import RentalCalendar from '../components/rentalcalendar/RentalCalendar'
import {getBusinessBySubdomain} from "../selectors";
import {createAction} from "../utils/reduxUtil";
import {BUSINESS_FETCH_REQUEST, BUSINESS_FETCH_SUCCESS} from "../reducers/business";
import {getBusinessesBySubdomain} from "../api/app";
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import {RENTAL_CALENDAR_RESET} from "../reducers/rentalcalendar";

let FacilityPage = ({intl, dispatch}) => {

    let {business_id: businessContextId} = useSelector(state => state.app)

    let params = useParams();

    let facilities = useSelector(state => state.facility.byId)

    let businesses = useSelector(state => state.business.byId)

    let services = useSelector(state => state.service.byId)

    let allResources = useSelector(state => state.resource.byId)

    let facility = useSelector(state => Object.keys(state.facility.byId)
        .map(facilityId => state.facility.byId[facilityId])
        .filter(f => f.slug === params.slug)[0]
    )
    let allCustomFieldOptions = useSelector(state => state.customFieldOption.byId)

    let allCustomFields = useSelector(state => state.customField.byId)

    let customFieldOptions = facility?.custom_field_values?.map(id => allCustomFieldOptions[id])

    let customFieldIds = [...new Set(customFieldOptions?.map(cfo => cfo.custom_field_id))]

    let loading = useSelector(state => state.facility.loading)

    let business = null

    if (!businessContextId) {
        business = useSelector(state => getBusinessBySubdomain(params.business, state))
    } else {
        business = useSelector(state => state.business.byId[businessContextId])
    }

    React.useEffect(() => {
        dispatch(createAction(BUSINESS_FETCH_REQUEST))
        dispatch(getFacilityDetails(businessContextId ? business.subdomain : params.business, params.slug))
    }, [])

    React.useEffect(() => {
        if(facility?.id) {
            dispatch(createAction(RENTAL_CALENDAR_RESET, {
                facilityId: facility?.id,
                serviceId: facility.rental.entire ? facility.rental.entire_service_id : facility.rental.partial_service_id,
                date: moment().format('YYYY-MM-DD'),
                start: moment().format('YYYY-MM-DD'),
                end: moment().add(6, 'days').format('YYYY-MM-DD'),
                duration: facility.rental.times[0]
            }))
        }
    }, [facility?.id])

    if (loading && !facility?.id) {
        return <div className=''>
            <Loader/>
        </div>
    }

    if (!loading && !facility?.id) {
        return <Page404/>
    }

    return <div className='facility-page'>
        <Row>
            <div className='header'>
                <Link to={(businessContextId ? '' : '/biznes/' + params.business) + '/wynajem'}
                      className='btn'>{'<'} Wszystkie obiekty</Link>
            </div>
        </Row>
        <Row>
            <h3>{facility.name}</h3>
        </Row>
        <Row>
            <Col lg={6}>
                <FacilityImageSlider images={facility?.images ?? []}/>
            </Col>
            <Col lg={6}>
                <div dangerouslySetInnerHTML={{__html: facility.description}}/>
                {customFieldIds.length > 0 && <div className='customfields'>
                    <div className='heading'>
                        Specyfikacja
                    </div>
                    <table>
                        <tbody>
                        {customFieldIds.map(id => {
                            return <tr key={id}>
                                <td>
                                    {allCustomFields[id]?.name}
                                </td>
                                <td>
                                    {customFieldOptions.filter(option => option.custom_field_id === id).map(option => option.value).join(',')}
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>}
            </Col>
        </Row>
        <Row className='terms'>
            <RentalCalendar facilityId={facility?.id}/>
            {/*<RentalForm facilityId={facility?.id}/>*/}
            <ReservationModal/>
        </Row>
    </div>
}

export default composeUtils(FacilityPage)