export default {
    notification_email: 'E-mail',
    notification_web: 'Push',
    app_name: 'bookendy',
    email: 'Adres e-mail',
    password: 'Hasło',
    phone: 'Numer telefonu',
    fullname: 'Imię i nazwisko',
    login: 'Zaloguj się',
    loging: 'Logowanie',
    logout: 'Wyloguj się',
    loggedout: 'Wylogowano',
    registering: 'Rejestracja',
    register: 'Zarejestruj się!',
    session_expired: 'Session expired',
    dont_have_account: 'Nie masz konta?',
    loggedin: 'Zalogowano!',
    registered: 'Zarejestrowano',
    forpartners: 'Dla partnerów',
    closest_facilities_1: 'Obiekty ',
    closest_facilities_2: 'najbliżej Ciebie',
    featured_facilities_1: 'Polecane ',
    featured_facilities_2: 'obiekty',
    reservations: 'Moje rezerwacje',
    favourites: 'Ulubione',
    go_to_search: 'Przejdź do wyszukiwarki',
    try_partners: 'Wypróbuj aplikację dla partnerów',
    partner_info_1: 'Posiadasz własny obiekt sportowy lub rozrywkowy i chciałbyś wypróbować nasz system ',
    partner_info_2: 'bez żadnych zobowiązań?',
    footer: {
        all_rights_reserved: 'Wszystkie prawa zastrzeżone'
    },
    header: {
        search_text_field: 'Nazwa, rodzaj obiektu lub usługa',
        place_text_field: 'Wskaż lokalizację',
        date_text_field: 'Wybierz termin'
    },
    benefits: {
        card1: 'Znajdź najlepsze obiekty sportowe i rozrywkowe w Twojej okolicy bez konieczności przeglądania setek stron. U nas masz wszystko w jednym miejscu.',
        card2: 'Rezerwuj usługi bez wychodzenia z domu, o dowolnej porze.',
        card3: 'Zarządzaj swoimi rezerwacjami i otrzymuj przypomnienia o zbliżających się terminach usług.',
        card4: 'Porównaj oferty różnych obiektów i zapoznaj się z opiniami innych użytkowników. usług.'
    }
}