import React from 'react';
import {connect, useSelector} from "react-redux";
import * as actions from "../../actions/login";
import {ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {validateEmail, validatePassword} from "../../utils/validationUtil";
import {createAction} from "../../utils/reduxUtil";
import {CLOSE_LOGIN_MODAL, OPEN_REGISTER_MODAL} from "../../constants/actiontypes/login";
import CardForm from '../../core/form/CardForm'
import Card from "../../core/card/Card";
import CardBody from "../../core/card/CardBody";
import CardFooter from "../../core/card/CardFooter";
import SubmitFormButton from "../../core/buttons/SubmitFormButton";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import TextInput from '../../core/form/TextInput'
import {useDispatch} from 'react-redux'
import Button from '../../core/buttons/Button'
import registerImage from "../../assets/img/registration.svg";
import backImage from "../../assets/img/arrow-left.svg";

let RegisterForm = ({form}) => {

    let dispatch = useDispatch()

    let isMobile = useSelector(state => state.app.mobile)

    let closeModal = () => dispatch(createAction(CLOSE_LOGIN_MODAL))

    return <>
        {isMobile && <div className='d-flex justify-content-center'>
            <img src={registerImage} className='w-75'/>
        </div>}
        <TextInput {...form} name='fullname' required={true} label='app.fullname'/>
        <TextInput {...form} name='email' required={true} label='app.email'/>
        <TextInput {...form} mask='+48 999 999 999' name='phone' required={true} label='app.phone'/>
        <TextInput {...form} type='password' required={true} name='password' label='app.password'/>
        <div className='terms-text'>Rejestrując się, akceptujesz
            <a href='/pdf/Bookendy_Regulamin_dla_Klienta.docx.pdf' target='_blank'
               className='extra' style={{textDecoration: "none"}}> regulamin</a> i
            <a href='/pdf/Polityka_prywatności_Bookendy.pdf' target='_blank'
               className='extra' style={{textDecoration: "none"}}> politykę prywatności</a>
        </div>
    </>

}

export default RegisterForm