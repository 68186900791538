import React, { useRef, useState, useEffect } from 'react'
import MapMarker from './MapMarker'
import {useSelector} from 'react-redux'
import L from "leaflet";
import getIcon from "../../../constants/facilityIcons";
import {Link} from "react-router-dom";
import FacilityImage from "../../facility/FacilityImage";

let FacilityMarker = ({facilityId}) => {

    let facility = useSelector(state => state.facility.byId[facilityId])

    let business = useSelector(state => state.business.byId[facility?.business])

    let facilityType = useSelector(state => state.facilityType.byId[facility.type])

    let currentFacility = useSelector(state => state.search.selected_facility)

    return <MapMarker lat={facility.address.lat}
                      lon={facility.address.lon}
                      icon={getIcon(facilityType)}
                      openPopup={facilityId === currentFacility}>
        <div className='d-flex flex-column justify-content-center'>
            <Link to={'/' + business?.subdomain + '/' + facility.slug}>{facility.name}</Link>
            <div>
                <FacilityImage facilityId={facilityId} width='100px'/>
            </div>
        </div>
    </MapMarker>
}

export default FacilityMarker