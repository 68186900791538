import validation from './validation.js'
import form from './form.js'
import app from './app.js'
import language from './language.js'
import permission from './permission.js'
import reservationstatus from './reservationstatus.js'
import resourcetypes from './resourcetypes.js'
import {importModule} from "../../utils/langUtil";

export default {
    ...importModule('app', {}, app),
    ...importModule('validation', {}, validation),
    ...importModule('form', {}, form),
    ...importModule('language', {}, language),
    ...importModule('permission', {}, permission),
    ...importModule('reservationstatus', {}, reservationstatus),
    ...importModule('resourcetypes', {}, resourcetypes),
}