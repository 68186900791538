import Echo from 'laravel-echo';
import {createAction} from "../utils/reduxUtil";
import {ADD_NOTIFICATION} from "../constants/actiontypes/notification";

let handleNotification = (notification, dispatch) => {
    switch (notification.notification_type) {
        case 'reservation_confirmed': {
            dispatch(createAction(ADD_NOTIFICATION, notification))
        }
    }
};

export function initWebsockets(clientId, dispatch) {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    const options = {
        broadcaster: 'pusher',
        key: '2855a685ad23ec8a4d32',
        cluster: 'eu',
        encrypted: true,
        authEndpoint: process.env.REACT_APP_API_URL + '/broadcasting/auth',
        auth: {
            headers: {
                'secure-key': 'QiTEqWsViaInQTvbjBYg',
                Authorization: 'Bearer ' + token,
            },
        },
    };
    let echoInstance = new Echo(options);
    echoInstance.private('clients.' + clientId).listen('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        (notification) => handleNotification(notification, dispatch));
}

export default initWebsockets;
