import React, {Component} from "react";
import TermList from './TermList'
import calendarImage from "../../assets/img/calendar.svg";
import {addLeadingZeros} from "../../utils/stringUtil";
import {createAction} from "../../utils/reduxUtil";
import {SELECT_TERM} from "../../constants/actiontypes/reservation";
import {useDispatch, useSelector} from "react-redux";

let TermListPart = ({terms}) => {

    let currentTerm = useSelector(state => state.reservationModal.current_term)

    let dispatch = useDispatch()

    let selectTerm = (term) => dispatch(createAction(SELECT_TERM, term))

    let isCurrentTerm = (term) => {
        if (!currentTerm) {
            return false;
        }
        return currentTerm.from.hour === term.from.hour &&
            currentTerm.from.minute === term.from.minute &&
            currentTerm.date === term.date;
    }

    return terms.map((term) =>
        <div className={'slot ' + (isCurrentTerm(term) ? 'selected' : '')}
             onClick={() => selectTerm(term)}>
            <span>{term.from.hour}:{addLeadingZeros(term.from.minute, 2)} <br/>{term.price} PLN</span>
        </div>
    )
}

export default TermListPart