export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const OPEN_REGISTER_MODAL = 'OPEN_REGISTER_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const LOAD_AGREEMENTS = 'LOAD_AGREEMENTS';
export const OPEN_RESET_PASSWORD_FORM = 'OPEN_RESET_PASSWORD_FORM';
export const OPEN_RESET_PASSWORD_SUBMIT_FORM = 'OPEN_RESET_PASSWORD_SUBMIT_FORM';
export const SET_STEP = 'SET_STEP';

export const LOGGED_IN = 'LOGGED_IN';
export const REGISTERED = 'REGISTERED';
export const LOGOUT = 'LOGOUT';
