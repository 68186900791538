import React from 'react';
import {withRouter, useMatch, useParams, Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getFacilityDetails} from "../actions/facility";
import {Row, Col, Button} from 'reactstrap';
import FacilityImageSlider from "../components/facility/FacilityImageSlider";
import {injectIntl} from "react-intl";
import moment from 'moment';
import {initReservationModal} from "../actions/reservation";
import {DAYS} from "../constants/days";
import {trans} from "../utils/translationUtil";
import {addLeadingZeros} from "../utils/stringUtil";
import {CgWebsite} from 'react-icons/cg';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {BiPhone} from 'react-icons/bi';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconMe from '../assets/img/marker-me.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import Loader from "../components/Loader";
import ReservationModal from "../components/reservation/ReservationModal";
import {getDistance} from 'geolib';
import {getPriceText} from "../utils/appUtil";
import ReserveButton from "../components/buttons/ReserveButton";
import {useIntl} from 'react-intl'
import MarkerMe from '../components/map/markers/MarkerMe'
import Distance from './../components/facility/Distance'
import Map from '../components/facility/Map'
import ServicesTable from '../components/facility/ServiceTable'
import OpeningHours from '../components/facility/OpeningHours'
import {composeUtils} from "../core/utils/appUtil";
import Socials from '../components/facility/Socials'
import Address from '../components/facility/Address'
import OtherFacilities from '../components/facility/OtherFacilities'
import {getBusinessBySubdomain} from "../selectors";
import {getMainImage} from "../utils/facilityUtil";

let BusinessRentalPage = ({intl, dispatch}) => {

    let {business_id: businessContextId} = useSelector(state => state.app)
    let business = null
    let params = useParams();

    if (!businessContextId) {
        business = useSelector(state => getBusinessBySubdomain(params.business, state))
    } else {
        business = useSelector(state => state.business.byId[businessContextId])
    }

    let allFacilities = useSelector(state => state.facility.byId)

    let facilityy = useSelector(state => state.facility)
    console.log('d', facilityy, allFacilities)

    let getFacilityUrl = facilityId => (businessContextId ? '' : '/biznes/' + business.subdomain) + '/obiekt/' + facilityId

    return <div className='page rental-page'>
        <div className='heading'>
            <span>
                Wybierz obiekt
            </span>
        </div>
        <div className='row'>
            {
                !business.facilities?.length && <div style={{textAlign: 'center'}}>BRAK OBIEKTÓW DO WYNAJMU</div>
            }
            {
                business.facilities?.map(facilityId => {
                    let facility = allFacilities[facilityId]
                    return <div className='col col-lg-4'>
                        <div>
                            {facility.name}
                            <div className='mb-2 image-container'>
                                <img src={getMainImage(facilityId)}
                                     style={{maxWidth: '100%'}}
                                />
                            </div>
                            <Link to={getFacilityUrl(facility.slug)} className='btn'>Przejdź do obiektu</Link>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
}

export default composeUtils(BusinessRentalPage)