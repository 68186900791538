import React from "react";
import {useIntl} from 'react-intl';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useController} from "react-hook-form";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {getValidationMessage} from "../../utils/validationUtil";
import FormControl from '@mui/material/FormControl';
import FormWrapper from "../FormWrapper";
import {trans} from "../../utils/translationUtil";
import InputLabel from '@mui/material/InputLabel';
import Checkbox from "../Checkbox";
import {uniqueArray} from "../../utils/appUtil";

const SelectInput = ({
                         control,
                         name,
                         label = null,
                         rawLabel = null,
                         validation = {},
                         options,
                         optionLabel = 'label',
                         optionValue = 'value',
                         required = true,
                         information = null,
                         loading = false,
                         autocomplete = false,
                         renderLabel = null,
                         disabled = false,
                         extraClassName = '',
                         isMulti = false,
                         min = 0,
                         height = null,
                         filterOptions = null,
                         warningMessage = null,
                         renderValue = null,
                        wrapForm = true,
                         extraOnChange = () => {
                         }
                     }) => {

    let intl = useIntl();

    if (!filterOptions) {
        filterOptions = createFilterOptions({
            stringify: (data) => `${data[optionLabel]}`
        });
    }

    extraClassName = [extraClassName, name.replace('_id', '') + '-select'].join(' ')

    if (height) {
        extraClassName += ' height-' + height
    }

    let defaultValue = control._defaultValues[name]

    if (isMulti && !defaultValue) {
        defaultValue = []
    }

    if (required) {
        validation.required = {
            value: true, message: getValidationMessage(intl, name, 'required')
        }
    }

    if (min) {
        validation.validate = val => {
            return val.length >= min;
        }
    } else {
        validation.validate = null
    }

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name,
        control,
        rules: validation,
        defaultValue: defaultValue
    })

    if (error?.type === 'validate') {
        if(min > 0) {
            error.message = 'Wymagane conajmniej ' + min + ' wybranych elementów';
        } else {
            error.message = null
        }
    }

    let errorMessage = error?.message;

    if (loading) {
        return <div className='form-skeleton'>
            <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                <Skeleton height={48}/>
            </SkeletonTheme>
        </div>;
    }

    function getAutcompleteDefaultValue() {
        if (!isMulti) {
            return options.filter(option => option[optionValue] === value).shift() ?? null
        }
        return options.filter(option => value.includes(option[optionValue]))
    }

    options = options.filter(option => !!option)


    let renderSelect = () => {
        if (!autocomplete) {
            return <FormControl fullWidth>
                <InputLabel>{rawLabel ? rawLabel : trans(intl, label)}</InputLabel>
                <Select
                    key={'select_' + name}
                    multiple={isMulti}
                    className={extraClassName}
                    error={!!errorMessage}
                    disabled={disabled}
                    value={value ?? ''}
                    renderValue={(value) => {
                        if(renderValue) {
                            return renderValue(value)
                        }
                        if (isMulti) {
                            value = uniqueArray(value)
                            return value.map((val, key) => {
                                val = options.filter(option => option[optionValue] === val).shift()
                                return <span
                                    key={name + '_' + val}>
                                    {val[optionLabel]}{key === value.length - 1 ? '' : ', '}
                                </span>
                            })
                        }
                        value = options.filter(option => option[optionValue] === value).shift()
                        return <span>{value ? value[optionLabel] : ''}</span>
                    }}
                    onChange={(event, newValue) => {
                        let val = newValue.props.value
                        if (isMulti) {
                            onChange(event.target.value)
                            extraOnChange(event.target.value)
                        } else {
                            onChange(val)
                            extraOnChange(val)
                        }
                    }}>
                    {!options.length && <div>
                        <span style={{display: 'flex', justifyContent: 'center', backgroundColor: 'white'}}>Brak</span>
                    </div>}
                    {
                        options.map(option => <MenuItem key={name + '_' + option[optionValue]}
                                                        value={option[optionValue]}
                                                        className={value === option[optionValue] && !isMulti ? ' current ' : ''}>
                            <div className={'detailed-select-option d-flex ' + name + (isMulti ? ' multi ' : ' ')}>
                                {isMulti ? <Checkbox value={value.includes(option[optionValue])}/> : null}
                                {renderLabel ? renderLabel(option) : option[optionLabel]}
                            </div>
                        </MenuItem>)
                    }
                </Select>
            </FormControl>
        }
        options = options.filter(o => !!o)
        return <Autocomplete
            multiple={isMulti}
            className={extraClassName}
            disabled={disabled}
            // disablePortal
            options={options}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option[optionLabel]}
            renderOption={(props, option) => {
                return <div {...props} className='select-option test'>
                    {renderLabel ? renderLabel(option) : option[optionLabel]}
                </div>
            }}
            defaultValue={options.filter(option => option[optionValue] === value)?.shift()}
            value={getAutcompleteDefaultValue()}
            onChange={(event, newValue) => {
                let val
                if (isMulti) {
                    val = newValue.map(val => val[optionValue])
                } else {
                    val = newValue ? newValue[optionValue] : null
                }
                onChange(val);
                if (onChange) {
                    onChange(val);
                }
            }}
            renderInput={(params) => {
                return <TextField {...params}
                                  label={rawLabel ? rawLabel : trans(intl, label)} error={!!errorMessage}
                                  variant="outlined"
                />
            }}
        />
    }

    if(!wrapForm) {
        return renderSelect();
    }

    return <FormWrapper information={information} warningMessage={warningMessage} errorMessage={errorMessage}
                        extraClasses={extraClassName}>
        {
            renderSelect()
        }
    </FormWrapper>

}

export default SelectInput;

