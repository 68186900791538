import React, {Component} from "react";
import TermList from './TermList'
import {Row, Col} from 'reactstrap'

let Terms = () => {
    return <div className='hours mt-3'>
        <Row>
            <Col>
                <TermList minHour={0} maxHour={12} title='Godziny poranne'/>
            </Col>
            <Col>
                <TermList minHour={12} maxHour={18} title='Godziny popołudniowe'/>
            </Col>
            <Col>
                <TermList minHour={18} maxHour={24} title='Godziny wieczorne'/>
            </Col>
        </Row>
    </div>
}

export default Terms