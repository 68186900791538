import React from 'react';
import BusinessSlider from "../components/BusinessSlider";
import {connect} from "react-redux";
import {loadClosestFacilities, loadProposedFacilities} from "../actions/facility";
import {trans} from "../utils/translationUtil";
import {injectIntl} from "react-intl";
import Loader from "../components/Loader";
import {loadFacilityTypes} from "../actions/facilityType";
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import Button from '../core/buttons/Button'
import inviteObject from '../assets/img/invite_object.svg'
import partnerImage from '../assets/img/partner.svg'
import card1 from '../assets/img/card1.svg'
import {BiChevronRight} from 'react-icons/bi'
import Cards from '../components/home/Cards'
import MobileSearch from '../components/MobileSearch'
import {getBusinesses} from "../actions/business";

let Home = () => {

    let intl = useIntl()

    let {allIds, loading} = useSelector(state => state.business)

    let dispatch = useDispatch()

    let isMobile = useSelector(state => state.app.mobile)

    let goToSearch = () => {
        window.scrollTo(0, 0)
    }

    let openPartnersPage = () => {
        window.location.href = process.env.REACT_APP_PARTNERS_PANEL_URL
    }

    React.useEffect(() => {
        dispatch(getBusinesses())
    }, [])

    return <div>
        {isMobile && <MobileSearch/>}
        <div className='facility-slider-container d-flex flex-column justify-content-center align-items-center'>
            <div className='title fw-bold text-center py-4'>{trans(intl, 'app.closest_facilities_1')}
                <span className='inverse'>{trans(intl, 'app.closest_facilities_2')}</span>
            </div>
            <BusinessSlider items={allIds} loading={loading}/>
        </div>
        <div className='facility-slider-container d-flex flex-column justify-content-center align-items-center'>
            <div className='title fw-bold text-center py-4'>{trans(intl, 'app.featured_facilities_1')}
                <span className='inverse'>{trans(intl, 'app.featured_facilities_2')}</span>
            </div>
            <BusinessSlider items={allIds} loading={loading}/>
        </div>
        <div className='my-5 pt-5'>
            <div className={'d-flex w-100 justify-content-around align-items-center invite-object mt-5 position-relative px-5 ' + (isMobile ? 'flex-column' : '')}>
                <div className='px-5'>
                    <img src={inviteObject} style={{marginTop: '-30px'}}/>
                </div>
                <div className={'d-flex flex-grow-1 ' + (isMobile ? 'flex-column py-5' : 'flex-row')}>
                    <div className='text flex-grow-1'>
                        <div>Nie możesz znaleźć obiektu ze <span className='fw-bold'>swojej okolicy?</span></div>
                        <div>Zaproś go do rejestracji w <span className='fw-bold'>naszym systemie!</span></div>
                    </div>
                    <div className='d-flex align-items-center py-3'>
                        <Button text='Zaproś obiekt' rightIcon Icon={BiChevronRight}/>
                    </div>
                </div>
            </div>
        </div>
        <div className='px-5'>
            <div className='py-5 text-center fw-bold' style={{fontSize: '36px', color: 'black'}}>
                Zarezerwuj usługę - <span className='inverse' style={{color: '#0067FF'}}>szybko i wygodnie</span>!
            </div>
            <Cards/>
            <div className='py-5 d-flex justify-content-center align-items-center'>
                <Button text={trans(intl, 'app.go_to_search')} rightIcon Icon={BiChevronRight}
                        onClick={goToSearch}/>
            </div>
            <div className='mt-5 px-3 mb-5'>
                <div className='partner-info p-5 d-flex position-relative'>
                    <div className='d-flex flex-column w-50 info'>
                        <div className='mb-5'>{trans(intl, 'app.partner_info_1')}<span
                            className='fw-bold'>{trans(intl, 'app.partner_info_2')}</span></div>
                        <div>
                            <Button text={trans(intl, 'app.try_partners')} rightIcon Icon={BiChevronRight}
                                    onClick={openPartnersPage}/>
                        </div>
                    </div>
                    <img src={partnerImage} className='position-absolute'/>
                </div>
            </div>
        </div>
    </div>
}

export default Home