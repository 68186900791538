import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import {useIntl} from 'react-intl';
import SubmitFormButton from "../buttons/SubmitFormButton";
import {setErrors} from "../utils/formUtil";
import {toastSuccess} from "../utils/appUtil";
import handleError from "../utils/errorUtil";
import {useDispatch} from 'react-redux';
import Card from "../../core/card/Card";
import CardBody from "../../core/card/CardBody";
import CardFooter from "../../core/card/CardFooter";
import Button from "../../core/buttons/Button";

let CardForm = function ({
                             prepareData = data => data,
                             renderForm = null,
                             title,
                             data = {},
                             cancelText = 'Wyczyść',
                             saveText = 'Zapisano',
                             onSubmit = null,
                             getPromise = null,
                             onChange = null,
                             wasChanged = false,
                             clearFormOnSuccess = false
                         }) {

    let [isDuringSubmit, setIsDuringSubmit] = useState(false)

    let intl = useIntl()

    let dispatch = useDispatch()

    let form = useForm({defaultValues: data})

    let watch = form.watch

    React.useEffect(() => {
        const subscription = form.watch((data, event) => {
            if(onChange) {
                onChange(data, event)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch])

    let submit = async (formData) => {
        if (prepareData) {
            formData = prepareData(formData)
        }
        setIsDuringSubmit(true)
        let result = await getPromise(data.id, formData)
            .then(response => {
                onSubmit(response.data)
                toastSuccess(saveText)
                if (clearFormOnSuccess) {
                    form.reset()
                }
            })
            .catch(error => handleError(error, dispatch))
        setErrors(result, form.setError, intl)
        setIsDuringSubmit(false)
    }

    React.useEffect(() => {
        form.reset(data)
    }, [data, form.reset])

    return <Form onSubmit={form.handleSubmit(submit)}>
        <Card title={title}>
            <CardBody>
                {renderForm(form)}
            </CardBody>
            {(form.formState.isDirty || wasChanged) && <CardFooter>
                <Button variant='secondary' text={cancelText} onClick={() => form.reset(data)}/>
                <SubmitFormButton loading={isDuringSubmit} text='Zapisz'/>
            </CardFooter>}
        </Card>
    </Form>

}
export default CardForm