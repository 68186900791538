import React from 'react';
import {connect} from "react-redux";
import {loadClosestFacilities, loadProposedFacilities} from "../actions/facility";
import {trans} from "../utils/translationUtil";
import {injectIntl} from "react-intl";
import Loader from "../components/Loader";
import {loadFacilityTypes} from "../actions/facilityType";
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import Button from '../core/buttons/Button'
import inviteObject from '../assets/img/invite_object.svg'
import partnerImage from '../assets/img/partner.svg'
import card1 from '../assets/img/card1.svg'
import {BiChevronRight} from 'react-icons/bi'
import Cards from '../components/home/Cards'
import MobileSearch from '../components/MobileSearch'
import Home from './Home'
import {OPEN_RESET_PASSWORD_SUBMIT_FORM} from '../constants/actiontypes/login'
import {createAction} from "../core/utils/appUtil";

let ResetPasswordPage = () => {

    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(createAction(OPEN_RESET_PASSWORD_SUBMIT_FORM))
    }, [])

    return <Home/>
}

export default ResetPasswordPage