import {createAction} from "../utils/reduxUtil";
import {
    SEARCH_REQUEST,
    SEARCH_SUCCESS
} from "../constants/actiontypes/facility";
import * as api from "../api/app";
import {normalize} from "normalizr";
import schemas from "../reducers/schemas";
import handleError from "../utils/errorUtil";
import {getBusinessesBySubdomain, getFacilityBySlug} from "../api/app";
import {BUSINESS_LIST_REQUEST, BUSINESS_LIST_SUCCESS} from "../reducers/business";

export function getBusinesses() {
    return function (dispatch) {
        dispatch(createAction(BUSINESS_LIST_REQUEST, {}));
        api.searchBusinesses()
            .then(response => {
                let normalizedData = normalize(response.data, [schemas.businessSchema]);
                dispatch(createAction(BUSINESS_LIST_SUCCESS, normalizedData));
            }).catch(err => handleError(err, dispatch));
    }
}