import {
    APP_LOADED,
    APP_LOADING, CHANGE_LANGUAGE_REQUEST,
    LOAD_LANGUAGES_SUCCESS,
    SET_LANGUAGE,
    SET_LOGGED_USER,
    APP_SET_BUSINESS
} from "../constants/actiontypes/app";
import api from '../core/api';
import {toastError} from "../utils/toastUtil";
import {createAction} from "../utils/reduxUtil";
import {getApiToken} from "../utils/apiUtil";
import initWebsockets from "../api/websocket";
import handleError from "../utils/errorUtil";
import {SPORTS_FETCH_REQUEST, SPORTS_FETCH_SUCCESS} from "../reducers/sport";
import {getBusinessesBySubdomain, getFacilityTypes, getSports} from "../api/app";
import schemas from "../reducers/schemas";
import {LOAD_FACILITY_TYPES_SUCCESS} from "../constants/actiontypes/facilityType";
import {normalize} from 'normalizr';

export function setLoggedUser(loggedUser) {
    return {type: SET_LOGGED_USER, payload: loggedUser}
}

export function initApp() {
    return function (dispatch) {
        dispatch(createAction(APP_LOADING))
        let subdomain = window.location.hostname.replace(process.env.REACT_APP_BASE_DOMAIN, '').replace('.', '');
        if (subdomain) {
            getBusinessesBySubdomain(subdomain)
                .then(response => {
                    let normalizedData = normalize(response.data, [schemas.businessSchema]);
                    if(normalizedData.result.length > 0) {
                        dispatch(createAction(APP_SET_BUSINESS, normalizedData))
                    }
                    loadUser(dispatch)
                })
                .catch(e => {
                    loadUser(dispatch)
                })
        } else {
            loadUser(dispatch)
        }
    }
}

function loadUser(dispatch) {
    api.auth.getAccount()
        .then((r) => {
            api.client.getById(r.data.id)
                .then(r => initAppSuccess(dispatch, r))
                .catch(err => appError(dispatch, err))
        })
        .catch((err) => appError(dispatch, err))
}

function initAppSuccess(dispatch, response) {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    if (token === null || token === '') {
        dispatch(createAction(APP_LOADED));
    } else {
        let loggedUser = response.data.data;
        dispatch(createAction(SET_LOGGED_USER, loggedUser));
        dispatch(createAction(APP_LOADED));
        initWebsockets(loggedUser.id, dispatch);
    }
}

export function appError(dispatch, error) {
    dispatch(createAction(APP_LOADED));
}

export function loadSports() {
    return function (dispatch) {
        dispatch(createAction(SPORTS_FETCH_REQUEST));
        getSports().then(response => {
            let normalizedData = normalize(response.data, [schemas.sportSchema]);
            dispatch(createAction(SPORTS_FETCH_SUCCESS, normalizedData));
        }).catch(err => handleError(err, dispatch))
    }
}

