import coreLangData from '../lang'
import _ from 'lodash'
import {importModule} from "./langUtil";

export function translationExists(intl, id) {
    return !!intl.messages[id.toLowerCase()];
}

export function trans(intl, id) {
    if(!translationExists(intl, id)) {
        return id
    }
    return intl.formatMessage({id: id.toLowerCase()});
}

export let  importModules = (modules) => {
    let langData = {}
    Object.keys(modules).forEach((module) => {
        langData = importModule(module, langData, modules[module])
    })
    return langData
}

export let includeCoreLang = (langData) => {
    return _.merge(coreLangData, langData)
}
