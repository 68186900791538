import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import SearchResultTileBusiness from "../components/SearchResultTile";
import SearchResultTileFacility from "../components/SearchResultTileFacility";
import SearchResultTile from '../components/SearchResultTile'
import {Row, Col} from 'reactstrap';
import {withRouter, useLocation} from 'react-router-dom';
import {
    search
} from "../actions/search";
import ReservationModal from "../components/reservation/ReservationModal";
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import FacilityTypes from "../components/search/FacilityTypes";
import * as QueryString from "query-string"
import SearchHeader from '../components/search/SearchHeader'
import FacilityMap from '../components/map/FacilityMap'
import Button from '../core/buttons/Button'
import {createAction} from "../core/utils/appUtil";
import DatatablePagination from '../core/components/datatable/DatatablePagination'
import SearchResultTileSkeleton from '../components/SearchResultTileSkeleton'
import {SEARCH_SET_PAGE, SEARCH_SET_PER_PAGE} from '../constants/actiontypes/facility'
import {SEARCH_SET_FILTER_FACILITY_TYPE} from "../constants/actiontypes/facilityType";
import MobileSearch from "../components/MobileSearch";
import {loadFacilityTypes} from "../actions/facilityType";

let SearchResultPage = () => {

    let dispatch = useDispatch()

    let location = useLocation()

    let {
        per_page: perPage,
        page,
        loading,
        place,
        selected_facility: selectedFacility,
        search_text: searchText,
        distance,
        sort,
        loading_facilities: loadingFacilities,
        facility_type: facilityType
    } = useSelector(state => state.search)

    let isMobile = useSelector(state => state.app.mobile)

    const params = QueryString.parse(location.search, {arrayFormat: 'bracket'});

    let searchType = params.type === 'rental' ? 'facility' : 'business'

    let allObjects = useSelector(state => state[searchType].byId)

    let result = useSelector(state => state.search.result).map(r => allObjects[r]).filter(r => !!r)

    React.useEffect(() => {
        dispatch(search(params))
    }, [
        JSON.stringify(params)
    ])

    React.useEffect(() => {
        dispatch(loadFacilityTypes())
    }, [])

    return <React.Fragment>
        <Row className='mt-2'>
            {isMobile && <MobileSearch/>}
        </Row>
        <Row>
            <Col lg={7} className='mb-3'>
                <>
                    <SearchHeader/>
                    {loading && <div>
                        <SearchResultTileSkeleton/>
                        <SearchResultTileSkeleton/>
                        <SearchResultTileSkeleton/>
                    </div>}
                    {!result.length && !loading &&
                        <h3 className='mt-5 text-center'>Nie znaleziono żadnych pasujących obiektów :(</h3>}
                    <div>
                        {
                            result.map(result =>
                                <SearchResultTile
                                    key={result.id}
                                    enable_hover={true}
                                    businessId={searchType === 'facility' ? result.business : result.id}
                                    facilityId={searchType === 'facility' ? result.id : null}
                                    sportId={params.sport}
                                />)
                        }
                    </div>
                </>
            </Col>
            <Col lg={5} className='map-container'>
                <FacilityMap type={searchType}/>
            </Col>
        </Row>
        <ReservationModal/>
    </React.Fragment>


}

export default SearchResultPage