import React, {Component, useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import {createAction} from "../../utils/reduxUtil";
import {
    CLOSE_RESERVATION_MODAL,
    OPEN_RESERVATION_MODAL,
    OPEN_RESERVATION_SUMMARY,
    RESERVATION_MODAL_FETCH_TERMS_REQUEST,
    RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    RESERVATION_MODAL_SELECT_PRICE_LIST,
    SELECT_DAY,
    SELECT_TERM
} from "../../constants/actiontypes/reservation";
import {makeReservation, selectMonth} from "../../actions/reservation";
import Slider from "react-slick";
import {addLeadingZeros} from "../../utils/stringUtil";
import {DAY_SHORTCUTS, MONTH_NAMES} from "../../constants/dateContants";
import moment from 'moment';
import {dateFormat} from "../../utils/dateUtil";
import {getPriceText} from "../../utils/appUtil";
import {injectIntl, useIntl} from "react-intl";
import Button from '../../core/buttons/Button'
import calendarImage from '../../assets/img/calendar.svg'
import {useNavigate} from "react-router-dom";
import ModalForm from "../../core/modal/ModalForm";
import {trans} from "../../core/utils/translationUtil";
import backImage from "../../assets/img/arrow-left.svg";
import reservationConfirmationImage from '../../assets/img/reservation_confirmation.svg'
import Loader from "../Loader";
import FacilityImage from '../facility/FacilityImage'
import SelectInput from "../../core/form/select/SelectInput";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getTerms} from "../../api/app";
import Dialog from "@mui/material/Dialog";
import {MdClose} from 'react-icons/md'
import PaymentForm from './PaymentForm'
import {createReservation} from "../../api/app";
import handleError from '../../utils/errorUtil'
import {OPEN_LOGIN_MODAL} from '../../constants/actiontypes/login'
import {
    RENTAL_MODAL_CLOSE,
    RENTAL_MODAL_STEP_SUMMARY,
    RENTAL_MODAL_SET_STEP,
    RENTAL_MODAL_STEP_PAYMENT,
    RENTAL_MODAL_CASH,
    RENTAL_MODAL_PRZELEWY24,
    RENTAL_MODAL_STEP_SUCCESS, RENTAL_MODAL_CREATE_RESERVATION
} from "../../reducers/rentalModal";
import RentalForm from './RentalForm'
import Success from './Success'
import {RENTAL_CALENDAR_LOAD_TERMS} from "../../reducers/rentalcalendar";

let RentalModal = () => {

    let dispatch = useDispatch()

    let navigate = useNavigate()

    let intl = useIntl()

    let closeModal = () => dispatch(createAction(RENTAL_MODAL_CLOSE))

    let isMobile = useSelector(state => state.app.mobile)

    let {
        facilityId, submitting,
        step, price, serviceId, date, time_from: timeFrom, time_to: timeTo,
        opened: isOpen,
        paymentMethod
    } = useSelector(state => state.rentalModal)

    let service = useSelector(state => state.service.byId[state.rentalModal.serviceId])

    return <Dialog onClose={closeModal} open={isOpen} className={'rentalmodal appdialog ' + (isMobile ? 'mobile' : '')}>
        <div className='dialog-header'>
            <span>Podsumowanie rezerwacji</span>
            <MdClose onClick={closeModal} className='close'/>
        </div>
        {step === RENTAL_MODAL_STEP_SUMMARY && <RentalForm/>}
        {step === RENTAL_MODAL_STEP_PAYMENT && <PaymentForm/>}
        {step === RENTAL_MODAL_STEP_SUCCESS && <Success/>}
    </Dialog>
}

export default RentalModal
