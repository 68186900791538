import React from 'react'
import {Route, Routes, BrowserRouter} from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import BasicLayout from "./components/layout/BasicLayout";
import BusinessLayout from './components/layout/BusinessLayout';
import SearchResultPage from "./pages/SearchResultPage";
import FacilityPage from "./pages/FacilityPage";
import Home from "./pages/Home";
import AccountSettingsPage from "./pages/AccountSettingsPage";
import FavouritePage from "./pages/FavouritePage";
import ReservationsPage from "./pages/ReservationsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import BusinessDetailsPage from './pages/BusinessDetailsPage'
import BusinessTrainingsPage from './pages/BusinessTrainingsPage'
import BusinessRentalPage from './pages/BusinessRentalPage'
import Page404 from './pages/Page404'

let createRoute = (Component, requireLogin = true) => <BasicLayout
    requireLogin={requireLogin}><Component/></BasicLayout>

let AppRoutes = () => <BrowserRouter>
    <Routes>
        <Route exact path='/szukaj' element={<BasicLayout requireLogin={false}><SearchResultPage/></BasicLayout>}/>
        <Route exact path='/' element={<BasicLayout requireLogin={false}><Home/></BasicLayout>}/>
        <Route exact path='/reset-hasla/:id' element={<BasicLayout requireLogin={false}><ResetPasswordPage/></BasicLayout>}/>
        <Route exact path='/konto' element={<BasicLayout><AccountSettingsPage/></BasicLayout>}/>
        <Route exact path='/rezerwacje' element={<BasicLayout><ReservationsPage/></BasicLayout>}/>
        <Route exact path='/biznes/:business' element={<BusinessLayout requireLogin={false}><BusinessDetailsPage/></BusinessLayout>}/>
        <Route exact path='/biznes/:business/treningi' element={<BusinessLayout requireLogin={false}><BusinessTrainingsPage/></BusinessLayout>}/>
        <Route exact path='/biznes/:business/wynajem' element={<BusinessLayout requireLogin={false}><BusinessRentalPage/></BusinessLayout>}/>
        <Route exact path='/biznes/:business/obiekt/:slug' element={<BasicLayout requireLogin={false}><FacilityPage/></BasicLayout>}/>
        <Route path='*' element={<BasicLayout><Page404/></BasicLayout>}/>
    </Routes>
</BrowserRouter>

export default AppRoutes