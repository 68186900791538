import React from 'react';
import {injectIntl} from "react-intl";
import Button from '../core/buttons/Button';
import {useSelector} from "react-redux";
import {initReservationModal} from "../actions/reservation";
import {withRouter} from 'react-router-dom';
import {getDistance} from 'geolib';
import {createAction} from "../utils/reduxUtil";
import {CHANGE_CURRENT_OBJECT} from "../constants/actiontypes/facility";
import {useHistory} from 'react-router-dom'
import ReserveButton from '../components/buttons/ReserveButton'
import {getPriceText} from "../utils/appUtil";
import {useIntl} from 'react-intl'
import ServiceTable from './facility/ServiceTable'
import {composeUtils} from "../core/utils/appUtil";
import {MdGpsFixed} from 'react-icons/md'
import IconButton from "../core/buttons/IconButton";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

let SearchResultTileSkeleton = ({enable_hover, facilityId, intl, history, dispatch}) => {

    return <div className='search-result-item skeleton'>
        <div className='d-flex flex-row my-2 p-2'>
            <div>
                <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                    <Skeleton height={180} width={180}/>
                </SkeletonTheme>
            </div>
            <div className='p-2 w-100'>
                <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5">
                    <Skeleton height={30} width='100%'/>
                    <Skeleton height={30} width='100%'/>
                    <Skeleton height={30} width='100%'/>
                    <Skeleton height={30} width='100%'/>
                    <Skeleton height={30} width='100%'/>
                </SkeletonTheme>
            </div>
        </div>
    </div>
}

export default composeUtils(SearchResultTileSkeleton)
