import _ from "lodash";

var initialState = {
    byId: {},
    allIds: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.customFieldOption) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.customFieldOption)
        }
    }
    switch (action.type) {
        default:
            return state;
    }
};