import React from 'react';
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import {BiCurrentLocation} from 'react-icons/bi';
import {BsCalendarFill} from 'react-icons/bs';
import {AiOutlineHeart, AiOutlineCalendar, AiOutlineSearch} from 'react-icons/ai';
import {withRouter} from 'react-router-dom';
import avatar from "../../assets/img/avatar.jpg";
import {FaAngleUp} from 'react-icons/fa';
import logo from '../../assets/img/logo_white.svg'
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button
} from 'reactstrap';
import {NotificationIcon} from '../../assets/icons/notifications-icon.svg';
import {CHANGE_LANGUAGE_REQUEST} from "../../constants/actiontypes/app";
import {createAction} from "../../utils/reduxUtil";
import {trans} from "../../utils/translationUtil";
import {OPEN_LOGIN_MODAL, OPEN_REGISTER_MODAL} from "../../constants/actiontypes/login";
import img from '../../assets/img/header.jpg';
import {CHANGE_SEARCH_TEXT} from "../../constants/actiontypes/facility";
import NotificationDropdown from "./../notification/NotificationDropdown";
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import SearchForm from './SearchForm'
import FacilityTypesPanel from './FacilityTypesPanel'

let MyNavbar = ({withSearchBar, businessContext}) => {

    let dispatch = useDispatch()

    let business = useSelector(state => state.business.byId[state.app.business_id])

    let intl = useIntl()

    let loggedUser = useSelector(state => state.app.logged_user)

    let [isOpen, setIsOpen] = React.useState(false)

    let [hasNavbar, setHasNavbar] = React.useState(false)

    let changeBackground = () => {
        setHasNavbar(state => window.scrollY >= 80)
    }

    let openLoginModal = () => dispatch(createAction(OPEN_LOGIN_MODAL))

    let openRegisterModal = () => dispatch(createAction(OPEN_REGISTER_MODAL))

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            changeBackground()
        });
    }, [])

    const toggle = () => setIsOpen(state => !state)

    return <header className={(withSearchBar ? 'with-background' : '') + ' ' + (businessContext ? 'business-context' : '')}>
        <div className={'navbar' + (hasNavbar ? ' active' : '')}>
            <div className='d-flex justify-content-between w-100 px-5 py-2'>
                <Link to='/' className='navbar-brand'>
                    {!businessContext && <img src={logo} style={{height: '50px'}}/>}
                    {(businessContext && business.logo) && <img src={process.env.REACT_APP_API_URL + '/image/' + business.logo.id}
                                           style={{marginRight: '30px', maxHeight: '60px'}}/>}
                    {(businessContext && !business.logo) && <span
                        style={{marginRight: '30px', maxHeight: '60px'}}>{business.name}</span>}
                </Link>
                <div className='d-flex align-items-center w-100 justify-content-end'>
                    {(!withSearchBar && !businessContext) && <SearchForm/>}
                    {!loggedUser && <span onClick={openLoginModal} className='mx-3 link'>
                        {trans(intl, 'app.login')}
                    </span>}
                    {!loggedUser && <span onClick={openRegisterModal} className='mx-3 link'>
                        {trans(intl, 'app.register')}
                    </span>}
                    {loggedUser && <Link to='/rezerwacje' className='mx-3 link text-decoration-none'>
                        {trans(intl, 'app.reservations')}
                    </Link>}
                    {loggedUser && <Link to='/konto' className='mx-3 link text-decoration-none'>
                        {loggedUser.fullname}
                    </Link>}
                    {(!loggedUser && withSearchBar) && <a href={process.env.REACT_APP_PARTNERS_PANEL_URL}
                                          className='for-partners bold text-decoration-none px-4 py-2'>
                        <span className=''>{trans(intl, 'app.forpartners')}</span>
                    </a>}
                </div>
            </div>
        </div>
        {withSearchBar &&
            <div className='d-flex justify-content-center flex-column align-items-center search-container-main'>
                <p>Zarezerwuj usługi w obiektach sportowych i rozrywkowych w <span
                    className='fw-bold'>Twojej okolicy!</span></p>
                <SearchForm/>
            </div>}
        {withSearchBar && <FacilityTypesPanel/>}
    </header>

}

export default MyNavbar
