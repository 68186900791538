export default {
    'dashboard' : 'Dashboard',
    'reservations' : 'Rezerwacje',
    'sales' : 'Sprzedaż',
    'facilities' : 'Obiekty',
    'offer' : 'Oferta',
    'services' : 'Usługi',
    'personnel' : 'Twój zespół',
    'partners' : 'Pracownicy',
    'permissions' : 'Role i uprawnienia',
    'resources' : 'Zasoby',
    'resource_categories' : 'Kategorie zasobów',
    'business' : 'Biznes',
    'business_data' : 'Dane podstawowe',
    'clients' : 'Klienci',
    'myaccount': 'Moje konto',
    'data': 'Dane',
    'preferences': 'Preferencje',
    'notifications': 'Powiadomienia',
    'logout': 'Wyloguj',
    'business_reservations': 'Ustawienia rezerwacji',
    'reservationhistory': 'Historia rezerwacji',
}