import React, {useState} from 'react'
import {injectIntl, useIntl} from "react-intl"
import {Link} from "react-router-dom"
import moment from 'moment'
import {trans} from '../utils/translationUtil'
import Loader from "./Loader";
import api from '../core/api';
import Button from '../core/buttons/Button'
import {getRentalTerms} from "../core/api/facility";
import {useDispatch, useSelector} from "react-redux";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {createAction} from "../utils/reduxUtil";
import {OPEN_RENTAL_RESERVATION_SUMMARY} from "../constants/actiontypes/reservation";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let RentalForm = ({facilityId}) => {

    let dispatch = useDispatch()

    let [currentDay, setCurrentDay] = useState(null)

    let [currentObject, setCurrentObject] = useState(null)

    let [currentTerm, setCurrentTerm] = useState(null)

    let allResources = useSelector(state => state.resource.byId)

    let services = useSelector(state => state.service.byId)

    let facility = useSelector(state => state.facility.byId[facilityId])
console.log('f', facility)
    let rentalServices = facility?.services?.map(s => services[s]).filter(service => service.type === 'rental') ?? []

    let entireRental = rentalServices.filter(s => s.price_per_resource === false)[0]

    let partialRental = rentalServices.filter(s => s.price_per_resource === true)[0]

    let resources = facility.resources?.map(r => allResources[r])

    let now = moment();

    let [day, setDay] = useState(now.format('Y-MM-DD'))

    let start = moment(day)

    let end = moment(day).add(6, 'days');

    let [modalOpened, setModalOpened] = useState(false)

    let [loading, setLoading] = useState(false)

    let defaultTerms = {}

    let tmpDate = start.clone()
    for (let i = 0; i <7; i++) {
        let terms = []
        for (let j = 7; j < 19; j++) {
            terms.push({
                    from: j + ':00',
                    to: (j+1) + ':00',
                    resources:[]
                })
        }
        defaultTerms[tmpDate.format('Y-MM-DD')] = {
            terms
        }
        tmpDate = tmpDate.add(1, 'days')
    }

    let [terms, setTerms] = useState(defaultTerms)

    React.useEffect(() => {
        setLoading(true);
        api.facility.getRentalTerms(facilityId, start.format('Y-MM-DD'), end.format('Y-MM-DD')).then(response => {
            setTerms(response.data.terms)
            setLoading(false)
        })
    }, [
        facilityId, start.format('Y-MM-DD'), end.format('Y-MM-DD')
    ])

    let isActive = (day, objectId, term) => {
        if (currentDay !== day) {
            return false;
        }
        if (objectId !== currentObject) {
            return false;
        }
        if (!currentTerm) {
            return false;
        }
        if (currentTerm.from <= term.from && currentTerm.to >= term.to) {
            return true;
        }
        return false;
    }

    let setTerm = (day, objectId, term) => {
        let wasActive = isActive(objectId, term)
        if (currentObject === objectId && currentDay === day && currentTerm !== null && term.to === currentTerm.to && term.from === currentTerm.from) {
            setCurrentTerm(null)
            return;
        }
        if (currentObject === objectId && currentDay === day && currentTerm !== null && term.to === currentTerm.from) {
            setCurrentTerm({from: term.from, to: currentTerm.to})
            return;
        }
        if (currentObject === objectId && currentDay === day && currentTerm !== null && term.from === currentTerm.to) {
            setCurrentTerm({from: currentTerm.from, to: term.to})
            return;
        }
        setCurrentDay(day)
        setCurrentObject(objectId)
        setCurrentTerm(term)
    }

    let rentalEnabled = facility.rental.partial || facility.rental.entire

    let getPrice = () => {
        if (!currentTerm) {
            return;
        }
        let priceKey = facilityId === currentObject ? 'entire_price' : 'partial_price';
        let price = terms[currentDay]?.terms?.filter(t => t.from >= currentTerm.from && t.to <= currentTerm.to)
            .reduce((partialSum, a) => partialSum + a[priceKey], 0)
        return price;
    }

    let openReservationSummary = () => {
        dispatch(createAction(OPEN_RENTAL_RESERVATION_SUMMARY, {
            resource_id: currentObject !== facilityId ? currentObject : null,
            facility_id: facilityId,
            service_id: currentObject === facilityId ? entireRental.id : partialRental.id,
            day: currentDay,
            from: currentTerm.from,
            to: currentTerm.to,
            price: getPrice()
        }))
    }

    let canBack = () => start.format('Y-MM-DD') > moment().format('Y-MM-DD')

    let back = () => {
        let newDay = moment(day).subtract(7, 'days')
        if (moment(day).subtract(7, 'days').format('Y-MM-DD') < moment().format('Y-MM-DD')) {
            newDay = moment()
        }
        setCurrentDay(null)
        setCurrentTerm(null)
        setCurrentObject(null)
        setDay(newDay.format('Y-MM-DD'))
    }

    let next = () => {
        setCurrentDay(null)
        setCurrentTerm(null)
        setCurrentObject(null)
        setDay(moment(day).add(7, 'days').format('Y-MM-DD'))
    }

    let today = () => {
        setCurrentDay(null)
        setCurrentTerm(null)
        setCurrentObject(null)
        setDay(moment().format('Y-MM-DD'))
    }

    let intl = useIntl()

    let [isOpen, setIsOpen] = React.useState(false)

    return <div style={{position: 'relative'}}>
        {
            (loading) && <div style={{position:"absolute", zIndex: 999,width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)'}}>
                <div className=''>
                    <Loader/>
                </div>
            </div>
        }
        {
            (!rentalEnabled && !loading) &&
            <p style={{textAlign: 'center'}}>Obiekt nie jest dostępny do rezerwacji online</p>
        }
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} className='navigation'>
            {
                rentalEnabled && <>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            autoOk
                            allowSameDateSelection={true}
                            open={isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            label=''
                            minDate={moment()}
                            value={moment(day)}
                            onChange={(newValue) => {
                                setDay(moment(newValue.toISOString()).format('yyyy-MM-DD'))
                            }}
                            disableMaskedInput={true}
                            inputFormat="yyyy-MM-dd"
                            renderInput={(params) => {
                                let date = moment(params.inputProps.value).format('dddd, D MMMM Y')
                                return <TextField
                                    {...params}
                                    onClick={() => {
                                        setIsOpen(true)
                                        return false;
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        value: date,
                                        onChange: () => {
                                        }
                                    }}
                                />
                            }
                            }
                        />
                    </LocalizationProvider>
                    <Button onClick={back} text={'<'} disabled={!canBack()} extraClasses={canBack() ? '' : 'vhidden'}/>
                    <Button onClick={today} text='Dziś'/>
                    <Button onClick={next} text={'>'}/>
                </>
            }
        </div>
        {
            (entireRental || partialRental) &&
            <div style={{overflowX: 'auto'}}><table className='table table-stripped table-bordered rental-table'>
                {
                    Object.keys(terms).map(d => {
                        let hasFreeTerms = terms[d].terms.filter(t => t.facility || t.resources.length > 0).length > 0
                        return <>
                            <tr className='header'>
                                <td>{moment(d).format('dddd, DD.MM.Y')}</td>
                                {
                                    terms[d].terms.map(term => {
                                        return <td>{term.from}</td>
                                    })
                                }
                            </tr>
                            {terms[d].error === 'MISSING_OPENING_HOURS' && <tr>
                                <td colSpan={999} align='center'>
                                    <p style={{textAlign: 'center'}}><b>Obiekt zamknięty</b></p>
                                </td>
                            </tr>}
                            {(!terms[d].error && !hasFreeTerms) && <tr>
                                <td colSpan={999} align='center'>
                                    <p style={{textAlign: 'center'}}><b>Brak wolnych terminów</b></p>
                                </td>
                            </tr>}
                            {
                                (!terms[d].error && hasFreeTerms && entireRental) && <tr>
                                    <td>{facility.name}</td>
                                    {
                                        terms[d].terms.map(term => {
                                            return <td>
                                                {
                                                    term.facility &&
                                                    <div className={isActive(d, facilityId, term) ? 'active' : ''}
                                                         onClick={() => setTerm(d, facilityId, term)}>
                                                        {term.entire_price.toString()} zł
                                                    </div>
                                                }
                                            </td>
                                        })
                                    }
                                </tr>
                            }
                            {
                                (!terms[d].error && hasFreeTerms && partialRental) && resources.map(resource => <tr>
                                    <td>{resource.name}</td>
                                    {
                                        terms[d].terms.map(term => {
                                            return <td>
                                                {term.resources.includes(resource.id) &&
                                                    <div className={isActive(d, resource.id, term) ? 'active' : ''}
                                                         onClick={() => setTerm(d, resource.id, term)}>
                                                        {term.partial_price.toString()} zł
                                                    </div>
                                                }
                                            </td>
                                        })
                                    }
                                </tr>)
                            }
                        </>
                    })
                }
            </table></div>
        }
        <div className={'rental-summary-modal ' + (currentTerm ? '' : 'close')}>
            <div className='container'>
                <div>
                    <div>Wybierz interesujące Cię godziny i przejdź do podsumowania</div>
                    <div className='facility-info'>
                        {currentDay}, {currentTerm?.from} - {currentTerm?.to}
                        <br/>
                        {facilityId === currentObject ? facility.name : allResources[currentObject]?.name}
                    </div>

                </div>
                <div>
                    <div>
                        Kwota
                        <br/>
                        <div className='price'>
                            {getPrice()} zł
                        </div>
                    </div>
                    <Button text='Rezerwuj >' onClick={() => openReservationSummary()}/>
                </div>
            </div>
        </div>
    </div>
}

export default injectIntl(RentalForm)
