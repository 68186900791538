import React, {useState} from 'react'
import TextInput from "../../core/form/TextInput"
import {ReactComponent as PinIcon} from '../../assets/icons/pin.svg'
import ReduxSelect from "../../core/form/select/ReduxSelect";
import FormWrapper from "../../core/form/FormWrapper";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import api from "../../core/api";
import {appError} from "../../actions/app";
import InputAdornment from "@mui/material/InputAdornment";
import {useController} from "react-hook-form";
import * as QueryString from "query-string";
import {composeUtils, uniqueArray} from "../../core/utils/appUtil";
import {useLocation} from 'react-router-dom'
import SelectInput from "../../core/form/select/SelectInput";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getValidationMessage} from "../../core/utils/validationUtil";
import {useSelector, useDispatch} from 'react-redux'
import {loadSports} from "../../actions/app";

let SportField = ({form}) => {

    let {
        all: sportIds,
        byId: sports
    } = useSelector(state => state.sport)

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name: 'sport',
        control: form.control,
        defaultValue: sportIds.map(id => sports[id]).filter(sport => sport.name === 'Piłka nożna')[0]?.id,
        rules: {
            required: {
                value: true,
                message: 'Pole jest wymagane'
            }
        }
    })

    return <FormWrapper extraClasses='place-select' errorMessage={error?.message}>
        <select className='form-control' placeholder='Wybierz' value={value} onChange={e => onChange(e.target.value)}>
            <option>Wybierz sport</option>
            {
                sportIds.map(t => sports[t])
                    .map(sport => <option value={sport.id}>{sport.name}</option>)
            }
        </select>
    </FormWrapper>

}

export default composeUtils(SportField)