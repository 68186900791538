import React, {useState} from 'react'
import Loader from "../Loader";
import {useSelector, useDispatch} from 'react-redux'
import Button from "../../core/buttons/Button";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import {createAction} from "../../utils/reduxUtil";
import {
    RENTAL_CALENDAR_LOAD_TERMS,
    RENTAL_CALENDAR_RESET,
    RENTAL_CALENDAR_HOVER_SLOT,
    RENTAL_CALENDAR_TERMS_LOADED,
    RENTAL_CALENDAR_RESET_HOVER
} from "../../reducers/rentalcalendar";
import {RENTAL_MODAL_OPEN, RENTAL_MODAL_CLOSE} from '../../reducers/rentalModal'
import api from '../../core/api'
import {geServiceTerms} from "../../api/app";
import {getMinFacilityOpeningHourTime, getMaxFacilityOpeningHourTime, getReservationTimes} from "../../selectors";

let TermTable = () => {

    let dispatch = useDispatch()

    let {
        loading,
        businessId,
        serviceId,
        facilityId,
        facilityPartId,
        start,
        end,
        terms,
        duration,
        hoveredSlot,
    } = useSelector(state => state.rentalcalendar)

    let facility = useSelector(state => state.facility.byId[facilityId])

    let service = useSelector(state => state.service.byId[serviceId])

    let business = useSelector(state => state.business.byId[businessId])

    let interval = business?.reservation_interval

    if (interval !== 30 && service?.price.times.filter(time => time % 60 !== 0).length > 0) {
        interval = 30
    }

    let reservationTimes = useSelector(state => getReservationTimes(state, business?.id, facilityId, interval ?? 30, duration))

    React.useEffect(() => {
        if (serviceId) {
            dispatch(createAction(RENTAL_CALENDAR_LOAD_TERMS))
        }
    }, [serviceId, start, end])

    React.useEffect(() => {
        if (loading) {
            geServiceTerms(serviceId, start, end).then(response => {
                let terms = {}
                for (let i = 0; i < response.data.length; i++) {
                    terms[response.data[i].date] = response.data[i].slots
                }
                dispatch(createAction(RENTAL_CALENDAR_TERMS_LOADED, {
                    terms: terms
                }))
            })
        }
    }, [loading])

    const getDateRange = (firstDate, lastDate) => {
        if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day'))
            return [lastDate];
        let date = firstDate;
        const dates = [date];
        do {
            date = moment(date).add(1, 'day');
            dates.push(date.format('YYYY-MM-DD'));
        } while (moment(date).isBefore(lastDate));
        return dates;
    };

    let days = getDateRange(start, end);

    let hover = (date, time) => {
        if (hoveredSlot?.date === date && hoveredSlot?.time === time) {
            return;
        }
        let foundSlot = terms[date].filter(term => term.time_from <= time.from && term.time_to >= time.to && term.duration == duration)
        if (foundSlot.length > 0) {
            dispatch(createAction(RENTAL_CALENDAR_HOVER_SLOT, {
                slot: foundSlot[foundSlot.length - 1]
            }))
        }
    }

    let renderInactiveSlot = () => <div className='term-table-column slot inactive'></div>

    let isAvailable = (date, time) => terms[date]?.filter(term => term.time_from <= time.from && term.time_to >= time.to && term.duration === duration)
        .filter(term => {
            if (facilityId && service.required_facility_parts > 0 && facilityPartId) {
                return term.parts[facilityId].includes(facilityPartId)
            }
            return true;
        })
        .length > 0

    let getHoverClasses = (date, time) => {
        let classes = '';
        if (date === hoveredSlot?.date && (
            time.from >= hoveredSlot?.time_from && time.to <= hoveredSlot?.time_to
        )) {
            classes = 'hovered'
        }
        if (classes) {
            if (time.from === hoveredSlot?.time_from) {
                classes += ' hover-top';
            }
            if (time.to === hoveredSlot?.time_to) {
                classes += ' hover-bottom';
            }
            if (time.from !== hoveredSlot?.time_from && time.to !== hoveredSlot?.time_to) {
                classes += ' hover-middle';
            }
        }
        return classes;
    }

    let selectTerm = (date, time) => {
        let fId = facilityId;
        let pId = facilityPartId;
        // if (service.required_facility) {
        //     if (facilityId) {
        //         fId = facilityId
        //     } else {
        //         fId = hoveredSlot.facilities[0]
        //     }
        // }
        // if (service.required_facility_parts) {
        //     fId = facilityId
        //     if (!fId) {
        //         fId = Object.keys(hoveredSlot.parts[0])
        //     }
        //     if (facilityPartId && hoveredSlot.parts[fId].includes(facilityPartId)) {
        //         pId = facilityPartId
        //     } else {
        //         pId = hoveredSlot.parts[fId][0]
        //     }
        // }
        dispatch(createAction(RENTAL_MODAL_OPEN, {
            date: hoveredSlot.date,
            time_from: hoveredSlot.time_from,
            time_to: hoveredSlot.time_to,
            businessId: businessId,
            serviceId: serviceId,
            price: hoveredSlot.price,
            facilityId: fId,
            duration: hoveredSlot.duration,
            facilityPartId: pId,
        }))
    }

    let renderSlot = (date, time) => {
        if (!isAvailable(date, time)) {
            return renderInactiveSlot()
        }
        let hoverClasses = getHoverClasses(date, time)
        return <div className={'term-table-column slot active ' + hoverClasses} onMouseEnter={() => hover(date, time)}
                    onClick={() => selectTerm(date, time)}>
        </div>
    }

    return <div className='term-table' onMouseLeave={() => dispatch(createAction(RENTAL_CALENDAR_RESET_HOVER))}>
        <div className='term-table-row'>
            <div className='term-table-column'></div>
            {days.map(date => <div className='term-table-column' key={date}>
                <div className='day-header'>
                    <div>{moment(date).format('ddd')}</div>
                    <div><b>{moment(date).format('DD.MM')}</b></div>
                </div>
            </div>)}
        </div>
        {
            reservationTimes.map(time => <div className='term-table-row' key={time}>
                <div className='term-table-column'>
                    <div className='time'>
                        {time.from} - {time.to}
                    </div>
                </div>
                {days.map(date => renderSlot(date, time))}
            </div>)
        }
    </div>
}

export default TermTable