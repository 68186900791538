import React, {Component, useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import {createAction} from "../../utils/reduxUtil";
import {
    CLOSE_RESERVATION_MODAL,
    OPEN_RESERVATION_MODAL,
    OPEN_RESERVATION_SUMMARY,
    RESERVATION_MODAL_FETCH_TERMS_REQUEST,
    RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    RESERVATION_MODAL_SELECT_PRICE_LIST,
    SELECT_DAY,
    SELECT_TERM
} from "../../constants/actiontypes/reservation";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {makeReservation, selectMonth} from "../../actions/reservation";
import Slider from "react-slick";
import {addLeadingZeros} from "../../utils/stringUtil";
import {DAY_SHORTCUTS, MONTH_NAMES} from "../../constants/dateContants";
import moment from 'moment';
import {dateFormat} from "../../utils/dateUtil";
import {getPriceText} from "../../utils/appUtil";
import {injectIntl, useIntl} from "react-intl";
import Button from '../../core/buttons/Button'
import calendarImage from '../../assets/img/calendar.svg'
import {useNavigate} from "react-router-dom";
import ModalForm from "../../core/modal/ModalForm";
import {trans} from "../../core/utils/translationUtil";
import backImage from "../../assets/img/arrow-left.svg";
import reservationConfirmationImage from '../../assets/img/reservation_confirmation.svg'
import {LocalizationProvider} from '@mui/x-date-pickers';
import Loader from "../Loader";
import FacilityImage from '../facility/FacilityImage'
import SelectInput from "../../core/form/select/SelectInput";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {createReservation, createPrzelewy24Payment} from "../../api/app";
import Dialog from "@mui/material/Dialog";
import {MdClose} from 'react-icons/md'
import Przelewy24 from './Przelewy24'
import api, {getServiceTerms} from '../../core/api'
import {
    RENTAL_MODAL_CLOSE,
    RENTAL_MODAL_SET_CYCLE,
    RENTAL_MODAL_SET_PAYMENT_METHOD,
    RENTAL_MODAL_CASH,
    RENTAL_MODAL_PRZELEWY24,
    RENTAL_MODAL_STEP_PAYMENT,
    RENTAL_MODAL_SET_STEP,
    RENTAL_MODAL_STEP_SUMMARY,
    RENTAL_MODAL_SET_LOADING_TERMS,
    RENTAL_MODAL_CREATE_RESERVATION,
    RENTAL_MODAL_STEP_SUCCESS,
    RENTAL_MODAL_SET_CYCLE_TYPE,
    RENTAL_MODAL_SET_ENDS_ON,
    RENTAL_MODAL_SET_RECURRENCE_COUNT, RENTAL_MODAL_TERMS_LOADED, RENTAL_MODAL_SET_DAYS_OF_WEEK
}
    from "../../reducers/rentalModal";
import {RENTAL_CALENDAR_LOAD_TERMS, RENTAL_CALENDAR_SET_DATE} from "../../reducers/rentalcalendar";
import handleError from "../../utils/errorUtil";

let RentalCycleForm = () => {

    let dispatch = useDispatch()

    let {
        price,
        serviceId,
        date,
        time_from: timeFrom,
        time_to: timeTo,
        duration,
        facilityPartId,
        cycle: {
            loadingTerms,
            type: cycleType,
            recurrenceCount,
            endsOn,
            daysOfWeek,
            terms
        }
    } = useSelector(state => state.rentalModal)

    let service = useSelector(state => state.service.byId[state.rentalModal.serviceId])

    let [isOpen, setIsOpen] = useState(false)

    let isCurrentDay = day => moment(date).day() === day

    React.useEffect(() => {
        if (!serviceId) {
            return;
        }
        dispatch(createAction(RENTAL_MODAL_SET_LOADING_TERMS))
        let params = {
            type: 'cycle',
            cycle_type: cycleType,
            days_of_week: daysOfWeek.join(','),
            recurrence_count: recurrenceCount,
            starts_on: date,
            ends_on: endsOn,
            interval_weeks: 1,
        };
        getServiceTerms(serviceId, params).then(r => {
            let days = r.data ?? []
            let dates = {}
            for (let i = 0; i < days.length; i++) {
                let date = days[i].date
                dates[date] = {
                    index: i + 1,
                    slot: days.filter(d => d.date === date)[0]?.slots?.filter(
                        slot => slot.time_from === timeFrom && slot.duration === duration
                    ).filter(slot => {
                        if(service.type === 'partial-rental') {
                            if(facilityPartId) {
                                return slot.parts.includes(facilityPartId)
                            }
                            return slot.parts.length > 0
                        }
                        return true;
                    })[0] ?? null
                }
            }
            dispatch(createAction(RENTAL_MODAL_TERMS_LOADED, dates))
        })
    }, [cycleType, daysOfWeek.join(','), recurrenceCount, date, endsOn, timeFrom, serviceId])

    let setDay = day => {
        if(isCurrentDay(day)) {
            return;
        }
        if(daysOfWeek.includes(day)) {
            var index = daysOfWeek.indexOf(day);
            if (index !== -1) {
                daysOfWeek.splice(index, 1);
            }
            dispatch(createAction(RENTAL_MODAL_SET_DAYS_OF_WEEK, daysOfWeek))
        } else {
            dispatch(createAction(RENTAL_MODAL_SET_DAYS_OF_WEEK, [...daysOfWeek, day]))
        }
    }

    return <div className='cycle-form'>
        <span className='heading'>W które dni dodatkowo powtarzać rezerwację?</span>
        <div className='days'>
            {
                [0, 1, 2, 3, 4, 5, 6].map(day => <div className={'day ' + (isCurrentDay(day) ? 'current' : '') + ' ' + (daysOfWeek.includes(day) ? 'active' : '')} onClick={e => setDay(day)}>
                    {moment().day(day).format('ddd')}
                </div>)
            }
        </div>
        <span className='heading'>Kiedy zakończyć powtarzanie rezerwacji?</span>
        <div className='cycle-type'>
            <div className={(cycleType === 'ends_on' ? 'active' : '')}
                 onClick={() => dispatch(createAction(RENTAL_MODAL_SET_CYCLE_TYPE, 'ends_on'))}
            >
                Wybierz datę zakończenia
            </div>
            <div className={(cycleType === 'recurrence_count' ? 'active' : '')}
                 onClick={() => dispatch(createAction(RENTAL_MODAL_SET_CYCLE_TYPE, 'recurrence_count'))}>
                Podaj liczbę rezerwacji
            </div>
        </div>
        {cycleType === 'ends_on' && <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    autoOk
                    allowSameDateSelection={true}
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    label=''
                    minDate={moment()}
                    value={moment(endsOn)}
                    onChange={(newValue) => {
                        let newDay = moment(newValue.toISOString())
                        dispatch(createAction(RENTAL_MODAL_SET_ENDS_ON, newDay.format('Y-MM-DD')))
                    }}
                    disableMaskedInput={true}
                    inputFormat="yyyy-MM-dd"
                    renderInput={(params) => {
                        let date = moment(params.inputProps.value).format('dddd, D MMMM Y')
                        return <TextField
                            {...params}
                            onClick={() => {
                                setIsOpen(true)
                                return false;
                            }}
                            inputProps={{
                                ...params.inputProps,
                                value: date
                            }}
                        />
                    }
                    }
                />
            </LocalizationProvider>
        </div>}
        {cycleType === 'recurrence_count' && <div>
            <select className='form-control' onChange={e => dispatch(createAction(RENTAL_MODAL_SET_RECURRENCE_COUNT, e.target.value))}
                    value={recurrenceCount}>
                {[2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => <option value={i}>{i}</option>)}
            </select>
        </div>}
        <span className='heading'>Lista terminów</span>
        {
            loadingTerms && <Loader/>
        }
        {
            !loadingTerms && <div className='term-list'>
                {
                    Object.keys(terms).map(d => {
                        let slot = terms[d].slot
                        return <div className={'term ' + (slot ? '' : 'invalid')}>
                            <span>{d} {timeFrom} - {timeTo}</span>
                            {slot && <span className='price'>{slot.price} zł</span>}
                            {!slot && <span className='invalid-text'>Termin niedostępny</span>}
                        </div>
                    })
                }
            </div>
        }
    </div>
}

export default RentalCycleForm
