import React from 'react';
import InformationLabel from "./InformationLabel";
import ErrorLabel from "./ErrorLabel";
import {useIntl} from 'react-intl';
import {trans, translationExists} from "../utils/translationUtil";
import WarningLabel from "./WarningLabel";

export default ({children, information, errorMessage, extraClasses = '', warningMessage}) => {

    let intl = useIntl();

    if(information && translationExists(intl, information)) {
        information = trans(intl, information)
    }

    if(errorMessage && translationExists(intl, errorMessage)) {
        errorMessage = trans(intl, errorMessage)
    }

    if(warningMessage && translationExists(intl, warningMessage)) {
        warningMessage = trans(intl, warningMessage)
    }

    return <div className={'form-field ' + extraClasses}>
        {children}
        {information && !errorMessage && <InformationLabel text={information}/>}
        {errorMessage && <ErrorLabel text={errorMessage}/>}
        {warningMessage && <WarningLabel text={warningMessage}/>}
    </div>
}