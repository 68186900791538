import React from 'react'
import CardTitle from "./CardTitle";

let Card = ({title, children}) => <div className='card-container'>
    {title && <CardTitle text={title}/>}
    <div className='card'>
        {children}
    </div>
</div>

export default Card