import {LOAD_PROFILE_REQUEST, LOAD_PROFILE_SUCCESS} from "../constants/actiontypes/profile";

var initialState = {
    loading: true,
    profile: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PROFILE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case LOAD_PROFILE_SUCCESS: {
            return {
                ...state,
                loading: false,
                profile: action.payload
            }
        }
        default:
            return state;
    }
};