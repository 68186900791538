import L from "leaflet";
import iconMe from "../../../assets/img/marker-me.png";
import {Marker, Popup} from "react-leaflet";
import React from "react";
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useSelector} from "react-redux";
import MapMarker from './MapMarker'

let markerIconMe = L.icon({
    iconUrl: iconMe,
    shadowUrl: iconShadow,
    iconSize: new L.Point(30, 30),
})

let MarkerMe = () => {

    let currentLocation = useSelector(state => state.app.currentLocation)

    if (!currentLocation) {
        return null
    }

    return <MapMarker icon={markerIconMe}
                      lat={currentLocation.coords.latitude}
                      lon={currentLocation.coords.longitude}>
        <p>Tu jesteś</p>
    </MapMarker>
}

export default MarkerMe