import React from 'react';
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux"
import {useHistory} from 'react-router-dom'
import {withRouter} from 'react-router-dom';
import {loadFacilityTypes} from "../../actions/facilityType";
import icon from '../../assets/icons/map/tennis.svg'
import FacilityType from './FacilityType'
import {composeUtils} from "../../core/utils/appUtil";

let FacilityTypes = ({searchPage, dispatch}) => {

    let {
        all: facilityTypeIds,
        byId: facilityTypes
    } = useSelector(state => state.facilityType)

    let {
        facility_type: currentFacilityType
    } = useSelector(state => state.search)

    React.useEffect(() => {
        dispatch(loadFacilityTypes())
    }, [])

    return <div className='facility-type-list'>
        {
            facilityTypeIds
                .filter(facilityType => !!facilityTypes[facilityType].code)
                .map(facilityType => <FacilityType id={facilityType} key={facilityType}/>)
        }
    </div>
}

export default composeUtils(FacilityTypes)
