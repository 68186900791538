import React, {Component, useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import {createAction} from "../../utils/reduxUtil";
import {
    CLOSE_RESERVATION_MODAL,
    OPEN_RESERVATION_MODAL,
    OPEN_RESERVATION_SUMMARY,
    RESERVATION_MODAL_FETCH_TERMS_REQUEST,
    RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    RESERVATION_MODAL_SELECT_PRICE_LIST,
    SELECT_DAY,
    SELECT_TERM
} from "../../constants/actiontypes/reservation";
import {makeReservation, selectMonth} from "../../actions/reservation";
import Slider from "react-slick";
import {addLeadingZeros} from "../../utils/stringUtil";
import {DAY_SHORTCUTS, MONTH_NAMES} from "../../constants/dateContants";
import moment from 'moment';
import {dateFormat} from "../../utils/dateUtil";
import {getPriceText} from "../../utils/appUtil";
import {injectIntl, useIntl} from "react-intl";
import Button from '../../core/buttons/Button'
import calendarImage from '../../assets/img/calendar.svg'
import {useNavigate} from "react-router-dom";
import ModalForm from "../../core/modal/ModalForm";
import {trans} from "../../core/utils/translationUtil";
import backImage from "../../assets/img/arrow-left.svg";
import reservationConfirmationImage from '../../assets/img/reservation_confirmation.svg'
import Loader from "../Loader";
import FacilityImage from '../facility/FacilityImage'
import SelectInput from "../../core/form/select/SelectInput";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getTerms} from "../../api/app";
import Dialog from "@mui/material/Dialog";
import {MdClose} from 'react-icons/md'
import {
    RENTAL_MODAL_CLOSE,
    RENTAL_MODAL_SET_CYCLE,
    RENTAL_MODAL_SET_PAYMENT_METHOD,
    RENTAL_MODAL_CASH,
    RENTAL_MODAL_PRZELEWY24
}
    from "../../reducers/rentalModal";

let PaymentForm = () => {

    let dispatch = useDispatch()

    let navigate = useNavigate()

    let intl = useIntl()

    let {
        opened: isOpen,
        paymentMethod
    } = useSelector(state => state.rentalModal)

    let closeModal = () => dispatch(createAction(RENTAL_MODAL_CLOSE))

    let {price, serviceId, date, time_from: timeFrom, time_to: timeTo} = useSelector(state => state.rentalModal)

    let service = useSelector(state => state.service.byId[state.rentalModal.serviceId])

    let goToReservations = () => {
        dispatch(createAction(RENTAL_MODAL_CLOSE))
        navigate('/rezerwacje')
    }

    return <div className='dialog-body'>
        <div className='success'>
            <svg width="158" height="145" viewBox="0 0 158 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="14.4922" y="0.765625" width="51.5427" height="51.5427" rx="10" fill="#9E96FF"/>
                <rect opacity="0.5" x="128.443" y="33.6357" width="29.3594" height="29.3594" rx="10" fill="#9E96FF"/>
                <rect opacity="0.5" x="0.443359" y="75.6357" width="31.5329" height="31.5329" rx="8" fill="#DEDBFF"/>
                <rect opacity="0.5" x="114.73" y="107.587" width="36.5935" height="36.5935" rx="8" fill="#DEDBFF"/>
                <circle cx="83.9199" cy="79.1406" r="60" fill="#4A3AFF"/>
                <g filter="url(#filter0_d_3465_41070)">
                    <path d="M61.2031 82.3858L74.1836 95.3663L106.635 62.915" stroke="white" stroke-width="8"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <filter id="filter0_d_3465_41070" x="27.2031" y="32.915" width="113.432" height="100.451"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="15"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.3 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3465_41070"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3465_41070" result="shape"/>
                    </filter>
                </defs>
            </svg>

            <p>Udało się!</p>
            <div className='info'>Możesz teraz zobaczyć status rezerwacji w zakładce “Moje rezerwacje”</div>
            <Button text='Zobacz rezerwację' onClick={goToReservations}/>
        </div>
    </div>
}

export default PaymentForm
