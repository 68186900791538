import {createAction} from "./reduxUtil";
// import {SHOW_ERROR} from "../constants/actiontypes/app";
// import {sessionExpired} from "../actions/auth";
import {toastError, toastSuccess} from "./toastUtil";

let handleError = (error, dispatch) => {
    console.log(error);
    toastError('Wystąpił błąd')
    // if(error?.response?.status === 401) {
    //     dispatch(sessionExpired());
    //     return;
    // }
    if (error?.response?.status === 422) {
        return error.response.data;
    }
    // dispatch(createAction(SHOW_ERROR, error.response?.data))
    return false;
}

export default handleError