import React from 'react'
import {initApp} from "../actions/app";
import {createAction} from "../utils/reduxUtil";
import {SET_LOCATION, SET_MOBILE} from "../constants/actiontypes/app";
import {useDispatch, useSelector} from "react-redux";

let Navigator = () => {

    let dispatch = useDispatch()

    let setLocation = (position) => dispatch(createAction(SET_LOCATION, position))

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(setLocation)
    }, [])

    return null
}

export default Navigator