import React from 'react';
import {injectIntl} from "react-intl";
import Button from '../core/buttons/Button';
import {useSelector} from "react-redux";
import {initReservationModal} from "../actions/reservation";
import {getFacilityDetails} from "../actions/facility";
import {withRouter} from 'react-router-dom';
import {getDistance} from 'geolib';
import {createAction} from "../utils/reduxUtil";
import {CHANGE_CURRENT_OBJECT} from "../constants/actiontypes/facility";
import {useNavigate} from 'react-router-dom'
import BusinessSliderItem from './BusinessSliderItem'
import ReserveButton from '../components/buttons/ReserveButton'
import {getPriceText} from "../utils/appUtil";
import {useIntl} from 'react-intl'
import ServiceTable from './facility/ServiceTable'
import {composeUtils} from "../core/utils/appUtil";
import {MdGpsFixed} from 'react-icons/md'
import IconButton from "../core/buttons/IconButton";

let SearchResultTileFacility = ({enable_hover, facilityId, intl, dispatch}) => {

    let navigate = useNavigate()

    let facility = useSelector(state => state.facility.byId[facilityId])

    let business = useSelector(state => state.business.byId[facility.business])

    let services = useSelector(state => state.service.byId)

    let {
        currentLocation,
        mobile: isMobile
    } = useSelector(state => state.app)

    let openFacilityPage = () => {
        // dispatch(getFacilityDetails(facility.slug))
        // navigate('/' + business.subdomain + '/' + facility.slug);
    }

    let setCurrentFacility = () => {
        if (!isMobile && enable_hover) {
            dispatch(createAction(CHANGE_CURRENT_OBJECT, facility.id))
        }
    }

    let distance = null

    if (currentLocation) {
        distance = getDistance(
            {
                latitude: currentLocation.coords.latitude,
                longitude: currentLocation.coords.longitude
            },
            {latitude: facility.address.lat, longitude: facility.address.lon}
        );
    }

    return <div className='search-result-item'>
        <div className='d-flex flex-row my-2 p-2'>
            <div>
                <BusinessSliderItem businessId={business.id} key={business.id}/>
            </div>
            <div className='p-2 w-100'>
                {/*<ServiceTable facilityId={facility.id}/>*/}
            </div>
            <MdGpsFixed className='navigate-to-object' size={20} onClick={setCurrentFacility}/>
        </div>
    </div>
}

export default composeUtils(SearchResultTileFacility)
