import React from 'react';
import {injectIntl} from "react-intl";
import Slider from "react-slick";
import ImageGallery from 'react-image-gallery';
import {ReactComponent as LeftIcon} from '../../assets/icons/left.svg'
import missingImage from '../../assets/img/missing-image.svg'
import {getImageUrl, getMainImage} from "../../utils/facilityUtil";

let FacilityImageSlider = ({images}) => {

    let prepareImages = () => images.map(({image_id: id}) => {
        return {
            original: getImageUrl(id),
            thumbnail: getImageUrl(id, true),
        }
    })

    let renderLeftNav = (onClick, disabled) => {
        return <LeftIcon onClick={onClick} className='nav left-nav'/>
    }

    let renderRightNav = (onClick, disabled) => {
        return <LeftIcon onClick={onClick} className='nav right-nav'/>
    }

    if (!images.length) {
        return <div className='d-flex justify-content-center my-5'>
            <img src={missingImage}/>
        </div>
    }

    return <ImageGallery items={prepareImages(images)}
                         renderLeftNav={renderLeftNav}
                         renderRightNav={renderRightNav}
    />
}

export default FacilityImageSlider
