import React from 'react';
import {injectIntl} from "react-intl";
import {Col, Button} from 'reactstrap';
import {connect} from "react-redux";
import moment from 'moment';
import {DAY_SHORTCUTS, MONTH_NAMES} from "../constants/dateContants";
import {trans} from "../utils/translationUtil";
import {getFacilityDetails} from "../actions/facility";
import {confirmReservation} from "../actions/reservation";
import {getPriceText} from "../utils/appUtil";
import {useIntl} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import {composeUtils} from "../core/utils/appUtil";
import ReservationStatusField from "./reservation/ReservationStatusField";
import {BsFillCalendarCheckFill} from 'react-icons/bs'
import {RiArrowDownSLine} from 'react-icons/ri'
import {RESERVATION_STATUS_CANCELLED, WAITING_FOR_BUSINESS_APPROVAL} from "../core/constants/reservationStatuses";
import {
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

let ReservationTile = ({intl, dispatch, reservationId, finished=false}) => {

    let confirmReservation = () => dispatch(confirmReservation(reservationId))

    let reservation = useSelector(state => state.reservation.byId[reservationId])

    let facility = useSelector(state => state.facility.byId[reservation.facility])

    let service = useSelector(state => state.service.byId[reservation.service])

    let date = moment(reservation.starttime)

    return <Col lg={4} md={4} sm={6} className='reservation-tile'>
        <div className='reservation-content d-flex'>
            <div className='date d-flex align-items-center justify-content-center flex-column m-2 p-4'>
                <span className='day-shortcut'>{DAY_SHORTCUTS[date.day()]}</span>
                <span className='day fw-bold'>{date.format('D')}</span>
                <span className='month'>{MONTH_NAMES[date.format('M') - 1]}</span>
                <span className='time fw-bold'>{date.format('H:mm')}</span>
            </div>
            <div className='details m-2 d-flex flex-column flex-grow-1'>
                <ReservationStatusField reservationId={reservation.id}/>
                <span>Nr rez: {reservation.number}</span>
                <span>{facility?.name}</span>
                <span>{service?.name}</span>
                <span>
                    Cena:
                    <span className='fw-bold mx-1'>{reservation.price} PLN</span>
                </span>
                <span>
                    Czas trwania:
                    <span className='fw-bold mx-1'>{moment.duration(moment(reservation.endtime).diff(moment(reservation.starttime))).asMinutes()} min.</span>
                </span>
                <span className={'status confirmed'}>
                    {trans(intl, 'reservationstatus.confirmed')}
                </span>
            </div>
        </div>
    </Col>
}

export default composeUtils(ReservationTile)
