import {
    LOAD_FACILITIES_REQUEST,
    LOAD_FACILITIES_SUCCESS,
    LOAD_FACILITY_REQUEST,
    LOAD_FACILITY_SUCCESS,
    RESIZE_SEARCH_PAGINATION,
    SEARCH_SUCCESS
} from "../constants/actiontypes/facility";
import {LOAD_UPCOMING_RESERVATIONS_SUCCESS} from "../constants/actiontypes/reservation";
import {LOAD_FACILITY_TYPES_REQUEST, LOAD_FACILITY_TYPES_SUCCESS} from "../constants/actiontypes/facilityType";

var initialState = {
    byId: [],
    all: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.facilityType) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.facilityType
            }
        }
    }
    switch (action.type) {
        case LOAD_FACILITY_TYPES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...action.payload.entities.facilityType
                },
                all: action.payload.result
            }
        }
        default:
            return state;
    }
};