import {
    BACK_TO_RESERVATION_MODAL,
    CLOSE_RESERVATION_MODAL, OPEN_RESERVATION_CONFIRMATION, OPEN_RENTAL_RESERVATION_SUMMARY,
    OPEN_RESERVATION_MODAL,
    OPEN_RESERVATION_SUMMARY, RESERVATION_MODAL_FETCH_TERMS_REQUEST, RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    RESERVATION_MODAL_SELECT_PRICE_LIST,
    SELECT_DAY,
    SELECT_MONTH, SELECT_TERM
} from "../constants/actiontypes/reservation";
import moment from 'moment';

export const RENTAL_MODAL_OPEN = 'RENTAL_MODAL_OPEN';
export const RENTAL_MODAL_CLOSE = 'RENTAL_MODAL_CLOSE';
export const RENTAL_MODAL_SET_CYCLE = 'RENTAL_MODAL_SET_CYCLE';
export const RENTAL_MODAL_SET_STEP = 'RENTAL_MODAL_SET_STEP';
export const RENTAL_MODAL_SET_PAYMENT_METHOD = 'RENTAL_MODAL_SET_PAYMENT_METHOD';
export const RENTAL_MODAL_CREATE_RESERVATION = 'RENTAL_MODAL_CREATE_RESERVATION';
export const RENTAL_MODAL_SET_PRZELEWY24_PAYMENT_METHOD_ID = 'RENTAL_MODAL_SET_PRZELEWY24_PAYMENT_METHOD_ID';
export const RENTAL_MODAL_SUCCESS = 'RENTAL_MODAL_SUCCESS';
export const RENTAL_MODAL_SET_CYCLE_TYPE = 'RENTAL_MODAL_SET_CYCLE_TYPE';
export const RENTAL_MODAL_SET_ENDS_ON = 'RENTAL_MODAL_SET_ENDS_ON';
export const RENTAL_MODAL_SET_RECURRENCE_COUNT = 'RENTAL_MODAL_SET_RECURRENCE_COUNT';
export const RENTAL_MODAL_SET_DAYS_OF_WEEK = 'RENTAL_MODAL_SET_DAYS_OF_WEEK';
export const RENTAL_MODAL_SET_LOADING_TERMS = 'RENTAL_MODAL_SET_LOADING_TERMS';
export const RENTAL_MODAL_TERMS_LOADED = 'RENTAL_MODAL_TERMS_LOADED';

export const RENTAL_MODAL_STEP_SUMMARY = 'summary'
export const RENTAL_MODAL_STEP_PAYMENT = 'payment'
export const RENTAL_MODAL_STEP_SUCCESS = 'success'
export const RENTAL_MODAL_CASH = 'cash'
export const RENTAL_MODAL_PRZELEWY24 = 'przelewy24'

var initialState = {
    paymentMethod: RENTAL_MODAL_CASH,
    step: RENTAL_MODAL_STEP_SUMMARY,
    serviceId: null,
    facilityId: null,
    facilityPartId: null,
    date: null,
    duration: null,
    time_from: null,
    time_to: null,
    isCycle: false,
    submitting: false,
    przelewy24MethodId: null,
    cycle: {
        type: 'ends_on',
        endsOn: null,
        recurrenceCount: 5,
        intervalWeeks: 1,
        daysOfWeek: [],
        loadingTerms: false,
        terms: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RENTAL_MODAL_OPEN: {
            return {
                ...initialState,
                step: RENTAL_MODAL_STEP_SUMMARY,
                opened: true,
                isCycle: false,
                date: action.payload.date,
                time_from: action.payload.time_from,
                time_to: action.payload.time_to,
                serviceId: action.payload.serviceId,
                price: action.payload.price,
                facilityId: action.payload.facilityId,
                facilityPartId: action.payload.facilityPartId,
                duration: action.payload.duration,
                paymentMethod: RENTAL_MODAL_CASH,
                cycle: {
                    ...initialState.cycle,
                    endsOn: moment(action.payload.date).add(1, 'days').format('Y-MM-DD'),
                    daysOfWeek: [moment(action.payload.date).day()]
                }
            }
        }
        case RENTAL_MODAL_CLOSE: {
            return {
                ...state,
                opened: false
            }
        }
        case RENTAL_MODAL_SET_CYCLE: {
            return {
                ...state,
                isCycle: action.payload
            }
        }
        case RENTAL_MODAL_SET_STEP: {
            return {
                ...state,
                step: action.payload
            }
        }
        case RENTAL_MODAL_SET_PAYMENT_METHOD: {
            return {
                ...state,
                paymentMethod: action.payload
            }
        }
        case RENTAL_MODAL_CREATE_RESERVATION: {
            return {
                ...state,
                submitting: true
            }
        }
        case RENTAL_MODAL_SET_PRZELEWY24_PAYMENT_METHOD_ID: {
            return {
                ...state,
                przelewy24MethodId: action.payload
            }
        }
        case RENTAL_MODAL_SUCCESS: {
            return {
                ...state,
                opened: true,
                step: RENTAL_MODAL_STEP_SUCCESS
            }
        }
        case RENTAL_MODAL_SET_CYCLE_TYPE: {
            return {
                ...state,
                cycle: {
                    ...state.cycle,
                    type: action.payload
                }
            }
        }
        case RENTAL_MODAL_SET_RECURRENCE_COUNT: {
            return {
                ...state,
                cycle: {
                    ...state.cycle,
                    recurrenceCount: action.payload
                }
            }
        }
        case RENTAL_MODAL_SET_ENDS_ON: {
            return {
                ...state,
                cycle: {
                    ...state.cycle,
                    endsOn: action.payload
                }
            }
        }
        case RENTAL_MODAL_SET_DAYS_OF_WEEK: {
            return {
                ...state,
                cycle: {
                    ...state.cycle,
                    daysOfWeek: action.payload
                }
            }
        }
        case RENTAL_MODAL_SET_LOADING_TERMS: {
            return {
                ...state,
                cycle: {
                    ...state.cycle,
                    loadingTerms: true
                }
            }
        }
        case RENTAL_MODAL_TERMS_LOADED: {
            return {
                ...state,
                cycle: {
                    ...state.cycle,
                    loadingTerms: false,
                    terms: action.payload
                }
            }
        }
        default:
            return state;
    }
};