import {toastError} from "../utils/toastUtil";
import {createAction} from "../utils/reduxUtil";
import {
    getAllFacilities,
    getFacilitiesByIds,
    getFacilityById,
    getFacilityBySlug,
    getFacilityTypes
} from "../api/app";
import {normalize} from 'normalizr';

import {
    CHANGE_SEARCH_PAGE,
    LOAD_CLOSEST_FACILITIES_REQUEST,
    LOAD_CLOSEST_FACILITIES_SUCCESS,
    LOAD_FACILITIES_REQUEST,
    LOAD_FACILITIES_SUCCESS,
    LOAD_FACILITY_REQUEST,
    LOAD_FACILITY_SUCCESS,
    LOAD_OTHER_FACILITIES_REQUEST,
    LOAD_OTHER_FACILITIES_SUCCESS, RESIZE_SEARCH_PAGINATION,
    SEARCH_REQUEST, SEARCH_SUCCESS
} from "../constants/actiontypes/facility";
import schemas from "../reducers/schemas";
import {appError} from "./app";
import {LOAD_FACILITY_TYPES_REQUEST, LOAD_FACILITY_TYPES_SUCCESS} from "../constants/actiontypes/facilityType";
import handleError from "../utils/errorUtil";
import {SPORTS_FETCH_REQUEST} from "../reducers/sport";

export function loadFacilityTypes() {
    return function (dispatch) {
        dispatch(createAction(LOAD_FACILITY_TYPES_REQUEST));
        getFacilityTypes().then(response => {
            let normalizedData = normalize(response.data.data, [schemas.facilityTypeSchema]);
            dispatch(createAction(LOAD_FACILITY_TYPES_SUCCESS, normalizedData));
        }).catch(err => handleError(err, dispatch))
    }
}
