export const APP_LOADED = 'APP_LOADED';
export const APP_LOADING = 'APP_LOADING';
export const SET_LOGGED_USER = 'APP_SET_LOGGED_USER';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LOCATION = 'SET_LOCATION';
export const LOAD_LANGUAGES_REQUEST = 'LOAD_LANGUAGES_REQUEST';
export const LOAD_LANGUAGES_SUCCESS = 'LOAD_LANGUAGES_SUCCESS';
export const CHANGE_LANGUAGE_REQUEST = 'CHANGE_LANGUAGE_REQUEST';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const SET_MOBILE = 'SET_MOBILE';
export const APP_SET_BUSINESS = 'APP_SET_BUSINESS';