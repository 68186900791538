import {timeToInt, intToTime} from "./core/utils/appUtil";

export let getBusinessBySubdomain = (subdomain, state) => Object.keys(state.business.byId)
    .map(businessId => state.business.byId[businessId])
    .filter(business => state.app.business_id || business.client_global_app_enabled)
    .filter(business => business.subdomain === subdomain)[0]

export let getMinFacilityOpeningHourTime = (state, facilityId) => {
    let times = state.facility.byId[facilityId]?.openingHours.map(openingHour => openingHour.time_from2)
    if(!times?.length) {
        return '10:00';
    }
    return times.sort()[0]
}

export let getMaxFacilityOpeningHourTime = (state, facilityId) => {
    let times = state.facility.byId[facilityId]?.openingHours.map(openingHour => openingHour.time_to2)
    console.log('x', times)
    if(!times?.length) {
        return '21:00';
    }
    return times.sort()[times.length - 1]
}

export let getMinBusinessOpeningHourTime = (state, businessId) => {
    let times = state.business.byId[businessId]?.openingHours.map(openingHour => openingHour.time_from2)
    if(!times?.length) {
        return '10:00';
    }
    return times.sort()[0]
}

export let getMaxBusinessOpeningHourTime = (state, businessId) => {
    let times = state.business.byId[businessId]?.openingHours.map(openingHour => openingHour.time_to2)
    if(!times?.length) {
        return '21:00';
    }
    return times.sort()[times.length - 1]
}

export let getReservationTimes = (state, businessId, facilityId, interval, duration) => {
    let minTime = facilityId ? getMinFacilityOpeningHourTime(state, facilityId) : getMinBusinessOpeningHourTime(state, businessId)
    let maxTime = facilityId ? getMaxFacilityOpeningHourTime(state, facilityId) : getMaxBusinessOpeningHourTime(state, businessId)

    let times = []
    while(minTime < maxTime) {
        times.push({
            from: minTime,
            to: intToTime(timeToInt(minTime) + interval)
        })
        minTime = intToTime(timeToInt(minTime) + interval)
    }
    return times;
}