export const RESERVATION_STATUS_CONFIRMED = 'CONFIRMED';
export const RESERVATION_STATUS_CANCELLED = 'CANCELLED';

export const CONFIRMED = 'CONFIRMED';
export const WAITING_FOR_BUSINESS_APPROVAL = 'WAITING_FOR_BUSINESS_APPROVAL';
export const CANCELLED = 'CANCELLED';
export const FINISHED = 'FINISHED';
export const REJECTED = 'REJECTED';

export const AVAILABILITY_TYPE_ALL_THE_TIME = 'ALL_THE_TIME';
export const AVAILABILITY_TYPE_CUSTOM = 'CUSTOM';

export const RESERVATION_STATUSES = [
    WAITING_FOR_BUSINESS_APPROVAL,
    CONFIRMED,
    CANCELLED,
    FINISHED,
    REJECTED
]

export const RESERVATION_STATUS_BACKGROUND_COLOR = {
    CANCELLED: '#FFEBEB',
    CONFIRMED: '#EDFDFC',
    WAITING_FOR_BUSINESS_APPROVAL: '#FFF8E1',
    FINISHED: '#DCE8FF',
    REJECTED: '#FFE7C2'
}

export const RESERVATION_STATUS_COLOR = {
    CANCELLED: '#E81515',
    CONFIRMED: '#11DFA1',
    WAITING_FOR_BUSINESS_APPROVAL: '#EFB400',
    FINISHED: '#155CE7',
    REJECTED: '#EB8116'
}

export const AVAILABLE_RESERVATION_STATUSES = {
    CONFIRMED: [
        CONFIRMED,
        WAITING_FOR_BUSINESS_APPROVAL,
        CANCELLED,
        FINISHED,
        REJECTED
    ],
    WAITING_FOR_BUSINESS_APPROVAL: [
        CONFIRMED,
        WAITING_FOR_BUSINESS_APPROVAL,
        CANCELLED,
        FINISHED,
        REJECTED
    ],
    CANCELLED: [
        CONFIRMED,
        WAITING_FOR_BUSINESS_APPROVAL,
        CANCELLED,
        FINISHED,
        REJECTED
    ],
    FINISHED: [
        CONFIRMED,
        WAITING_FOR_BUSINESS_APPROVAL,
        CANCELLED,
        FINISHED,
        REJECTED
    ],
    SETTLED: [
        CONFIRMED,
        WAITING_FOR_BUSINESS_APPROVAL,
        CANCELLED,
        FINISHED,
        REJECTED
    ]
}