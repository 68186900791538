import {
    CHANGE_CURRENT_OBJECT,
    CHANGE_PLACE_TEXT,
    CHANGE_SEARCH_TEXT, LOAD_MORE_REULTS, LOAD_MORE_REULTS_REQUEST,
    PLACES_AUTOCOMPLETE_REQUEST,
    PLACES_AUTOCOMPLETE_SUCCESS,
    RESIZE_SEARCH_PAGINATION,
    SEARCH_REQUEST,
    SEARCH_SUCCESS,
    SELECT_PLACE,
    SEARCH_SET_PER_PAGE,
    SEARCH_SET_PAGE, SEARCH_SET_DISTANCE, SEARCH_CHANGE_SORT
} from "../constants/actiontypes/facility";
import {SEARCH_REMOVE_FILTER_FACILITY_TYPE, SEARCH_SET_FILTER_FACILITY_TYPE} from "../constants/actiontypes/facilityType";

var initialState = {
    loading: false,
    per_page: 5,
    page: 1,
    search_text: null,
    search_text_input: null,
    place: 'Rzeszów, Polska',
    result: [],
    selected_object: null,
    facility_type: null,
    distance: 5,
    loaded: false,
    sort: 'id',
    searchParams: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_SET_PAGE: {
            return {
                ...state,
                page: action.payload
            }
        }
        case SEARCH_SET_PER_PAGE: {
            return {
                ...state,
                per_page: action.payload
            }
        }
        case SEARCH_REQUEST: {
            return {
                ...state,
                loading: true,
                result: [],
                searchParams: action.payload
                // search_text: action.payload.search_text,
                // place: action.payload.place,
                // distance: action.payload.distance,
                // facility_type: action.payload.facility_type
            }
        }
        case SEARCH_SUCCESS: {
            return {
                ...state,
                loaded: true,
                loading: false,
                result: action.payload.result,
                search_text_input: null
            }
        }
        case CHANGE_CURRENT_OBJECT: {
            return {
                ...state,
                selected_object: action.payload
            }
        }
        case SEARCH_SET_FILTER_FACILITY_TYPE: {
            return {
                ...state,
                facility_type: action.payload
            }
        }
        case SEARCH_REMOVE_FILTER_FACILITY_TYPE: {
            return {
                ...state,
                facility_type: null
            }
        }
        case SEARCH_SET_DISTANCE: {
            return {
                ...state,
                distance: action.payload
            }
        }
        case SEARCH_CHANGE_SORT: {
            return {
                ...state,
                sort: action.payload
            }
        }
        default:
            return state;
    }
};