import * as types from '../constants/actiontypes/login';
import {setLoggedUser} from './app';
import api from '../core/api';
import {LOGGED_IN} from "../constants/actiontypes/login";
import {toastError, toastSuccess} from "../utils/toastUtil";
import {createAction} from "../utils/reduxUtil";
import handleError from "../utils/errorUtil";

export function logout(navigate, intl) {
    return function (dispatch) {
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        dispatch(setLoggedUser(null));
        dispatch({type: types.LOGOUT});
        toastSuccess('Wylogowano!', intl);
        navigate('/');
    };
}

export async function submitLogin(dispatch, formData) {
    return await api.auth.loginClient(formData.email, formData.password)
        .then((response) => {
        let data = response.data.data;
        if (data.access_token) {
            localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data.access_token);
            localStorage.setItem(process.env.REACT_APP_CLIENT_ID_NAME, data.client.id);
            dispatch(setLoggedUser(data.client));
            dispatch(createAction(LOGGED_IN));
            toastSuccess('Zalogowano');
        } else {
            return {password: 'Złe hasło'};
        }
    }).catch(err => {
        if(err?.response?.data?.error) {
            return {
                password: 'Zły adres email lub hasło'
            }
        }
        return handleError(err, dispatch)
    })
}

export function register(dispatch, formData) {
    let formAgreements = [];
    formData.agreements = formAgreements;
    // formData.language_id = languageId;
    formData.type = 'client';
    return api.auth.register(formData).then((response) => {
        let data = response.data.data;
        localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data.access_token);
        dispatch(setLoggedUser(data.client));
        dispatch(createAction(LOGGED_IN));
        toastSuccess('Zarejestrowano');
    }).catch(err => {
        return err.response.data.errors;
        // toastError('Wystąpił błąd');
    });
}