import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import {createAction} from "../../utils/reduxUtil";
import {
    CLOSE_RESERVATION_MODAL,
    OPEN_RESERVATION_MODAL,
    OPEN_RESERVATION_SUMMARY,
    RESERVATION_MODAL_FETCH_TERMS_REQUEST,
    RESERVATION_MODAL_FETCH_TERMS_SUCCESS,
    RESERVATION_MODAL_SELECT_PRICE_LIST,
    SELECT_DAY,
    SELECT_TERM
} from "../../constants/actiontypes/reservation";
import {makeReservation, selectMonth} from "../../actions/reservation";
import Slider from "react-slick";
import {addLeadingZeros} from "../../utils/stringUtil";
import {DAY_SHORTCUTS, MONTH_NAMES} from "../../constants/dateContants";
import moment from 'moment';
import {dateFormat} from "../../utils/dateUtil";
import {getPriceText} from "../../utils/appUtil";
import {injectIntl, useIntl} from "react-intl";
import Button from '../../core/buttons/Button'
import calendarImage from '../../assets/img/calendar.svg'
import Terms from './Terms'
import MonthSlider from './MonthSlider'
import DaySlider from './DaySlider'
import ServiceInfo from './ServiceInfo'
import {useNavigate} from "react-router-dom";
import ModalForm from "../../core/modal/ModalForm";
import {trans} from "../../core/utils/translationUtil";
import backImage from "../../assets/img/arrow-left.svg";
import reservationConfirmationImage from '../../assets/img/reservation_confirmation.svg'
import Loader from "../Loader";
import FacilityImage from '../facility/FacilityImage'
import SelectInput from "../../core/form/select/SelectInput";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getTerms} from "../../api/app";

let ReservationModal = () => {
    let dispatch = useDispatch()

    let navigate = useNavigate()

    let intl = useIntl()

    let {
        price,
        modal_opened: modalOpened,
        current_term: currentTerm,
        current_day: currentDay,
        current_service: serviceId,
        confirmation,
        summary,
        loadingTerms,
        priceListId
    } = useSelector(state => state.reservationModal)

    let close = () => dispatch(createAction(CLOSE_RESERVATION_MODAL))

    let isMobile = useSelector(state => state.app.mobile)

    let reserve = () => dispatch(makeReservation())

    let service = useSelector(state => state.service.byId[state.reservationModal.current_service])

    let goToSummary = () => dispatch(createAction(OPEN_RESERVATION_SUMMARY))

    let changePriceList = (e) => dispatch(createAction(RESERVATION_MODAL_SELECT_PRICE_LIST, e.target.value))

    React.useEffect(() => {
        if(serviceId && priceListId && currentDay) {
            dispatch(createAction(RESERVATION_MODAL_FETCH_TERMS_REQUEST));
            getTerms(serviceId, priceListId, currentDay).then((response) => {
                let terms = response.data;
                dispatch(createAction(RESERVATION_MODAL_FETCH_TERMS_SUCCESS, {terms}));
            })
        }
    }, [serviceId, priceListId, currentDay])

    let renderForm = () => {
        if (confirmation) {
            return <div className='d-flex justify-content-center flex-column confirmation'>
                <div>Rezerwacja została pomyślnie potwierdzona!</div>
                <img src={reservationConfirmationImage} className='mb-5' style={{height: '300px'}}/>
            </div>
        }
        if (summary) {
            return <>
                {isMobile && <div className='header'>
                    <img src={backImage} onClick={close}/>
                    <span>Podsumowanie</span>
                </div>}
                <div className='summary'>
                    <div className='d-flex flex-column tabledata mt-3 mb-5'>
                        <div className='tablerow d-flex flex-row justify-content-between p-2'>
                            <div>Usługa</div>
                            <div>{service?.name}</div>
                        </div>
                        <div className='tablerow d-flex flex-row justify-content-between p-2'>
                            <div>Czas trwania</div>
                            <div
                                className='fw-bold'>{(currentTerm.to.hour * 60 + parseInt(currentTerm.to.minute)) - (currentTerm.from.hour * 60 + parseInt(currentTerm.from.minute))} min.
                            </div>
                        </div>
                        <div className='tablerow d-flex flex-row justify-content-between align-items-center p-2'>
                            <div>Termin</div>
                            <div className='d-flex flex-column align-items-end'>
                                <div>
                                    {moment(currentDay).format('DD MMMM YYYY r.')}
                                </div>
                                <div className='fw-bold'>
                                    {currentTerm.from.hour}:{addLeadingZeros(currentTerm.from.minute, 2)} - {currentTerm.to.hour}:{addLeadingZeros(currentTerm.to.minute, 2)}
                                </div>
                            </div>
                        </div>
                        <div className='tablerow d-flex flex-row justify-content-between p-2'>
                            <div>Cena</div>
                            <div className='fw-bold'>{currentTerm.price} PLN</div>
                        </div>
                    </div>
                </div>
            </>
        }
        return <>
            {isMobile && <div className='header'>
                <img src={backImage} onClick={close}/>
                <span>Nowa rezerwacja</span>
            </div>}
            <ServiceInfo/>
            {
                service?.priceLists.length > 1 && <Select name='price_list_id' value={priceListId} onChange={changePriceList}>
                    {
                        service.priceLists.map(priceList => <MenuItem value={priceList.id}>{priceList.name}</MenuItem>)
                    }
                </Select>
            }
            <MonthSlider/>
            <DaySlider/>
            {loadingTerms && <Loader/>}
            {!loadingTerms && <Terms/>}
        </>
    }

    let getSubmitText = () => {
        if (confirmation) {
            return 'Zamknij'
        }
        if (summary) {
            return 'Zarezerwuj usługę'
        }
        return 'Przejdź do podsumowania'
    }

    let getTitle = () => {
        if (confirmation) {
            return ''
        }
        return summary ? 'Podsumowanie' : 'Nowa rezerwacja'
    }

    let submit = () => {
        if (confirmation) {
            close()
            navigate('/rezerwacje')
        }
        if (!summary) {
            return goToSummary()
        }
        return reserve()
    }

    return <ModalForm
        isOpen={modalOpened}
        closeModal={close}
        title={getTitle()}
        onSubmit={submit}
        renderForm={renderForm}
        submitText={getSubmitText()}
        className={'reservation-modal ' + (isMobile ? 'mobile' : '')}
        cancelButton={false}
    />
}

export default ReservationModal
