import React from 'react'
import {loadFacilityTypes} from "../../actions/facilityType";
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {createAction} from "../../core/utils/appUtil";
import {SEARCH_SET_FILTER_FACILITY_TYPE} from "../../constants/actiontypes/facilityType";

let FacilityTypesPanel = () => {

    let dispatch = useDispatch()

    let navigate = useNavigate()

    React.useEffect(() => {
        dispatch(loadFacilityTypes())
    }, [])

    let {all, byId} = useSelector(state => state.facilityType)

    let openFacilityType = facilityTypeId => {
        dispatch(createAction(SEARCH_SET_FILTER_FACILITY_TYPE, facilityTypeId))
        navigate('/szukaj?type=rental&facilityType=' + facilityTypeId)
    }

    return <div className='d-flex justify-content-around facility-types-list py-4 px-5'>
        {
            all.map(facilityTypeId => <div className='d-flex justify-content-center align-items-center text-center px-2' key={facilityTypeId}>
                <div onClick={() => openFacilityType(facilityTypeId)} style={{cursor: 'pointer'}}>
                    {
                        byId[facilityTypeId].name
                    }
                </div>
            </div>)
        }
    </div>

}

export default FacilityTypesPanel