import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import CardForm from "../../core/form/CardForm";
import TextInput from "../../core/form/TextInput";
import FormHeader from '../../core/form/FormHeader'
import SwitchInput from '../../core/form/SwitchInput'
import {LOAD_PROFILE_SUCCESS} from "../../constants/actiontypes/profile";
import {toastSuccess} from '../../core/utils/appUtil'

let AccountForm = function () {

    let user = useSelector(state => state.app.logged_user);

    let dispatch = useDispatch();

    let renderForm = (form) => <>
        <FormHeader text='Informacje podstawowe'/>
        <TextInput {...form} name='fullname' required={true} label='app.fullname'/>
        <TextInput {...form} name='email' required={true} label='app.email'/>
        <TextInput {...form} mask='+48 999 999 999' name='phone' required={true} label='app.phone'/>
        <FormHeader text='Ustawienia powiadomień'/>
        <SwitchInput {...form} name='notification_email' label='app.notification_email'/>
    </>

    let onSubmit = (data) => {
        dispatch(createAction(LOAD_PROFILE_SUCCESS, data.data))
    }

    return <CardForm
        renderForm={renderForm}
        data={user}
        getPromise={(id, data) => api.client.update(data, id)}
        onSubmit={onSubmit}
    />
}

export default AccountForm