import React from 'react';
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {BiCurrentLocation} from 'react-icons/bi';
import {BsCalendarFill} from 'react-icons/bs';
import {AiOutlineHeart, AiOutlineCalendar, AiOutlineSearch} from 'react-icons/ai';
import {withRouter} from 'react-router-dom';
import avatar from "../../assets/img/avatar.jpg";
import {FaAngleUp} from 'react-icons/fa';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button
} from 'reactstrap';
import {NotificationIcon} from '../../assets/icons/notifications-icon.svg';
import {CHANGE_LANGUAGE_REQUEST} from "../../constants/actiontypes/app";
import {createAction} from "../../utils/reduxUtil";
import {trans} from "../../utils/translationUtil";
import {OPEN_LOGIN_MODAL, OPEN_REGISTER_MODAL} from "../../constants/actiontypes/login";
import img from '../../assets/img/header.jpg';
import {CHANGE_SEARCH_TEXT} from "../../constants/actiontypes/facility";
import NotificationDropdown from "./../notification/NotificationDropdown";
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import SearchForm from './SearchForm'
import FacilityTypesPanel from './FacilityTypesPanel'
import logo from '../../assets/img/logo_white.svg'
import * as QueryString from "query-string";

let MobileHeader = ({skipSearchBar}) => {

    let navigate = useNavigate()

    return <header className='mobile'>
        <div onClick={() => navigate('/')}>
            <img src={logo} style={{height: '26px'}} onClick={() => navigate('/')}/>
        </div>
    </header>

}

export default MobileHeader
