export const OPEN_RESERVATION_MODAL = 'OPEN_RESERVATION_MODAL';
export const CLOSE_RESERVATION_MODAL = 'CLOSE_RESERVATION_MODAL';
export const RESERVATION_MODAL_FETCH_TERMS_REQUEST = 'RESERVATION_MODAL_FETCH_TERMS_REQUEST';
export const RESERVATION_MODAL_FETCH_TERMS_SUCCESS = 'RESERVATION_MODAL_FETCH_TERMS_SUCCESS';
export const OPEN_RESERVATION_SUMMARY = 'OPEN_RESERVATION_SUMMARY';
export const BACK_TO_RESERVATION_MODAL = 'BACK_TO_RESERVATION_MODAL';
export const OPEN_RESERVATION_CONFIRMATION = 'OPEN_RESERVATION_CONFIRMATION';
export const SELECT_MONTH = 'SELECT_MONTH';
export const SELECT_DAY = 'SELECT_DAY';
export const SELECT_TERM = 'SELECT_TERM';
export const RESERVATION_MODAL_SELECT_PRICE_LIST = 'RESERVATION_MODAL_SELECT_PRICE_LIST'
export const LOAD_UPCOMING_RESERVATIONS_REQUEST = 'LOAD_UPCOMING_RESERVATIONS_REQUEST';
export const LOAD_UPCOMING_RESERVATIONS_SUCCESS = 'LOAD_UPCOMING_RESERVATIONS_SUCCESS';
export const LOAD_FINISHED_RESERVATIONS_REQUEST = 'LOAD_FINISHED_RESERVATIONS_REQUEST';
export const LOAD_FINISHED_RESERVATIONS_SUCCESS = 'LOAD_FINISHED_RESERVATIONS_SUCCESS';
export const CONFIRM_RESERVATION_SUCCESS = 'CONFIRM_RESERVATION_SUCCESS';
export const RESERVATION_CANCEL_SUCCESS = 'RESERVATION_CANCEL_SUCCESS';
export const RESERVATION_UPDATE_SUCCESS = 'RESERVATION_UPDATE_SUCCESS';
export const OPEN_RENTAL_RESERVATION_SUMMARY = 'OPEN_RENTAL_RESERVATION_SUMMARY';