import {
    LOAD_FACILITIES_SUCCESS,
    LOAD_FACILITY_SUCCESS, LOAD_MORE_REULTS,
    RESIZE_SEARCH_PAGINATION,
    SEARCH_SUCCESS
} from "../constants/actiontypes/facility";

export const BUSINESS_LIST_REQUEST = 'BUSINESS_LIST_REQUEST';
export const BUSINESS_LIST_SUCCESS = 'BUSINESS_LIST_SUCCESS';
export const BUSINESS_FETCH_REQUEST = 'BUSINESS_FETCH_REQUEST';
export const BUSINESS_FETCH_SUCCESS = 'BUSINESS_FETCH_SUCCESS';
export const BUSINESS_SEARCH_REQUEST = 'BUSINESS_SEARCH_REQUEST';
export const BUSINESS_SEARCH_SUCCESS = 'BUSINESS_SEARCH_SUCCESS';

var initialState = {
    loading: false,
    byId: [],
    allIds: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.business) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.business
            }
        }
    }
    switch (action.type) {
        case BUSINESS_FETCH_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case BUSINESS_FETCH_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case BUSINESS_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case BUSINESS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                allIds: action.payload.result
            }
        default:
            return state;
    }
};