import React, {useRef, useState, useEffect} from 'react'
import {Marker, Popup} from 'react-leaflet'

const MapMarker = ({lat, lon, icon, openPopup = false, children}) => {

    const markerRef = useRef(null)

    useEffect(() => {
        if (openPopup)
            markerRef.current.openPopup();
    }, [openPopup])

    return <Marker ref={markerRef} icon={icon} position={[lat, lon]}>
        <Popup>
            {children}
        </Popup>
    </Marker>
}

export default MapMarker