import React from 'react';
import {loadClosestFacilities, loadProposedFacilities} from "../actions/facility";
import {trans} from "../utils/translationUtil";
import {injectIntl} from "react-intl";
import Loader from "../components/Loader";
import {loadFacilityTypes} from "../actions/facilityType";
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import Button from '../core/buttons/Button'
import inviteObject from '../assets/img/invite_object.svg'
import partnerImage from '../assets/img/partner.svg'
import card1 from '../assets/img/card1.svg'
import {BiChevronRight} from 'react-icons/bi'
import Cards from '../components/home/Cards'
import ObjectPage from '../core/components/objectpage/ObjectPage'
import {logout} from "../actions/login"
import {useNavigate} from 'react-router-dom'
import {FiLogOut} from 'react-icons/fi'

let AccountPage = ({children}) => {

    let navigate = useNavigate()

    let intl = useIntl()

    let pages = [
        {
            name: 'Rezerwacje',
            path: '/rezerwacje',
        },
        {
            name: 'Ustawienia konta',
            path: '/konto',
        }
    ]

    let dispatch = useDispatch()

    let onLogout = () => {
        dispatch(logout(navigate, intl))
    }

    return <ObjectPage pages={pages}
                       name='account'
                       onRemove={onLogout}
                       removeText='app.logout'
                        removeIcon={FiLogOut}
    >
        {children}
    </ObjectPage>

}

export default AccountPage