import React, {useState} from 'react'
import Loader from "../Loader";
import {createAction} from '../../utils/reduxUtil'
import {useSelector, useDispatch} from 'react-redux'
import Button from "../../core/buttons/Button";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import {RENTAL_CALENDAR_CHANGE_DURATION} from "../../reducers/rentalcalendar";

let DurationSelect = () => {

    let dispatch = useDispatch()

    let {loading, duration, serviceId} = useSelector(state => state.rentalcalendar)

    let service = useSelector(state => state.service.byId[state.rentalcalendar.serviceId])

    return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 0}} className='navigation'>
        {
            service?.price.times.map(time => <div>
                <Button text={time + ' min.'}
                        extraClasses={duration === time ? 'secondary' : 'primary'}
                        onClick={() => dispatch(createAction(RENTAL_CALENDAR_CHANGE_DURATION, {duration: time}))}/>
            </div>)
        }
    </div>
}

export default DurationSelect