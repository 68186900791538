import React from "react";
import {Spinner} from "reactstrap";
import SearchTextField from "./header/SearchTextField";
import {useForm} from "react-hook-form";
import * as QueryString from "query-string";
import TextField from "@mui/material/TextField";
import FormWrapper from "../core/form/FormWrapper";
import {ReactComponent as SearchIcon} from "../assets/icons/search.svg";
import TextInput from "../core/form/TextInput";
import MobileSearchModal from '../components/search/ModileSearchModal'
import {useDispatch} from "react-redux";
import {createAction} from "../utils/reduxUtil";
import {APP_OPEN_SEARCH_MODAL} from '../reducers/app'
import {useLocation} from "react-router-dom";

const MobileSearch = () => {

    let location = useLocation()

    let params = QueryString.parse(location.search)

    let p = []

    if(params.text) {
        p.push(params.text)
    }
    if(params.place) {
        p.push(params.place)
    }

    let form = useForm({
        defaultValues: {
            search_text: p.join(',')
        }
    })

    let dispatch = useDispatch()

    let [isOpen, setIsOpen] = React.useState(false)

    let openSearchModal = () => {
        dispatch(createAction(APP_OPEN_SEARCH_MODAL, true))
    }

    return <div className='mt-5 px-5 search-form'>
        <MobileSearchModal/>
        <FormWrapper>
            <TextInput {...form} name='search_text' label='Wyszukaj obiekt' Icon={SearchIcon} onClickInput={openSearchModal} disabled={true}/>
        </FormWrapper>
    </div>
}

export default MobileSearch;
