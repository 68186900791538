import React from 'react';
import {withRouter, useMatch, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getFacilityDetails} from "../actions/facility";
import {Row, Col} from 'reactstrap';
import FacilityImageSlider from "../components/facility/FacilityImageSlider";
import {injectIntl} from "react-intl";
import moment from 'moment';
import {initReservationModal} from "../actions/reservation";
import {DAYS} from "../constants/days";
import {trans} from "../utils/translationUtil";
import {addLeadingZeros} from "../utils/stringUtil";
import {CgWebsite} from 'react-icons/cg';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {BiPhone} from 'react-icons/bi';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconMe from '../assets/img/marker-me.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import Loader from "../components/Loader";
import ReservationModal from "../components/reservation/ReservationModal";
import {getDistance} from 'geolib';
import {getPriceText, getServicePriceText} from "../utils/appUtil";
import ReserveButton from "../components/buttons/ReserveButton";
import {useIntl} from 'react-intl'
import MarkerMe from '../components/map/markers/MarkerMe'
import Distance from './../components/facility/Distance'
import Map from '../components/facility/Map'
import ServicesTable from '../components/facility/ServiceTable'
import OpeningHours from '../components/facility/OpeningHours'
import {composeUtils} from "../core/utils/appUtil";
import Socials from '../components/facility/Socials'
import Address from '../components/facility/Address'
import OtherFacilities from '../components/facility/OtherFacilities'
import {getBusinessBySubdomain} from "../selectors";
import {getMainImage} from "../utils/facilityUtil";
import RentalCalendar from "../components/rentalcalendar/RentalCalendar";
import {ReactComponent as ArrowIcon} from '../assets/icons/arrow-right.svg'
import Button from "../core/buttons/Button";
import {createAction} from "../utils/reduxUtil";
import {RENTAL_CALENDAR_RESET} from "../reducers/rentalcalendar";

let BusinessTrainingsPage = ({intl, dispatch}) => {

    let {business_id: businessId} = useSelector(state => state.app)
    let business = null
    let params = useParams();

    if (!businessId) {
        business = useSelector(state => getBusinessBySubdomain(params.business, state))
        businessId = business?.id
    } else {
        business = useSelector(state => state.business.byId[businessId])
    }

    let allServices = useSelector(state => state.service.byId)

    let services = business.services
        ?.map(serviceId => allServices[serviceId])
        .filter(service => service.type !== 'partial-rental')
        .filter(service => service.type !== 'entire-rental')
        .filter(service => service.price.min !== null && service.price.max !== null)

    let [serviceId, setServiceId] = React.useState(null)

    let selectService = service => {
        setServiceId(service.id)
        dispatch(createAction(RENTAL_CALENDAR_RESET, {
            businessId: businessId,
            facilityId: null,
            serviceId: service.id,
            date: moment().format('YYYY-MM-DD'),
            start: moment().format('YYYY-MM-DD'),
            end: moment().add(6, 'days').format('YYYY-MM-DD'),
            duration: service.price.times[0]
        }))
    }

    return <div className='trainings-page'>
        <div className='heading'>
            <span>
                Zarezerwuj usługę
            </span>
        </div>
        <table className='service-list'>
            {
                !services.length && <div style={{textAlign: 'center'}}>BRAK DOSTĘPNYCH USŁUG</div>
            }
            {
                services
                    .map(service => {
                        let minTime = Math.min(...service.price.times)
                        let maxTime = Math.max(...service.price.times)
                        return <>
                            <div className='service-item'>
                                <div width='100%'>
                                    {service.name}
                                </div>
                                <div width='200px'>
                                    <div className='price'>{getServicePriceText(service.price)}</div>
                                    <div
                                        className='duration'>{minTime === maxTime ? minTime : (minTime + '-' + maxTime)} min.
                                    </div>
                                </div>
                                <div>
                                    <Button extraClasses='btn-reserve'
                                            onClick={() => selectService(service)}
                                            Icon={ArrowIcon}
                                            rightIcon={true}
                                            text='Rezerwuj'/>
                                </div>
                            </div>
                            <div className='divider'/>
                        </>
                    }
                )
            }
        </table>
        <ReservationModal/>
        {serviceId && <RentalCalendar/>}
    </div>
}

export default composeUtils(BusinessTrainingsPage)