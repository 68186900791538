export const LOAD_FACILITIES_REQUEST = 'LOAD_FACILITIES_REQUEST';
export const LOAD_FACILITIES_SUCCESS = 'LOAD_FACILITIES_SUCCESS';
export const LOAD_FACILITY_REQUEST = 'LOAD_FACILITY_REQUEST';
export const LOAD_FACILITY_SUCCESS = 'LOAD_FACILITY_SUCCESS';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const CHANGE_PLACE_TEXT = 'CHANGE_PLACE_TEXT';
export const SELECT_PLACE= 'SELECT_PLACE';
export const PLACES_AUTOCOMPLETE_REQUEST= 'PLACES_AUTOCOMPLETE_REQUEST';
export const PLACES_AUTOCOMPLETE_SUCCESS= 'PLACES_AUTOCOMPLETE_SUCCESS';
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT';
export const LOAD_MORE_REULTS = 'LOAD_MORE_REULTS';
export const RESIZE_SEARCH_PAGINATION = 'RESIZE_SEARCH_PAGINATION';
export const LOADING_FACILITIES = 'LOADING_FACILITIES';
export const CHANGE_CURRENT_OBJECT = 'CHANGE_CURRENT_OBJECT';
export const LOAD_MORE_REULTS_REQUEST = 'LOAD_MORE_REULTS_REQUEST';
export const SEARCH_SET_PAGE = 'FACILITY_SET_PAGE';
export const SEARCH_SET_PER_PAGE = 'FACILITY_SET_PER_PAGE';
export const SEARCH_SET_DISTANCE = 'SEARCH_SET_DISTANCE';
export const SEARCH_CHANGE_SORT = 'SEARCH_CHANGE_SORT';