import {addLeadingZeros} from "./stringUtil";
import {FIXED, FREE, INDIVIDUAL, RANGE, VARIABLE} from "../constants/priceTypes";
import {trans, translationExists} from "./translationUtil";
import {toast} from "react-toastify"
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

export function createAction(action, payload = null) {
    return {type: action, payload: payload}
}

export function toastSuccess(text, intl = null) {
    if (intl && translationExists(intl, text)) {
        text = trans(intl, text)
    }
    toast.success(text, {hideProgressBar: true});
}

export function toastError(text, intl = null) {
    if (intl && translationExists(intl, text)) {
        text = trans(intl, text)
    }
    toast.error(text, {hideProgressBar: true});
}

export function pad(num, size = 2) {
    var s = "0" + num;
    return s.substr(s.length - size);
}

export function timeToString({hour, minute}) {
    return addLeadingZeros(hour) + ':' + addLeadingZeros(minute) + ':00';
}

export function momentTimeToInt(date) {
    return Math.floor((parseInt(date.format('HH')) * 60 + parseInt(date.format('mm'))) / 5) * 5
}

export function intToTime(time) {
    return addLeadingZeros(Math.floor(time / 60)) + ':' + addLeadingZeros(time % 60)
}

export function timeToInt(time) {
    if(!time) return
    let [hour, minute] = time.split(':')
    return parseInt(hour) * 60 + parseInt(minute)
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function uniqueArray(arr) {
    return arr.filter(onlyUnique)
}

export function formatInterval(time) {
    let text = '';
    let hour = Math.floor(time / 60);
    if (hour) {
        text = hour + ' godz. '
    }
    if (time % 60) {
        text += (time % 60) + ' min.'
    }
    return text
}


export function getPriceText(price, intl) {
    if (!price?.type) {
        return '-'
    }
    if (price.type === FIXED) {
        return (price.value) + " PLN"
    }
    if ([FREE, INDIVIDUAL].includes(price.type)) {
        return trans(intl, 'form.price.type_' + price.type)
    }
    if (price.type === RANGE) {
        return price.from + ' - ' + price.to + " PLN"
    }
    if (price.type === VARIABLE) {
        if (price.value) {
            return price.value + " PLN"
        }
        return price.from + ' - ' + price.to + " PLN"
    }
    return '-';
}


export let increaseTime = time => {
    return {
        hour: time.minute === 55 ? time.hour + 1 : time.hour, minute: time.minute === 55 ? 0 : time.minute + 5
    }
}
export let decreaseTime = time => {
    return {
        hour: time.minute === 0 ? time.hour - 1 : time.hour, minute: time.minute === 0 ? 55 : time.minute - 5
    }
}

export function hasPermissionTo(permission, user = {}) {
    return user.master_account || user.permissions.includes(permission);
}

export function composeUtils(component) {
    return connect(null, null)(
        injectIntl(
            component
        )
    )
}