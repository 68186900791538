import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import tennisIcon from '../assets/icons/map/tennis.svg'
import footballIcon from '../assets/icons/map/football.svg'
import basketballIcon from '../assets/icons/map/basketball.svg'
import gocartIcon from '../assets/icons/map/gocart.svg'
import fitnessIcon from '../assets/icons/map/fitness.svg'
import volleyballIcon from '../assets/icons/map/voleyball.svg'
import climbingIcon from '../assets/icons/map/climbing.svg'
import swimmingIcon from '../assets/icons/map/swimming.svg'
import rehabilitationIcon from '../assets/icons/map/rehabilitation.svg'
import skateparkIcon from '../assets/icons/map/skatepark.svg'
import bowlingIcon from '../assets/icons/map/bowling.png'
import squashIcon from '../assets/icons/map/squash.png'

let DefaultIcon = L.icon({
    iconUrl: icon,
    // shadowUrl: iconShadow
});

let iconMap = {
    'sport-hall': volleyballIcon,
    'tennis-court': tennisIcon,
    'sport-field': footballIcon,
    'go-cart': gocartIcon,
    'squash': squashIcon,
    'fitness-studio': fitnessIcon,
    'rope-park': climbingIcon,
    'swimming-pool': swimmingIcon,
    'bowling-club': bowlingIcon,
    'skatepark': skateparkIcon,
    'gym': fitnessIcon
}

let getIcon = function (facilityType, icon = true) {
    return DefaultIcon
    let image = Object.keys(iconMap).includes(facilityType.code) ? iconMap[facilityType.code] : DefaultIcon

    if (!icon) {
        return image
    }
    return new L.Icon({
        iconUrl: image,
        iconSize: new L.Point(22, 22)
    });
}

export default getIcon;