import {createAction} from "./appUtil";
const SHOW_ERROR = 'SHOW_ERROR'
const LOGOUT = 'LOGOUT'
import {toastError} from "./appUtil";

export function sessionExpired() {
    return function (dispatch) {
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        dispatch(createAction(LOGOUT));
        toastError('Sesja wygasła');
    }
}

let handleError = (error, dispatch) => {
    console.log(error);
    if(error?.response?.status === 401) {
        dispatch(sessionExpired());
        return;
    }
    if (error?.response?.status === 422) {
        return error.response.data;
    }
    dispatch(createAction(SHOW_ERROR, error.response?.data))
    return false;
}

export default handleError