import React from "react";

import {Modal, ModalHeader, ModalBody, ModalFooter, Form} from "reactstrap";
import {MdClose} from 'react-icons/md'
import Button from "../../core/buttons/Button";
import Dialog from "@mui/material/Dialog";
import SubmitFormButton from "../../core/buttons/SubmitFormButton";

const AppModal = ({
                      children,
                      title = '',
                      modalOpened,
                      setModalOpened,
                      submitAction,
                      message = '',
                      cancelText = 'Anuluj',
                      submitText = 'Zapisz',
                      type = 'normal',
                      includeButtons = true
                  }) => {

    let [loading, setLoading] = React.useState(false);

    let handleModalSave = () => {
        setLoading(true);
        let promise = submitAction()
        if (promise?.then) {
            promise.then(() => {
                setLoading(false);
            })
        }
    }

    let hideModal = () => {
        setModalOpened(() => false)
    };

    return <Dialog onClose={hideModal} open={modalOpened} className={type + ' appdialog'}>
        <div className='dialog-header'>
            <span>{title}</span>
            <MdClose onClick={hideModal} className='close'/>
        </div>
        <div  className='dialog-body'>
            {message}
            {children}
        </div>
        <div className='dialog-footer'>
            <div className='buttons'>
                {
                    includeButtons && <>
                        <Button variant='secondary' onClick={() => setModalOpened(false)} text={cancelText}/>
                        <Button onClick={() => handleModalSave()} text={submitText} loading={loading}/>
                    </>
                }
            </div>
        </div>
    </Dialog>
}

export default AppModal;

