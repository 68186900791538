export const SPORTS_FETCH_REQUEST = 'SPORTS_FETCH_REQUEST';
export const SPORTS_FETCH_SUCCESS = 'SPORTS_FETCH_SUCCESS';

var initialState = {
    byId: [],
    all: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.sport) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.sport
            }
        }
    }
    switch (action.type) {
        case SPORTS_FETCH_SUCCESS: {
            return {
                ...state,
                all: action.payload.result
            }
        }
        default:
            return state;
    }
};